/* eslint-disable no-unused-vars */
let version = {
    software: "v1.0.1-beta35", // ATTENTION: 切勿修改v1.0.1-beta35这个值，在gitlab构建时，会自动替换成对应版本
    environment: process.env.REACT_APP_ENV,
    // environment: "test",

    isProduction() {
        return this.environment === "production";
    },

    isInternal() {
        return this.environment === "internal";
    }
}

let config = {
    alioss: {
        region: 'oss-cn-shenzhen',
        logBucket: 'tv-client-log',
        versionBucket: 'tv-android-version',
    },

    host: {
        cs: 'https://ccsapi.ludiqiao.com',
        ws: 'wss://ccsapi.ludiqiao.com',
    },

    task: {
        type: 3,
    },

    map: {
        lng: 113.97005752568613,
        lat: 22.589486651330265,
        zoomLevel: 14.00,

    }
}

function updateHostEnv(host) {
    if (host.ip && host.port) {
        config.host.cs = `https://${host.ip}:${host.port}`;
        config.host.ws = `wss://${host.ip}:${host.port}`;
        window.localStorage && window.localStorage.setItem("ccs::csIp", host.ip);
        window.localStorage && window.localStorage.setItem("ccs::csPort", host.port);
    }
}

function curEnvTrans() {
    let title = "开发环境"
    switch (version.environment) {
        case "internal":
            title = "内网环境";
            break;
        case "development":
            title = "开发环境";
            break;
        case "production":
            title = "正式环境";
            break;
        case "show":
            title = "展厅环境";
            break;
        case "test":
            title = "测试环境";
            break;
        default:
            title = "自定义环境";
            break;
    }

    return title;
}

function switchEnvironment(evn, force) {
    version.environment = evn;
    let host = {}
    switch (version.environment) {
        case "internal":
            host = {
                ip: window.localStorage?.getItem("ccs::csIp") || window.location.hostname,
                port: window.localStorage?.getItem("ccs::csPort") || 6443,
            }
            break;
                
        case "production":
            host = {
                ip: "ccsapi.ludiqiao.com",
                port: 443,
            }
            break;
        case "custom":
            host = {
                ip: window.localStorage.getItem("ccs::csIp"),
                port: window.localStorage.getItem("ccs::csPort"),
            }
            break;
        case "test":
        default:
            host = {
                ip: "test.ludiqiao.com",
                port: 443,
            }
            break;
    }

    if (window.localStorage && !force) {
        let newHost = {
            ip: window.localStorage.getItem("ccs::csIp") || host.ip,
            port: window.localStorage.getItem("ccs::csPort") || host.port,
        }
        host = newHost;
    }

    updateHostEnv(host)
}

switchEnvironment(version.environment)

export { config, version, updateHostEnv, curEnvTrans, switchEnvironment };