
import { version } from '../util/version';

export let userMenu = {
    key: "user", icon: "icon-staff", title: "人员管理",
    children: [
        { key: "user/organization", icon: "icon-org", title: "部门", },
        { key: "user/role", icon: "icon-role2", title: "角色", },
        { key: "user/staff", icon: "icon-staff2", title: "人员", },
        // { key: "user/anonymous", icon: "icon-anonymous", title: "疑犯", }
    ]
}

export let taskMenu = {
    key: "task", icon: "icon-task", title: "任务管理",
    children: [
        { key: "task/summary", icon: "icon-task2", title: "任务", },
        { key: "task/situation", icon: "icon-situation", title: "态势图", }
    ]
}

export let resourceMenu = {
    key: "asset", icon: "icon-shield", title: "设备管理",
    children: [
        { key: "asset/terminal", icon: "icon-device", title: "视频终端", },
        { key: "asset/phone", icon: "icon-terminal", title: "手持终端", },
        { key: "asset/camera", icon: "icon-camera", title: "摄像头", },
        { key: "asset/alarm", icon: "icon-alarm", title: "告警器", },
        { key: "asset/drone", icon: "icon-drone", title: "无人机", },
        // { key: "asset/car", icon: "icon-police-car", title: "车辆", },
        // { key: "asset/ship", icon: "icon-ship", title: "船只", }
    ]
}

export let configMenu = {
    key: "config", icon: "icon-config", title: "全局配置",
    children: [
        { key: "config/daping", icon: "icon-daping", title: "大屏", }
    ]
}

export let menus = [];
switch(version.environment) {
    // 正式环境
    case "production":
        // menus = [billMenu, devopsMonitorMenu, devopsAnalysisMenu];
        // break;
    // 展厅环境、开发环境、测试环境
    // eslint-disable-next-line no-fallthrough
    case "show":
    case "development":
    case "test":
        break;
    default:
        break;
}

export function initMenu(roleType) {
    switch (roleType) {
        case "superadmin":
        case "controller":
            menus = [taskMenu, userMenu, resourceMenu, configMenu]
            break;
        
        default:
            break;
    }

    return menus;
}