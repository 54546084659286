/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */

export function isFullscreen() {
    return document.fullscreenElement   //standard property
    || document.webkitFullscreenElement //safari/opera support
    || document.mozFullscreenElement    //firefox support
    || document.msFullscreenElement;    //ie/edge support
}
 
export function toggleFullscreen() {
    if (isFullscreen()) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    } else {
        if (document.documentElement?.requestFullscreen) {
            document.documentElement.requestFullscreen().catch(console.log);
        }
    }
}

export function screenRequestFull(isFullScreen) {
    if (!isFullScreen) {
        let de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen().catch(console.log);
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
        }
    }
};

export function screenExitFull(isFullScreen) {
    if (isFullScreen) {
        let de = document;
        if (de.screenExitFull) {
            de.screenExitFull();
        } else if (de.mozCancelFullScreen) {
            de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
            de.webkitCancelFullScreen();
        }
    }
};

export function screenResize(params) {
    this.setState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
    })
}

export function screenWatchFull(onChange = undefined) {
    document.addEventListener("webkitfullscreenchange", onChange);
    document.addEventListener("fullscreenchange", onChange);
    document.addEventListener("mozfullscreenchange", onChange);
};

export function screenUnwatchFull(onChange = undefined) {
    document.removeEventListener("webkitfullscreenchange", onChange);
    document.removeEventListener("fullscreenchange", onChange);
    document.removeEventListener("mozfullscreenchange", onChange);
};

export function screenWatchResize(onChange = undefined) {
    window.addEventListener("resize",() => {
        onChange && onChange();
    });
}