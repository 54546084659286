/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Menu, AutoComplete, Select, Tooltip, Input, Button } from 'antd';
import styleJson from 'component/map/style/custom_map_config5.json';
import { getLoginUserInfo } from 'pages/login/loginRD';
import { IconFont } from 'util/hoComponent';
import { config } from 'util/version';

import './map.less'

class Map extends Component{

    constructor(props){
        super(props);

        this.state = {
            labelOffset: new window.BMapGL.Size(-12, -45),
            lng: 0,
            lat: 0,
            tilt: 0,
            heading: 0,
            zoomLevel: 19,
            tips: "",
            width: 1,
            height: 1,
        }
    }

    showMenuStyle(e, domId) {
        let { height, width } = this.state;
        let topOffset = 0;
        let rightOffset = 16;
        let dom = document.getElementById(domId);
        if (dom) {
            if (dom.clientHeight + e.offsetY > height) {
                topOffset -= dom.clientHeight
            }
            if (dom.clientWidth + e.offsetX > width) {
                rightOffset -= rightOffset * 2 + dom.clientWidth;
            }
        }

        console.group()
        console.log(e);
        console.log(dom);
        console.groupEnd()

        return {
            opacity: 1,
            zIndex: 999,
            top: `${e.offsetY + topOffset}px`,
            left: `${e.offsetX + rightOffset}px`,
        }
    }

    hideMenuStyle() {
        return {
            opacity: 0,
            zIndex: 0,
        }
    }

    resize() {
        let dom = document.getElementById("map")
        if (dom) {
            this.setState({
                width: dom.offsetWidth,
                height: dom.offsetHeight,
            })
        }
    }

    getZoom(maxLng, minLng, maxLat, minLat) {
        var zoom = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000, 200000, 500000, 1000000, 2000000] //级别18到3
        var distance = this.map.getDistance(new window.BMapGL.Point(maxLng, maxLat), new window.BMapGL.Point(minLng, minLat)).toFixed(2);
        for (var i = 0,zoomLen = zoom.length; i < zoomLen; i++) {
            if(zoom[i] - distance > 0){
                return 18 - i + 2;
            }
        };
    }

    initAddress(local, callback) {
        new window.BMapGL.Geocoder().getLocation(local, (reslut) => {
            let addr = "";
            let poisTitle = "";
            let poisAddress = "";
            if (reslut?.surroundingPois?.length) {
                let pois = reslut?.surroundingPois?.[0]
                poisTitle = pois?.title;
                poisAddress = pois?.address;
                if (pois?.title) {
                    addr = `${pois?.title} | ${pois?.address}`
                } else {
                    addr = pois?.address;
                }
            } else {
                poisTitle = addr = reslut?.address + reslut?.business;
            }
            callback && callback(addr, poisTitle, poisAddress)
        }, {
            poiRadius: 50,
        })
    }

    uninitMap() {
        this.map && this.map.destroy()
    }

    initMap() {
        let { labelOffset } = this.state;
        let { position, zoomLevel, name } = this.props;
        let map = new window.BMapGL.Map("map", { preserveDrawingBuffer: true });
        this.map = map;
        let lng = position?.lng || (parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLng")) || config.map.lng));
        let lat = position?.lat || (parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLat")) || config.map.lat));
        zoomLevel = zoomLevel || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapZoomLevel")) || config.map.zoomLevel).toFixed(2);
        let center = new window.BMapGL.Point(lng, lat)
        map.centerAndZoom(center);
        map.setMapStyleV2({ styleJson: styleJson });
        let navigation = new window.BMapGL.NavigationControl3D({
            anchor: window.BMAP_ANCHOR_TOP_RIGHT,
            offset: new window.BMapGL.Size(18, 18)
        })
        map.addControl(navigation);
        let cityControl = new window.BMapGL.CityListControl({
            anchor: window.BMapGL.BMAP_ANCHOR_TOP_LEFT,
        });
        map.addControl(cityControl);
        map.enableScrollWheelZoom();
        map.enableKeyboard();
        map.disableDoubleClickZoom();
        map.setDisplayOptions({
            poiIcon: false,
            poiText: true,
        })
        let ac = new window.BMapGL.Autocomplete({
            input: "suggestId",
            location: map,
        });

        ac.addEventListener("onhighlight", (e) => {
            let str = "";
            let _value = e.fromitem.value;
            let value = "";
            if (e.fromitem.index > -1) {
                value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            }    
            str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
            
            value = "";
            if (e.toitem.index > -1) {
                _value = e.toitem.value;
                value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            }    
            str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
            document.getElementById("searchResultPanel").innerHTML = str;
        });

        ac.addEventListener("onconfirm", (e) => {
            let _value = e.item.value;
            let myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
            
            let myFun = () => {
                let pp = local.getResults().getPoi(0).point;
                map.centerAndZoom(pp, 18);
                this.initAddress(pp, (addr) => {
                    this.setState({
                        addressPlaceholder: addr,
                    })
                });
                this.searchMarker && map.removeOverlay(this.searchMarker)
                this.searchMarker = new window.BMapGL.Marker(pp);
                this.searchMarker.disableMassClear();
                map.addOverlay(this.searchMarker);
            }
            let local = new window.BMapGL.LocalSearch(map, {
                onSearchComplete: myFun
            });
            local.search(myValue);
        });
        map.addEventListener('tilesloaded', (e) => {
            if (!this.tilesloadFirst) {
                this.tilesloadFirst = true;

                this.setState({
                    controlVisible: true,
                    zoomLevel: zoomLevel,
                    lng: lng,
                    lat: lat,
                }, () => {
                    this.map.setTilt(0);
                    this.map.setZoom(zoomLevel, {
                        callback: (e) => {
                            this.initAddress(center, (addr) => {
                                this.setState({
                                    addressPlaceholder: addr,
                                })
                            })
                            if (this.props.position?.lng && this.props.position?.lat) {
                                let point = new window.BMapGL.Point(this.props.position?.lng, this.props.position?.lat)
                                this.posMarker && this.map.removeOverlay(this.posMarker)
                                this.posMarker = new window.BMapGL.Marker(point);
                                this.posMarker.disableMassClear();
                                if (name) {
                                    let label = new window.BMapGL.Label(name, {
                                        offset: labelOffset
                                    });
                                    let style = { color: '#000', fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent'};
                                    label.setStyle(style)
                                    this.posMarker.setLabel(label)
                                }
                                this.map.addOverlay(this.posMarker);
                            }   
                        }
                    });
                })
            }
        })

        map.addEventListener('click', (e) => {
            this.menuLatlng = e.latlng;
            this.setState({
                mapMenuStyle: this.hideMenuStyle(),
                lng: e.latlng.lng,
                lat: e.latlng.lat
            })
            this.initAddress(e.latlng, (addr) => {
                this.setState({
                    addressPlaceholder: addr,
                })
            });
        });

        map.addEventListener('rightclick', (e) => {
            this.menuLatlng = e.latlng;
            this.setState({
                mapMenuStyle: this.showMenuStyle(e.domEvent, "map-menu"),
                lng: e.latlng.lng,
                lat: e.latlng.lat
            })
        });
        
        map.addEventListener('zoomstart', (e) => {
            let { mapMenuStyle} = this.state;
            if (mapMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('zoomend', (e) => {
            let { onOk } = this.props;
            (onOk && this.circle) && onOk(this.circle.getCenter(), this.circle.getRadius(), e.target?.zoomLevel)
            this.setState({
                zoomLevel: e.target?.zoomLevel.toFixed(2),
            });
        });
        map.addEventListener('dragend', (e) => {
            let centerPoint = this.map.getCenter()
            this.setState({
                tilt: this.map.getTilt()?.toFixed(2),
                heading: ((this.map.getHeading() % 360 + 360) % 360).toFixed(2),
                lat: centerPoint?.lat || 0,
                lng: centerPoint?.lng || 0,
            });
        });
        map.addEventListener('movestart', (e) => {
            let { mapMenuStyle,} = this.state;
            if (mapMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('mousemove', (e) => {
            this.curLatlng = e.latlng
            // this.setState({
            //     lat: e.latlng?.lat || 0,
            //     lng: e.latlng?.lng || 0,
            // })
        });
    }

    mkHeader() {
        return <div className="task-summary-header">
            <div className="task-summary-header-address">
                <Input id="suggestId" placeholder="搜索地址" className="task-summary-header-address-input"/>
                <div id="searchResultPanel" className="task-summary-header-address-result"/>
            </div>
        </div>
    }

    mkMeun() {
        let { mapMenuStyle, labelOffset } = this.state;
        let { name } = this.props;
        return (
            <Menu
                id="map-menu"
                className="position-map-menu"
                // openKeys={['setting']}
                selectedKeys={[]}
                defaultSelectedKeys={[]}
                style={mapMenuStyle}
                onClick={(e) => {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle()
                    })
                }}
            >
                <Menu.Item key="start" className="enable" onClick={e => {
                    this.props.onSelect?.(this.menuLatlng)
                    this.posMarker && this.map.removeOverlay(this.posMarker)
                    this.posMarker = new window.BMapGL.Marker(this.menuLatlng);
                    this.posMarker.disableMassClear();
                    if (name) {
                        let label = new window.BMapGL.Label(name, {
                            offset: labelOffset
                        });
                        let style = { color: '#000', fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent'};
                        label.setStyle(style)
                        this.posMarker.setLabel(label)
                    }
                    this.map.addOverlay(this.posMarker);
                }}>
                    <div className="left">
                        <IconFont className="icon-menu" type="icon-area-select" />
                        <span className="description">放置</span>
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    mkFooter() {
        let { lng, lat, zoomLevel, heading, tilt, addressPlaceholder } = this.state;
        return <div className="task-summary-footer">
            <div className="left">
                <Tooltip title="地址" className="tooltip">
                    <IconFont className="icon-menu" type="icon-local" /><div className="title address">{addressPlaceholder}</div>
                </Tooltip>
            </div>
            <div className="right">
                <Tooltip title="经纬度" className="tooltip">
                    <IconFont className="icon-menu" type="icon-aim" /><div className="title lnglat">{lng}, {lat}</div>
                </Tooltip>
                <Tooltip title="缩放级别" className="tooltip">
                    <IconFont className="icon-menu" type="icon-zoom" /><div className="title zoom">{zoomLevel}</div>
                </Tooltip>
                <Tooltip title="视角倾斜度（3D）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-tilt" /><div className="title tilt">{tilt}°</div>
                </Tooltip>
                <Tooltip title="正北方偏移（顺时针）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-heading" /><div className="title heading">{heading}°</div>
                </Tooltip>
            </div>
        </div>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        this.uninitMap();
    }

    componentDidMount() {
        document.oncontextmenu = function () {
            return false;
        }

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninitMap();
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninitMap();
        }.bind(this)

        window.addEventListener("resize",() => {
            this.resize();
        });

        this.resize();
        this.initMap();
    }

    render() {
        return (<div className="task-summary">
            {this.mkHeader()}
            {this.mkMeun()}
            {this.mkFooter()}
            <div className="task-summary-map" id="map" />
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Map);

