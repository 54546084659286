/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Row, Menu, Space, Button, Calendar, Modal, Dropdown, Skeleton, Popconfirm, Tooltip, Spin } from 'antd';
import { PlusOutlined, EditOutlined, StarFilled, ReloadOutlined, DeleteOutlined, FullscreenOutlined, DownOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import ProCard, { StatisticCard } from '@ant-design/pro-card';
import ProForm, { ModalForm, ProFormRadio, ProFormText, ProFormTextArea, ProFormSelect, ProFormDateTimePicker, ProFormList, ProFormGroup } from '@ant-design/pro-form';
import { Page } from '../../../component/antd/page';
import { DEVICE, TASK } from '../../../component/common/common';
import Number from '../../../component/tweenOneIcon/number';
import { PaginationPositionIcon, PicPreview } from '../../../component/antd/antd';
import { menus } from '../../../laylout/menu';
import { getMenus, subSet } from '../../../util/logic';
import { message } from '../../../util/message';
import { config, version } from '../../../util/version';
import { getLoginUserInfo } from '../../login/loginRD';
import { apiTaskAdd, apiTaskDel, apiTaskList, apiTaskMod, apiTaskDetail, apiTaskScheduledDateStatistic, apiTaskStateStatistic, apiTaskStaffBatchAdd, apiTaskStaffBatchDel, apiTaskTerminalBatchAdd, apiTaskTerminalBatchDel } from '../../../api/task';
import { pagization } from '../../../api/api';
import Map from './map';
import Staff from '../../../component/common/staff';
import Terminal from '../../../component/common/terminal';

import './summary.less';

class Summary extends Component{

    constructor(props){
        super(props);

        let paginationConfig = {
            position: ['bottomRight'],
            showQuickJumper: true,
        }

        const columns = [
            {
                title: '任务名称',
                width: '20%',
                type: 'str',
                dataIndex: 'Name',
                // search: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '状态',
                width: '10%',
                dataIndex: 'State',
                type: "strEnumMultiple",
                fieldProps: {
                    mode: 'multiple',
                },
                valueType: 'select',
                valueEnum: TASK.STATE.valueEnum(),
            },
            {
                title: '计划执行时间',
                width: '16%',
                type: 'dateToTime',
                dataIndex: 'ScheduledTime',
                valueType: 'date',
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => moment(a.ScheduledTime) - moment(b.ScheduledTime),
                render: (text, record) => record.ScheduledTime,
            },
            {
                title: '人员数量',
                width: '7%',
                type: 'int',
                dataIndex: 'StaffCount',
                search: false,
            },
            {
                title: '设备数量',
                width: '7%',
                type: 'int',
                dataIndex: 'DeviceCount',
                search: false,
            },
            {
                title: '备注',
                width: '10%',
                type: 'str',
                dataIndex: 'Remark',
                ellipsis: true,
                // search: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                width: '30%',
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let options = [];
                    // options.push(<Button key="detail" type="link" className="nopadding">详情</Button>)
                    if (record.State !== TASK.STATE.FINISHED) {
                        options.push(
                            <Dropdown key="situation" overlay={<Menu>
                                <Menu.Item key="controlled">
                                    <div onClick={() => {
                                        window.goToMenu(`/ccs/task/situation?env=${version.environment}&id=${record.Id}&mode=controlled`, "", undefined, false, true)
                                    }}>纯控制模式</div>
                                </Menu.Item>
                                <Menu.Item key="mix">
                                    <div onClick={() => {
                                        window.goToMenu(`/ccs/task/situation?env=${version.environment}&id=${record.Id}&mode=mix`, "", undefined, false, true)
                                    }}>控制+显示模式</div>
                                </Menu.Item>
                              </Menu>}>
                                <Button type="link" className="nopadding">态势图<DownOutlined /></Button>
                            </Dropdown>
                        )
                    }
                    options.push(this.mkEdit(record))
                    options.push(<Popconfirm
                        key={record.Id}
                        title={`是否删除任务${record?.Name}？`}
                        onConfirm={async () => {
                            let rsp = await apiTaskDel(record);
                            if (rsp?.Status === 0) {
                                message.success('删除任务成功');
                                this.onRefreshOnClick();
                                return true;
                            }
                        }}
                        okText="确定"
                        cancelText="我再想想"
                    >
                        <Button key="delete" type="link" className="nopadding">删除</Button>
                    </Popconfirm>)
                    return options;
                },
            },
        ];

        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            paginationConfig: paginationConfig,
            calendarSelectedDate: moment(),
            calendarMode: 'month',
        }
    }

    onRefreshCalendar() {
        setTimeout(async () => {
            let { calendarSelectedDate } = this.state;
            let startTime = moment(calendarSelectedDate).startOf('month').format('YYYY-MM-DD 00:00:00');
            let endTime = moment(calendarSelectedDate).endOf('month').format('YYYY-MM-DD 23:59:59');
            let rsp2 = await apiTaskScheduledDateStatistic({ StartTime: startTime, EndTime: endTime });
            
            this.setState({
                scheduledDateData: rsp2?.RecordList || [],
            })

        }, 0);
    }


    onRefreshOnClick() {
        setTimeout(async () => {
            let rsp1 = await apiTaskStateStatistic();
            let stateData = {
                [TASK.STATE.ALL]: 0,
                [TASK.STATE.TOSTART]: 0,
                [TASK.STATE.INPROGRESS]: 0,
                [TASK.STATE.FINISHED]: 0,
            }
            // eslint-disable-next-line no-unused-expressions
            rsp1?.RecordList?.forEach(r => {
                stateData[r.State] = r.Count;
                stateData[TASK.STATE.ALL] += r.Count;
            });
            
            this.setState({
                stateData,
            })

            this.onRefreshCalendar()

        }, 0);

        this.tableAction?.reload()
    }

    async modStaffAndTerminal(task) {

        let dstTerminals = this.state.terminalSelectedRows.map(r => r.Id) || [];
        let srcTerminals = task?.terminals?.map(t => t.Id) || []
        let addTerminals = subSet(dstTerminals, srcTerminals)
        let delTerminals = subSet(srcTerminals, dstTerminals)

        console.group("task mod staff and terminal")
        console.log("task", task);
        console.group("terminal")
        console.log("src", srcTerminals);
        console.log("dst", dstTerminals);
        console.groupEnd();

        if (addTerminals?.length > 0) {
            let rsp = await apiTaskTerminalBatchAdd({ MissionId: task?.Id, DeviceIds: addTerminals })
        }

        if (delTerminals?.length > 0) {
            let rsp = await apiTaskTerminalBatchDel({ MissionId: task?.Id, DeviceIds: delTerminals })
        }

        let dstStaffs = this.state.staffSelectedRows.map(d => d.Id) || [];
        let srcStaffs = task?.staffs?.map(t => t.Id) || []
        let addStaffs = subSet(dstStaffs, srcStaffs)
        let delStaffs = subSet(srcStaffs, dstStaffs)

        console.group("staff")
        console.log("src", srcStaffs);
        console.log("dst", dstStaffs);
        console.groupEnd();
        console.groupEnd();

        if (addStaffs?.length > 0) {
            let rsp = await apiTaskStaffBatchAdd({MissionId: task?.Id, StaffIds: addStaffs})
        }

        if (delStaffs?.length > 0) {
            let rsp = await apiTaskStaffBatchDel({MissionId: task?.Id, StaffIds: delStaffs})
        }

        return true;
    }

    mkPageHeaderExtra() {
        let { stateData } = this.state;
        return (<Spin spinning={!stateData}>
            <StatisticCard.Group>
                <StatisticCard statistic={{
                    title: '总计', valueRender: () => {
                        return <span className="hover" onClick={() => {
                            if (this.table) {
                                this.table.setFieldsValue({
                                    State: undefined,
                                })
                                this.table?.submit();
                            }
                        }}><Number value={stateData?.[TASK.STATE.ALL] || 0} /></span>
                    }
                }}
                />
                <StatisticCard.Divider />
                <StatisticCard statistic={{
                    title: '进行中', status: 'processing', valueRender: () => {
                        return <span className="hover" onClick={() => {
                            if (this.table) {
                                this.table.setFieldsValue({
                                    State: [TASK.STATE.INPROGRESS],
                                })
                                this.table?.submit();
                            }
                        }}><Number value={stateData?.[TASK.STATE.INPROGRESS] || 0}/></span>
                    }
                }}
                />
                <StatisticCard statistic={{
                    title: '未开始', status: 'warning', valueRender: () => {
                        return <span className="hover" onClick={() => {
                            if (this.table) {
                                this.table.setFieldsValue({
                                    State: [TASK.STATE.TOSTART],
                                })
                                this.table?.submit();
                            }
                        }}><Number value={stateData?.[TASK.STATE.TOSTART] || 0}/></span>
                    }
                }}
                />
                <StatisticCard statistic={{
                    title: '已完成', status: 'default', valueRender: () => {
                        return <span className="hover" onClick={() => {
                            if (this.table) {
                                this.table.setFieldsValue({
                                    State: [TASK.STATE.FINISHED],
                                })
                                this.table?.submit();
                            }
                        }}><Number value={stateData?.[TASK.STATE.FINISHED] || 0}/></span>
                    }
                }}
                />
            </StatisticCard.Group>
        </Spin>)
    }

    mkLocation() {
        let { pageLocationVisible, centerLngLat, radius, zoomLevel } = this.state;
        return <Modal
            title="任务范围"
            width="100%"
            visible={pageLocationVisible}
            destroyOnClose={true}
            onOk={() => {
                this.setState({
                    pageLocationVisible: false
                })
            }}
            onCancel={() => {
                this.setState({
                    pageLocationVisible: false
                })
            }}
        >
            <Map
                centerLngLat={centerLngLat}
                radius={radius}
                zoomLevel={zoomLevel}
                onOk={(centerLngLat, radius, zoomLevel) => {
                    clearTimeout(this.updateTimer)
                    this.updateTimer = setTimeout(() => {
                        this.setState({
                            centerLngLat: centerLngLat,
                            radius: radius,
                            zoomLevel: zoomLevel,
                        })
                    }, 500);
                }}
                onReset={() => {
                    this.setState({
                        centerLngLat: undefined,
                        radius: undefined,
                        zoomLevel: undefined,
                    })
                }}
            />
        </Modal>
    }

    mkNewStaff() {
        let { newStaffVisible, staffSelectedRows } = this.state;
        return <Modal
            width="80%"
            visible={newStaffVisible}
            destroyOnClose={true}
            cancelButtonProps={{ className: 'none' }}
            onCancel={() => {
                this.setState({
                    newStaffVisible: false,
                })
            }}
            okText="完成"
            onOk={() => {
                this.setState({
                    newStaffVisible: false,
                })
            }}
        >
            <Staff
                selectedRowKeys={staffSelectedRows?.map(d => d.Id) || []}
                onAdd={(selectedRows) => {
                    selectedRows.forEach(k => {
                        if (!staffSelectedRows.find(r => r.Id === k.Id)) {
                            staffSelectedRows.push(k)
                        }
                    })
                    
                    this.setState({
                        staffSelectedRows: staffSelectedRows
                    }, () => {
                    })
                }}
                onDel={(selectedRows) => {
                    selectedRows.forEach((k, i) => {
                        let index = staffSelectedRows.findIndex(r => r.Id === k.Id)
                        if (index !== -1) {
                            staffSelectedRows = [
                                ...staffSelectedRows.slice(0, index),
                                ...staffSelectedRows.slice(index + 1),
                            ]
                        }
                    })
                    
                    this.setState({
                        staffSelectedRows: staffSelectedRows
                    }, () => {
                    })
                }}
            />
        </Modal>
    }

    mkNewDevice() {
        let { newTerminalVisible, terminalSelectedRows } = this.state;
        return <Modal
            width="80%"
            visible={newTerminalVisible}
            cancelButtonProps={{ className: 'none' }}
            onCancel={() => {
                this.setState({
                    newTerminalVisible: false,
                })
            }}
            okText="完成"
            onOk={() => {
                this.setState({
                    newTerminalVisible: false,
                })
            }}
        >
            <Terminal
                valid={{TypeId: [DEVICE.TYPE.CAMERA, DEVICE.TYPE.TERMINAL, DEVICE.TYPE.ALARM]}}
                selectedRowKeys={terminalSelectedRows?.map(r => r.Id) || []}
                onAdd={(selectedRows) => {
                    selectedRows.forEach(k => {
                        if (!terminalSelectedRows.find(r => r.Id === k.Id)) {
                            terminalSelectedRows.push(k)
                        }
                    })
                    
                    this.setState({
                        terminalSelectedRows: terminalSelectedRows
                    }, () => {
                    })
                }}
                onDel={(selectedRows) => {
                    selectedRows.forEach((k, i) => {
                        let index = terminalSelectedRows.findIndex(r => r.Id === k.Id)
                        if (index !== -1) {
                            terminalSelectedRows = [
                                ...terminalSelectedRows.slice(0, index),
                                ...terminalSelectedRows.slice(index + 1),
                            ]
                        }
                    })
                    
                    this.setState({
                        terminalSelectedRows: terminalSelectedRows
                    }, () => {
                    })
                }}
            />
        </Modal>
    }

    mkForm(operation) {
        let { centerLngLat, radius, avatarFile, staffSelectedRows, terminalSelectedRows } = this.state;
        return <>
            <ProCard split="horizontal">
                <ProForm.Group align="start">
                    <ProForm.Group direction="vertical">
                        <ProFormText
                            name="Name"
                            label="名称"
                            width="lg"
                            placeholder="请输入任务名称"
                            rules={[{ required: true, message: '请输入任务名称' }]}
                        />
                        <ProFormSelect
                            name="State"
                            label="状态"
                            width="lg"
                            request={async () => {
                                return TASK.STATE.LIST;
                            }}
                            placeholder="请选择状态"
                            rules={[{ required: true, message: '请选择状态' }]}
                        />
                        <ProFormDateTimePicker
                            name="ScheduledTime"
                            label="计划执行时间"
                            width="lg"
                            placeholder="请选择计划执行时间"
                            rules={[{ required: true, message: '请选择计划执行时间' }]}
                        />
                        <div className="ant-row ant-form-item ant-form-item-has-success">
                            <div className="ant-col ant-form-item-label">
                                <label>人员</label>
                            </div>
                            <div className="ant-col ant-form-item-control">
                                <div className="form-content-operation">
                                    <Button className="form-content-operation-btn" icon={<EditOutlined />} type="default" onClick={() => {
                                        this.setState({
                                            newStaffVisible: true,
                                        })
                                    }}>编辑</Button>
                                    <div className="form-content-operation-description">
                                        <span>数量：<span>{staffSelectedRows?.length || 0}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ant-row ant-form-item ant-form-item-has-success">
                            <div className="ant-col ant-form-item-label">
                                <label>设备</label>
                            </div>
                            <div className="ant-col ant-form-item-control">
                                <div className="form-content-operation">
                                    <Button className="form-content-operation-btn" icon={<EditOutlined />} type="default" onClick={() => {
                                        this.setState({
                                            newTerminalVisible: true,
                                        })
                                    }}>编辑</Button>
                                    <div className="form-content-operation-description">
                                        <span>数量：<span>{terminalSelectedRows?.length || 0}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ProForm.Group>
                    <ProForm.Group direction="vertical">
                        <ProFormText
                            name="Target"
                            label="目标名称"
                            width="lg"
                            placeholder="请输入目标名称"
                        />
                        <ProFormTextArea
                            name="TargetDesc"
                            label="目标描述"
                            width="lg"
                            placeholder="请输入目标描述"
                            fieldProps={{
                                rows: 5,
                            }}
                        />
                        {PicPreview.ProFormUploadButton.bind(this)(avatarFile, {label: "目标图片", name: "TargetPicUrl"})}
                        <div className="ant-row ant-form-item ant-form-item-has-success">
                            <div className="ant-col ant-form-item-label">
                                {/* <label className="ant-form-item-required">{"目标位置&范围"}</label> */}
                                <label>{"目标位置&范围"}</label>
                            </div>
                            <div className="ant-col ant-form-item-control">
                                <div className="form-content-operation">
                                    <Button className="form-content-operation-btn" icon={<EditOutlined />} type="default" onClick={() => {
                                        this.setState({
                                            pageLocationVisible: true,
                                        })
                                    }}>编辑</Button>
                                    {
                                        (centerLngLat && radius) ? <div className="form-content-operation-description">
                                            <span>中心点：<span>{centerLngLat.lng.toFixed(2)}, {centerLngLat.lat.toFixed(2)}</span></span>
                                            <span>半径：{radius.toFixed(2)}米</span>
                                        </div> : undefined
                                    }
                                </div>
                            </div>
                        </div>
                        
                    </ProForm.Group>
                </ProForm.Group>
                <ProFormTextArea width="100%" name="Remark" label="备注" placeholder="请输入备注" />
            </ProCard>
        </>
    }

    mkNew() {
        let { centerLngLat, radius, zoomLevel } = this.state;
        return <ModalForm
            formRef={ref => this.newForm = ref}
            title="添加任务"
            width="80%"
            labelwidth="auto"
            trigger={<Button key="add" type="primary" icon={<PlusOutlined/>}>添加</Button>}
            onFinish={async (values) => {
                values.CentreLng = centerLngLat.lng;
                values.CentreLat = centerLngLat.lat;
                values.ZoomLevel = zoomLevel;
                values.EdgeLng = 0;
                values.EdgeLat = 0;
                values.Radius = radius;
                values.MissionType = config.task.type;
                let rsp = await apiTaskAdd(Object.assign(values, { TargetPicUrl: values.TargetPicUrl?.[0]?.url }));
                if (rsp?.Status === 0) {
                    message.success('添加任务成功');
                    await this.modStaffAndTerminal(rsp)
                    this.onRefreshOnClick()
                    return true;
                }
                return false;
            }}
            onVisibleChange={(visible) => {
                if (visible) {
                    this.newForm.setFieldsValue({
                        TargetPicUrl: undefined,
                        CentreLng: 0,
                        CentreLat: 0,
                        EdgeLng: 0,
                        EdgeLat: 0,
                        Radius: 0,
                        Name: '',
                        State: TASK.STATE.TOSTART,
                        ScheduledTime: moment().format("YYYY-MM-DD 00:00:00"),
                        Remark: '',
                    })
                    this.setState({
                        avatarFile: undefined,
                        centerLngLat: { lng: 0, lat: 0 },
                        radius: 0,
                        zoomLevel: 0,
                        staffSelectedRows: [],
                        terminalSelectedRows: [],
                    })
                }
            }}
        >
            {this.mkForm('new')}
        </ModalForm>
    }

    mkEdit(record) {
        let { centerLngLat, radius, zoomLevel } = this.state;
        return <ModalForm
            key={record.Id}
            formRef={ref => record.editForm = ref}
            width="80%"
            title="编辑任务"
            labelwidth="auto"
            trigger={<Button key="edit" type="link" className="nopadding">编辑</Button>}
            onFinish={async (values) => {
                values.CentreLng = centerLngLat.lng;
                values.CentreLat = centerLngLat.lat;
                values.ZoomLevel = zoomLevel;
                values.Radius = radius;
                values.EdgeLng = 0;
                values.EdgeLat = 0;
                values.MissionType = config.task.type;

                let rsp = await apiTaskMod(Object.assign(Object.assign(record, Object.assign(values, {
                    TargetPicUrl: values.TargetPicUrl?.[0]?.url,
                    editForm: undefined,
                    staffs: undefined,
                    terminals: undefined,
                }))));
                if (rsp?.Status === 0) {
                    message.success('编辑任务成功');
                    await this.modStaffAndTerminal(record)
                    this.onRefreshOnClick()
                    return true;
                }
                return false;
            }}
            onVisibleChange={async (visible) => {
                if (visible) {
                    // 先更新表单
                    let file = PicPreview.newFile(record.TargetPicUrl);
                    record.editForm.setFieldsValue(Object.assign({}, record, { TargetPicUrl: file ? [file] : undefined }))
                    // 请求设备和人员数据
                    let rsp = await apiTaskDetail({ Id: record.Id });
                    record.terminals = rsp?.DeviceList;
                    record.staffs = rsp?.StaffList;
                    this.setState({
                        avatarFile: file,
                        centerLngLat: { lng: record.CentreLng, lat: record.CentreLat },
                        radius: record.Radius,
                        zoomLevel: record.ZoomLevel,
                        terminalSelectedRows: Object.assign([], rsp?.DeviceList) || [],
                        staffSelectedRows: Object.assign([], rsp?.StaffList) || [],
                    })
                }
            }}
        >
            {this.mkForm('edit')}
        </ModalForm>
    }

    mkPageBody() {
        let { columns, scheduledDateData, calendarSelectedDate, paginationData, paginationConfig } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={(e) => this.onRefreshOnClick(e)}>刷新</Button>,
            this.mkNew(),
            // <Button key="export" type="primary" icon={<DownloadOutlined/>}>导出</Button>,
            <PaginationPositionIcon
                paginationConfig={paginationConfig}
                onChange={(key) => {
                paginationConfig.position = [key]
                    this.setState({
                        paginationConfig: paginationConfig,
                    })
                }}
            />,
        ]
        return (
            <div className="summary-page-body">
                <div className="summary-page-body-calendar-col">
                    <div className="summary-page-body-calendar">
                        <div className="summary-page-body-calendar-header">
                            <div className="summary-page-body-calendar-header-title">任务日历</div>
                            <div className="summary-page-body-calendar-header-extra">
                            {calendarSelectedDate?.format("YYYY-MM") !== moment().format("YYYY-MM") ? <Button type="link" onClick={() => {
                                    this.setState({
                                        calendarSelectedDate: moment(),
                                    }, () => {
                                        this.onRefreshCalendar();
                                    })
                                }}>回到本月</Button> : undefined}
                            </div>
                        </div>
                        {
                            <Spin spinning={!scheduledDateData}>
                                <Calendar className="summary-page-body-calendar-body-calendar"
                                    fullscreen={false}
                                    value={calendarSelectedDate}
                                    onPanelChange={(date, mode) => {
                                        this.calendarPanelTimer = setTimeout(() => {
                                            this.calendarPanelTimer = 0;
                                        }, 200);
                                        this.setState({
                                            calendarSelectedDate: date,
                                        }, () => {
                                            this.onRefreshCalendar();
                                        })
                                    }}
                                    onSelect={(date) => {
                                        this.setState({
                                            calendarSelectedDate: date
                                        }, () => {
                                            if (this.calendarPanelTimer) {
                                                clearTimeout(this.calendarPanelTimer)
                                                this.calendarPanelTimer = 0;
                                                return;
                                            }
                                            if (this.table) {
                                                this.table.setFieldsValue({
                                                    ScheduledTime: date,
                                                })
                                                this.table.submit();
                                            }
                                        })
                                    }}
                                    dateCellRender={(value) => {
                                        let ret = scheduledDateData?.find(d => d.Date === value?.format('YYYY-MM-DD') && d.Count)
                                        return <Tooltip title={ret?.Count ? `任务数：${ret?.Count}` : ""}>
                                            <StarFilled className={"summary-page-body-calendar-body-calendar-star-" + (ret ? "show" : "hide")} />
                                        </Tooltip>
                                    }}
                                />
                            </Spin>
                        }
                    </div>
                </div>
                <div className="summary-page-body-table-col">
                    <ProTable
                        formRef={(ref) => this.table = ref}
                        actionRef={(ref) => this.tableAction = ref}
                        columns={columns}
                        request={async (params, sorter, filter) => {
                            console.group('request');
                            console.log("params:", params);
                            console.log("sorter:", sorter);
                            console.log("filter:", filter);
                            console.groupEnd();
                            let scheduledTime = params?.ScheduledTime;
                            if (scheduledTime instanceof Array) {
                                scheduledTime = scheduledTime[0]
                            }
                            if (scheduledTime) {
                                this.setState({
                                    calendarSelectedDate: moment(scheduledTime),
                                })
                            }

                            let rsp = await apiTaskList(pagization(params, sorter, filter, columns))
                            if (rsp?.Status === 0) {
                                this.setState({
                                    paginationData: {
                                        current: params?.current,
                                        pageSize: params?.pageSize,
                                        total: rsp?.TotalNum,
                                    },
                                })
                            }
                            return {
                                data: rsp?.RecordList || [],
                                total: rsp?.TotalNum,
                                success: true,
                            };
                        }}
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            },
                            getCheckboxProps: (record) => ({
                                Id: record.Id,
                            }),
                        }}
                        tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => {
                            return (
                                <Space size={16}>
                                    <Button className="nopadding" type="link">导出数据</Button>
                                    <Button className="nopadding" type="link" onClick={onCleanSelected}>取消选择</Button>
                                </Space>
                            );
                        }}
                        rowKey="Id"
                        pagination={{
                            ...paginationConfig,
                            ...paginationData,
                        }}
                        search={{filterType: "light"}}
                        dateFormatter="string"
                        headerTitle="任务列表"
                        options={{
                            density: true,
                            setting: true,
                            reload: false,
                            fullScreen: false,
                            search: false,
                        }}
                        toolBarRender={() => operations}
                    />
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra()
        let body = this.mkPageBody()
        return <Page className="summary-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        window.summary = this;
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
            // calendarSelectedDate: undefined,
        })
        this.onRefreshOnClick()
    }

    render() {
        return (<div>
            {this.mkNewDevice()}
            {this.mkNewStaff()}
            {this.mkLocation()}
            {this.mkPage()}
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Summary);

