/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Menu, AutoComplete, Select, Tooltip, Input, Button } from 'antd';
import { red, volcano, gold, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { SearchOutlined } from '@ant-design/icons';

import { message } from '../../../util/message';
import styleJson from '../../../component/map/style/custom_map_config5.json';
import { getIconSrc } from '../../../resource/resource';
import { getLoginUserInfo } from '../../login/loginRD';
import { apiMemberList } from '../../../api/member';
import { apiAddressSuggestion } from '../../../api/baidu';
import { IconFont } from '../../../util/hoComponent';
import { config } from '../../../util/version';

class Map extends Component{

    constructor(props){
        super(props);

        this.state = {
            cameraOnlineIcon: new window.BMapGL.Icon(getIconSrc('camera-online'), new window.BMapGL.Size(25, 25)),
            cameraOfflineIcon: new window.BMapGL.Icon(getIconSrc('camera-offline'), new window.BMapGL.Size(25, 25)),
            cameraBusyIcon: new window.BMapGL.Icon(getIconSrc('camera-busy'), new window.BMapGL.Size(25, 25)),
            deviceOnlineIcon: new window.BMapGL.Icon(getIconSrc('device-online'), new window.BMapGL.Size(25, 25)),
            deviceOfflineIcon: new window.BMapGL.Icon(getIconSrc('device-offline'), new window.BMapGL.Size(25, 25)),
            deviceBusyIcon: new window.BMapGL.Icon(getIconSrc('device-busy'), new window.BMapGL.Size(25, 25)),
            labelOffset: new window.BMapGL.Size(-12, -35),
            records: [],
            lng: 0,
            lat: 0,
            tilt: 0,
            heading: 0,
            zoomLevel: 19,
            tips: "",
            width: 1,
            height: 1,

            sdkInit: true,
        }
    }

    showMenuStyle(e, domId) {
        let { height, width } = this.state;
        let topOffset = -128 - 55;
        let rightOffset = -16;
        let dom = document.getElementById(domId);
        if (dom) {
            if (dom.clientHeight + e.clientY > height) {
                topOffset -= dom.clientHeight
            }
            if (dom.clientWidth + e.clientX > width) {
                rightOffset -= -rightOffset * 2 + dom.clientWidth;
            }
        }

        return {
            opacity: 1,
            zIndex: 999,
            top: `${e.clientY + topOffset}px`,
            left: `${e.clientX + rightOffset}px`,
        }
    }

    hideMenuStyle() {
        return {
            opacity: 0,
            zIndex: 0,
        }
    }

    resize() {
        let dom = document.getElementById("map")
        if (dom) {
            this.setState({
                width: dom.offsetWidth,
                height: dom.offsetHeight,
            })
        }
    }

    getZoom(maxLng, minLng, maxLat, minLat) {
        var zoom = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000, 200000, 500000, 1000000, 2000000] //级别18到3
        var distance = this.map.getDistance(new window.BMapGL.Point(maxLng, maxLat), new window.BMapGL.Point(minLng, minLat)).toFixed(2);
        for (var i = 0,zoomLen = zoom.length; i < zoomLen; i++) {
            if(zoom[i] - distance > 0){
                return 18 - i + 2;
            }
        };
    }

    mainRefreshOnClick(e, manual) {
        if (manual) {
            message.loading("正在刷新数据")
        }
        let pag = {
            PageNum: 1,
            PageSize: 1000,
            OrFirst: true,
            Filters: [[{Key: "Lng", Type: "int", Op: "!=", Value: 0}]],
            OrderKey: 'AppState',
            Desc: 1,
        }
        setTimeout(async () => {
            let rsp = await apiMemberList(pag);
            this.setState({
                records: rsp.UserList,
                loading: false,
            }, () => {
                if (manual) {
                    message.success("刷新成功")
                }
                this.initMapDevice(rsp.UserList)
            })
        }, manual ? 500 : 0);
        
    }

    initAddress(local, callback) {
        new window.BMapGL.Geocoder().getLocation(local, (reslut) => {
            let addr = "";
            let poisTitle = "";
            let poisAddress = "";
            if (reslut?.surroundingPois?.length) {
                let pois = reslut?.surroundingPois?.[0]
                poisTitle = pois?.title;
                poisAddress = pois?.address;
                if (pois?.title) {
                    addr = `${pois?.title} | ${pois?.address}`
                } else {
                    addr = pois?.address;
                }
            } else {
                poisTitle = addr = reslut?.address + reslut?.business;
            }
            callback && callback(addr, poisTitle, poisAddress)
        }, {
            poiRadius: 50,
        })
    }

    initCircle(center, radius, timeout) {
        setTimeout(() => {
            this.map.removeOverlay(this.circle)
            this.circle = new window.BMapGL.Circle(center || this.map.getCenter(), radius || 2000, {
                strokeColor: '#000',
                strokeWeight: 1,
                strokeOpacity: 0.3,
                strokeStyle: 'dashed',
                fillOpacity: 0.3,
                fillColor: '#000'
            });
            this.map.addOverlay(this.circle);
            this.circle.enableEditing()
            this.circle.disableMassClear()
            this.circle.addEventListener('lineupdate', (e) => {
                let latLng = e?.overlay?.latLng;
                let radius = e?.overlay?.radius;
                this.initAddress(new window.BMapGL.Point(latLng?.lng, latLng?.lat), (addr) => {
                    this.setState({
                        addressPlaceholder: addr,
                        radius: radius,
                    })
                })
                let { onOk } = this.props;
                onOk && onOk(latLng, radius, this.map.getZoom())
            }, { passive: true })
        }, timeout || 1000);
    }

    initMapDevice(records) {
        let { labelOffset, members } = this.state;
        this.map.clearOverlays();
        // eslint-disable-next-line no-unused-expressions
        records?.forEach(member => {
            if (!member.Lng || !member.Lat) {
                return;
            }
            let point = new window.BMapGL.Point(member.Lng, member.Lat);

            let online = 'Online';
            let device = 'camera';
            let color = '#fff';
            let labelExtra = ''
            if (member.AppState === 'online' && member.SipState === 'online') {
                if (member.CallState === 'idle') {
                    online = 'Online';
                } else {
                    online = 'Busy';
                    if (members?.find(m => m.userId === member?.SipNum)) {
                        labelExtra = '（通信中）';
                        color = gold[5];
                    } else {
                        labelExtra = '（忙碌）';
                        color = volcano[5];
                    }
                }
            } else {
                online = 'Offline';
                color = red[5];
                labelExtra = '（离线）';
            }
            if (member.DeviceType === "CAMERA-28181") {
                device = 'camera';
            } else {
                device = 'device';
            }

            let marker = new window.BMapGL.Marker(point, {
                icon: this.state[`${device}${online}Icon`],
            });
            let label = new window.BMapGL.Label(`${member.NickName}${labelExtra}`, {
                offset: labelOffset
            });
            let style = { color: color, fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent'};
            label.setStyle(style)
            marker.setLabel(label)
            marker.dataset = member;
        })
    }

    uninitMap() {
        this.map && this.map.destroy()
    }

    initMap() {
        let { centerLngLat, radius, zoomLevel } = this.props;
        let map = new window.BMapGL.Map("map", { preserveDrawingBuffer: true });
        this.map = map;
        let lng = centerLngLat?.lng || (parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLng")) || config.map.lng));
        let lat = centerLngLat?.lat || (parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLat")) || config.map.lat));
        zoomLevel = zoomLevel || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapZoomLevel")) || config.map.zoomLevel).toFixed(2);
        let center = new window.BMapGL.Point(lng, lat)
        map.centerAndZoom(center);
        map.setMapStyleV2({ styleJson: styleJson });
        let navigation = new window.BMapGL.NavigationControl3D({
            anchor: window.BMAP_ANCHOR_TOP_RIGHT,
            offset: new window.BMapGL.Size(18, 18)
        })
        map.addControl(navigation);
        let cityControl = new window.BMapGL.CityListControl({
            anchor: window.BMapGL.BMAP_ANCHOR_TOP_LEFT,
        });
        map.addControl(cityControl);
        map.enableScrollWheelZoom();
        map.enableKeyboard();
        map.disableDoubleClickZoom();
        map.setDisplayOptions({
            poiIcon: false,
            poiText: true,
        })
        let ac = new window.BMapGL.Autocomplete({
            input: "suggestId",
            location: map,
        });

        ac.addEventListener("onhighlight", (e) => {
            let str = "";
            let _value = e.fromitem.value;
            let value = "";
            if (e.fromitem.index > -1) {
                value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            }    
            str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
            
            value = "";
            if (e.toitem.index > -1) {
                _value = e.toitem.value;
                value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            }    
            str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
            document.getElementById("searchResultPanel").innerHTML = str;
        });

        ac.addEventListener("onconfirm", (e) => {
            let _value = e.item.value;
            let myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
            document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
            
            let myFun = () => {
                let pp = local.getResults().getPoi(0).point;
                map.centerAndZoom(pp, 18);
                this.initAddress(pp, (addr) => {
                    this.setState({
                        addressPlaceholder: addr,
                    })
                });
                this.searchMarker && map.removeOverlay(this.searchMarker)
                this.searchMarker = new window.BMapGL.Marker(pp);
                this.searchMarker.disableMassClear();
                map.addOverlay(this.searchMarker);
                this.initCircle(pp, 100, 1)
            }
            let local = new window.BMapGL.LocalSearch(map, {
                onSearchComplete: myFun
            });
            local.search(myValue);
        });
        map.addEventListener('tilesloaded', (e) => {
            if (!this.tilesloadFirst) {
                this.tilesloadFirst = true;

                this.setState({
                    controlVisible: true,
                    zoomLevel: zoomLevel,
                    areaSelectMode: centerLngLat && radius,
                    lng: lng,
                    lat: lat,
                }, () => {
                    this.map.setTilt(0);
                    this.map.setZoom(zoomLevel, {
                        callback: (e) => {
                            if (centerLngLat && radius) {
                                this.initCircle(centerLngLat, radius)
                            }
                            this.initAddress(center, (addr) => {
                                this.setState({
                                    addressPlaceholder: addr,
                                })
                            })
                            this.mainRefreshOnClick();
                        }
                    });
                })

                
            }
        })

        map.addEventListener('click', (e) => {
            this.menuLatlng = e.latlng;
            this.setState({
                mapMenuStyle: this.hideMenuStyle(),
                lng: e.latlng.lng,
                lat: e.latlng.lat
            })
        });

        map.addEventListener('rightclick', (e) => {
            this.menuLatlng = e.latlng;
            this.setState({
                mapMenuStyle: this.showMenuStyle(e.domEvent, "map-menu"),
                lng: e.latlng.lng,
                lat: e.latlng.lat
            })
        });
        
        map.addEventListener('zoomstart', (e) => {
            let { mapMenuStyle} = this.state;
            if (mapMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('movestart', (e) => {
            let { mapMenuStyle,} = this.state;
            if (mapMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('zoomend', (e) => {
            let { onOk } = this.props;
            (onOk && this.circle) && onOk(this.circle.getCenter(), this.circle.getRadius(), e.target?.zoomLevel)
            this.setState({
                zoomLevel: e.target?.zoomLevel.toFixed(2),
            });
        });
        map.addEventListener('dragend', (e) => {
            let centerPoint = this.map.getCenter()
            this.setState({
                tilt: this.map.getTilt()?.toFixed(2),
                heading: ((this.map.getHeading() % 360 + 360) % 360).toFixed(2),
                lat: centerPoint?.lat || 0,
                lng: centerPoint?.lng || 0,
            });
        });

        map.addEventListener('mousemove', (e) => {
            this.curLatlng = e.latlng
            // this.setState({
            //     lat: e.latlng?.lat || 0,
            //     lng: e.latlng?.lng || 0,
            // })
        });
    }

    mkHeader() {
        return <div className="task-summary-header">
            <div className="task-summary-header-address">
                <Input id="suggestId" placeholder="搜索地址" className="task-summary-header-address-input"/>
                <div id="searchResultPanel" className="task-summary-header-address-result"/>
            </div>
        </div>
    }

    mkMeun() {
        let { mapMenuStyle, orgTilt, areaSelectMode, onOk, onReset } = this.state;
        return (
            <Menu
                id="map-menu"
                className="task-summary-menu"
                // openKeys={['setting']}
                selectedKeys={[]}
                defaultSelectedKeys={[]}
                style={mapMenuStyle}
                onClick={(e) => {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle()
                    })
                }}
            >
                {
                    !areaSelectMode ? <Menu.Item key="start" className="enable" onClick={e => {
                        this.setState({
                            areaSelectMode: true,
                        }, () => {
                            this.map.setTilt(0)
                            this.initCircle()
                        })
                    }}>
                        <div className="left">
                            <IconFont className="icon-menu" type="icon-area-select" />
                            <span className="description">开始选择范围</span>
                        </div>
                    </Menu.Item> : undefined
                }
                {
                    areaSelectMode ? <Menu.Item key="reset" onClick={() => {
                        this.setState({
                            areaSelectMode: true,
                        }, () => {
                            this.map.setTilt(0)
                            this.initCircle()
                            onReset && onReset()
                        })
                    }}><div className="left">
                            <IconFont className="icon-menu" type="icon-refresh" />
                            <span className="description">重新选择范围</span>
                        </div>
                    </Menu.Item> : undefined
                }
                {
                    areaSelectMode ? <Menu.Item key="exit" onClick={() => {
                        this.map.setTilt(orgTilt || 0)
                        this.setState({
                            areaSelectMode: false,
                        }, () => {
                            this.map.removeOverlay(this.circle)
                        })
                    }}><div className="left">
                            <IconFont className="icon-menu" type="icon-cuo" />
                            <span className="description">退出</span>
                        </div>
                    </Menu.Item> : undefined
                } 
            </Menu>
        )
    }

    mkFooter() {
        let { lng, lat, zoomLevel, heading, tilt, addressPlaceholder, radius } = this.state;
        return <div className="task-summary-footer">
            <div className="left">
                <Tooltip title="地址" className="tooltip">
                    <IconFont className="icon-menu" type="icon-local" /><div className="title address">{addressPlaceholder}</div>
                </Tooltip>
                <Tooltip title="半径" className="tooltip">
                    <IconFont className="icon-menu" type="icon-radius" /><div className="title radius">{radius || this.props.radius}</div>
                </Tooltip>
            </div>
            <div className="right">
                {/* <div className="canvas">{this.state.drawAreaStart?.lng},{this.state.drawAreaStart?.lat}</div>
                <div className="pixel">{this.map && this.map.pointToPixel(this.state.drawAreaStart)?.x}, {this.map && this.map.pointToPixel(this.state.drawAreaStart)?.y}</div>
                <div className="canvas">{this.curLatlng?.lng},{this.curLatlng?.lat}</div>
                <div className="pixel">{this.map && this.map.pointToPixel(this.curLatlng)?.x}, {this.map && this.map.pointToPixel(this.curLatlng)?.y}</div>
                <div className="wh">{this.state.width} * {this.state.height}</div> */}
                <Tooltip title="经纬度" className="tooltip">
                    <IconFont className="icon-menu" type="icon-aim" /><div className="title lnglat">{lng}, {lat}</div>
                </Tooltip>
                <Tooltip title="缩放级别" className="tooltip">
                    <IconFont className="icon-menu" type="icon-zoom" /><div className="title zoom">{zoomLevel}</div>
                </Tooltip>
                <Tooltip title="视角倾斜度（3D）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-tilt" /><div className="title tilt">{tilt}°</div>
                </Tooltip>
                <Tooltip title="正北方偏移（顺时针）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-heading" /><div className="title heading">{heading}°</div>
                </Tooltip>
            </div>
        </div>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        this.uninitMap();
    }

    componentDidMount() {
        document.oncontextmenu = function () {
            return false;
        }

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninitMap();
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninitMap();
        }.bind(this)

        window.addEventListener("resize",() => {
            this.resize();
        });

        this.resize();
        this.initMap();
    }

    render() {
        return (<div className="task-summary">
            {this.mkHeader()}
            {this.mkMeun()}
            {this.mkFooter()}
            <div className="task-summary-map" id="map" />
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Map);

