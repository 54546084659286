/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Layout , BackTop } from 'antd';

import {actNetRequestClear} from '../util/netReqRD';
import {getLoginUserInfo, actLoginClear} from '../pages/login/loginRD';


import Header from './header';
import Content from './content';
import Footer from './footer';

class MainLayout extends Component {

    constructor(props){
        super(props);

        this.logoutOnClick = this.logoutOnClick.bind(this);
    }

    logoutOnClick(){
        const {dispatch} = this.props;
        // console.log("logoutOnClick");
        dispatch(actNetRequestClear())
        dispatch(actLoginClear())
    }

    render(){
        return (
            <Layout className="ho-layout">
                <BackTop/>
                {/* 顶端头部 */}
                <Header 
                    history={this.props.history}
                    match={this.props.match}
                    reqUserInfo={this.props.reqUserInfo} 
                    logoutOnClick={this.logoutOnClick}/>
                {/* 主要内容 */}
                <Content 
                    history={this.props.history}
                    match={this.props.match}/>
                {/* 页脚 */}
                {/* <Footer
                    history={this.props.history}
                    match={this.props.match}/> */}
            </Layout>
        );
    }
}

MainLayout.defaultProps = {
    theme: "light", // 'light' or 'dark'
}
const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});

export default connect(
    mapState, 
    null
)(MainLayout);

