import { api } from './api';

export let apiMemberSetLocation = async (data, extra) => {
    return await api("/cs/v1/mntn/user/setLocation", data, extra)
}

export let apiMemberList = async (data, extra) => {
    return await api("/cs/v1/mntn/user/list", data, extra)
}

