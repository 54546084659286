/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { message } from '../../../util/message';
import { getLoginUserInfo } from '../../login/loginRD';
import { getUrlParam, randomString } from '../../../util/logic';
import { version } from '../../../util/version';
import { isFullscreen, toggleFullscreen, screenUnwatchFull, screenWatchFull } from '../../../component/common/screenFull';
import { template as TEMP } from '../../../component/common/dapingTemplate';

import './template3.less';

class Template3 extends Component{

    constructor(props){
        super(props);

        this.onUpdateOnClick = this.onUpdateOnClick.bind(this);

        let editable = getUrlParam("editable", window.location?.search) === "1";
        let template = "template3";

        this.state = {
            template: `daping.template.${template}`,
            editable: editable,
            editableStyle: editable ? "editable" : "",
            topic: `daping/template/${template}`,
            templateConfig: TEMP.list.find(t => t.template === template),
        }
    }

    onUpdateOnClick(subTopic) {
        let { topic, mqttClient } = this.state;
        mqttClient.publish(`${topic}/${subTopic}`, JSON.stringify({ type: subTopic, action: "refresh" }))
    }

    resize() {
        this.setState({
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        })
    }

    screenOnChange(e) {
        this.setState({
            isFullScreen: isFullscreen(),
        }, () => {
            message.destroy();
            this.resize();
        });
    }

    init() {
        let { topic } = this.state;
        let { reqUserInfo } = this.props;
        const clientId = `${randomString()}_${moment().unix()}`;

        let mqtt = require('mqtt')
        let client = mqtt.connect('', {
            port: reqUserInfo.mqttInfo.ServerPort,
            hostname: reqUserInfo.mqttInfo.ServerHost,
            username: reqUserInfo.mqttInfo.Accout,
            password: reqUserInfo.mqttInfo.Password,
            clientId: clientId,
            protocol: 'wss',
        })
        let tagTopic = `${topic}/#`;
        console.log(`mqtt ${clientId} connected and will subscribe topic: ${tagTopic}`);

        client.on('connect', () => {
            client.subscribe(tagTopic, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe success`);
                }
            })
        })

        client.on('message', (dstTopic, message) => {
            if (dstTopic.indexOf(topic) !== 0) {
                return;
            }

            let content = {}
            let msg = message.toString();
            try {
                content = JSON.parse(msg);
                console.info(`mqtt message:`, dstTopic, content);
            } catch (error) {
                console.error(`mqtt message:`, dstTopic, msg);
            }
        })

        this.setState({
            mqttClient: client,
        })
    }

    uninit() {

    }

    mkMain() {
        let { templateConfig } = this.state;
        return <>
            <img className="template3-bg" alt="" src={templateConfig?.image} />
        </>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        screenUnwatchFull((e) => this.screenOnChange(e));
    }

    componentDidMount() {
        window.daping = this;

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninit()
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninit()
        }.bind(this)

        screenWatchFull((e) => this.screenOnChange(e));
        this.resize();
        this.init();
    }

    render() {
        return (<div className="template3">
            {this.mkMain()}
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Template3);

