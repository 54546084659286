/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Button } from 'antd';
import { drawBackgroud, drawColor } from '../../../component/common/theme';

class DrawRectangle extends Component{
    constructor(props){
        super(props);
    }

    drawDashLine([x1, y1], [x2, y2], step = 5) {
        var ctx = this.canvas.getContext("2d"); //获得画笔
        ctx.strokeStyle = drawColor[this.props.theme];
        const x = x2 - x1,
        y = y2 - y1,
        count = Math.floor(Math.sqrt(x * x + y * y) / step),
        xv = x / count,
        yv = y / count;
        ctx.beginPath();
        for (let i = 0; i < count; i++) {
            if (i % 2 === 0) {
                ctx.moveTo(x1, y1);
            } else {
                ctx.lineTo(x1, y1);
            }
            x1 += xv;
            y1 += yv;
        }
        ctx.lineTo(x2, y2);
    }

    drawDashRect(left, top, width, height, step = 5) {
        var ctx = this.canvas.getContext("2d"); //获得画笔
        this.drawDashLine([left, top], [left + width, top], step);
        ctx.stroke();
        this.drawDashLine(
            [left + width, top],
            [left + width, top + height],
            step
        );
        ctx.stroke();
        this.drawDashLine(
            [left + width, top + height],
            [left, top + height],
            step
        );
        ctx.stroke();
        this.drawDashLine([left, top + height], [left, top], step);
        ctx.stroke();
    }

    showLastHistory() {
        let ctx = this.canvas.getContext('2d');
        ctx.putImageData(this.history, 0, 0)
    }

    addHistoy() {
        let { width, height } = this.props;
        let ctx = this.canvas.getContext('2d');
        this.history = ctx.getImageData(0, 0, width, height);
    }

    updateRect(point) {
        let w = Math.abs(point.x - this.mousedown.x)
        let h = Math.abs(point.y - this.mousedown.y)

        let left = point.x > this.mousedown.x ? this.mousedown.x : point.x
        let top = point.y > this.mousedown.y ? this.mousedown.y : point.y

        this.drawDashRect(left, top, w, h, this.step)
        this.step = this.step === 5 ? 4 : 5;
        let ctx = this.canvas.getContext('2d');
        ctx.fillStyle = drawBackgroud[this.props.theme];
        ctx.fillRect(left, top, w, h);
    }

    clear() {
        let { width, height } = this.props;
        var ctx = this.canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height);
        this.addHistoy();
    }

    // clear handle
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let canvas = document.getElementById('rect');

         // 鼠标事件
        canvas.onmousedown = (e) => {
            let { width, height } = this.props;

            // console.log(e);
            e.preventDefault();
            let { onStart } = this.props;
            onStart && onStart(e);
            this.mousedown = { x: e.layerX, y: e.layerY }
            this.dragging = true
            var ctx = this.canvas.getContext("2d");
            ctx.clearRect(0, 0, width, height);
            this.addHistoy();
        }

        canvas.onmousemove = (e) => {
            e.preventDefault();
            if (this.dragging) {
                this.moving = true;
                this.showLastHistory()
                this.updateRect({ x: e.layerX, y: e.layerY })
                this.clientX = e.clientX
                this.clientY = e.clientY
            }
        }

        canvas.onmouseup = (e) => {
            e.preventDefault();
            if (this.dragging && this.moving) {
                let { onEnd } = this.props;
                onEnd && onEnd(e);
            }
            this.moving = false;
            this.dragging = false;
            this.addHistoy();
        }

        canvas.oncontextmenu = (e) => {
            // 鼠标右键
            if (e.button === 2) {
                let { onRightClick } = this.props;
                onRightClick && onRightClick(e);
            }
        }

        this.canvas = canvas;
        this.addHistoy()
    }

    render() {
        let { className, width, height, theme } = this.props;
        return <div className={className}>
            <div className={"tips " + theme}><Button className="description" type="link">请框选需要入会的设备</Button><Button type="link" onClick={(e) => {
                let { onCancel } = this.props;
                onCancel && onCancel(e);
            }}>退出</Button></div>
            <canvas id="rect" style={{height: 'inherit', width: 'inherit'}} width={width} height={height}></canvas>
        </div>
    }
}

export default DrawRectangle;

