
import { api } from './api';

export let apiDeviceTypeList = async (data, extra) => {
    return await api("/ccs/v1/operator/deviceTpye/list", data, extra)
}

// export let apiDeviceList = async (data, extra) => {
//     return await api("/ccs/v1/operator/device/sipNum/list", data, extra)
// }

export let apiDeviceList = async (data, extra) => {
    return await api("/cs/v1/mntn/user/list", data, extra)
}

export let apiDeviceTypeStatistic = async (data, extra) => {
    return await api("/ccs/v1/operator/device/typeId/count", data, extra)
}

export let apiScreenList = async (data, extra) => {
    return await api("/ccs/v1/operator/dispatchScreenList", data, extra)
}
