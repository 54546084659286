/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import { netRequest_reducer } from '../util/netReqRD'
import { login_reducer } from '../pages/login/loginRD'

export const reducer = combineReducers(
    {
        login: login_reducer,
        net_request: netRequest_reducer, 
    }
);




