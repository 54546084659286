/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts';
import { Button, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import ProForm, { ModalForm, ProFormText, ProFormList, ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import Editable from './editable';
import { getUrlParam, hexToRgba, rgbaToHex } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { CaretDownOutlined } from '@ant-design/icons';

class CardBar extends Component{

    constructor(props){
        super(props);

        let data = [];
        let category = ['项A', '项B', '项C', '项D', '项E', '项F']
        for (let i = 0; i < category.length; ++i) {
            data.push({ name:category[i], value: Math.round(Math.random() * 200 + 1) });
        }

        let defaultOption = {
            title: {
                show: true,
                text: '卡片主标题',
                textStyle: {
                    fontSize: 18,
                    color: '#fff',
                    fontWeight: 'normal',
                },
                subtext: '卡片副标题',
                subtextStyle: {
                    fontSize: 14,
                    color: '#689ad3',
                },
                padding: [2, 20],
            },
            grid: { // 图部分的边距距离
                top: 64,
                bottom: 40,
                left: 20,
                right: 20,
            },
            xAxis: {
                type: 'category',
                data: data.map(d => d.name),
                animationDuration: 300,
                animationDurationUpdate: 300,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: -20,
                    margin: 16,
                }
                
            },
            yAxis: {
                max: 'dataMax',
                type: 'value',
                show: false,
            },
            series: [{
                realtimeSort: true,
                name: 'X',
                type: 'bar',
                data: data.map(d => d.value),
                label: {
                    show: false, // 不显示柱状条的上的值
                },
                barWidth: 15, // 柱状条的宽度
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            { offset: 1, color: '#564aa3' },
                            { offset: 0.5, color: '#564aa3' },
                            { offset: 0, color: '#29abdf' },
                        ]
                    )
                },
            }],
            legend: {
                show: false
            },
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear',
            backgroundColor: 'transparent',
        }

        let defaultInd = {
            label1: "指标描述",
            label2: "指标项",
            value2: "指标值",
            value2Style: {
                color: '#43ba23'
            },
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
            defaultInd: defaultInd,
            ind: defaultInd,
        }
    }

    onRefresh(e) {
        let { number } = this.props;
        let key = `card${number}`;
        if (e?.type === key) {
            let { chart, defaultOption, defaultInd } = this.state;
            let { template } = this.props;

            async function init() {
                if (!chart) {
                    let dom = document.getElementById(`card${number}`);
                    chart = echarts.init(dom, 'dark');
                }
                let rsp1 = await apiDapingDataGet(template, `card${number}.option`);
                let option = rsp1?.Data || defaultOption;
                let rsp2 = await apiDapingDataGet(template, `card${number}.ind`);
                let ind = rsp2?.Data || defaultInd;
                chart.setOption(option);
                window.addEventListener("resize",function (){
                    setTimeout(() => {
                        chart.resize();
                    })
                });
                this.setState({
                    option: option,
                    ind: ind,
                    value2StyleTmpColor: hexToRgba(ind.value2Style.color),
                    loading: false,
                    chart: chart,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(ind, option) {
        let items = [];
        let names = option?.xAxis?.data || [];
        let values = option?.series?.[0]?.data || [];
        for (let i in names) {
            items.push({ name: names[i], value: values[i] })
        }
        this.setState({
            value2StyleTmpColor: hexToRgba(ind.value2Style.color),
        }, () => {
            this.form.setFieldsValue({
                ...ind,
                value2StyleColor: ind.value2Style.color,
                title: option?.title?.text,
                subTitle: option?.title?.subtext,
                items: items,
                barWidth: option?.series?.[0]?.barWidth,
            })
        })
    }

    mkEdit() {
        let { chart, option, ind, visible, value2StyleTmpColor } = this.state;
        let { template, onUpdate, number } = this.props;
        return <ModalForm
            width="80%"
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(ind, option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultInd, defaultOption } = this.state;
                            this.initForm(defaultInd, defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                option.title.text = values.title;
                option.title.subtext = values.subTitle;
                option.xAxis.data = values.items?.map(i => i.name);
                option.series[0].data = values.items?.map(i => i.value);
                option.series[0].barWidth = values.barWidth;
                let topics = [];
                let rsp1 = await apiDapingDataSet(template, `card${number}.option`, option);
                if (rsp1.Status === 0) {
                    let newInd = {
                        ...values,
                        value2Style: {
                            color: values.value2StyleColor,
                        },
                    }
                    let rsp2 = await apiDapingDataSet(template, `card${number}.ind`, newInd);
                    if (rsp2.Status === 0) {
                        this.setState({
                            ind: newInd,
                            option: option,
                            visible: false,
                        }, () => {
                            chart?.setOption && chart.setOption(option);
                            onUpdate && onUpdate(`card${number}`);
                        })
                    }
                }
                return true;
            }}
        >
            <ProCard split="vertical">
                <ProCard colSpan="30%">
                    <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
                    <ProFormText name="subTitle" label="副标题" placeholder="请输入副标题" />
                    <ProFormText name="label1" label="指标描述" placeholder="请输入描述" />
                    <ProFormText name="label2" label="指标项" placeholder="请输入描述" />
                    <ProFormText name="value2" label="指标值" placeholder="请输入数据" />
                    <ProFormText
                        name="value2StyleColor"
                        label="指标值颜色"
                        placeholder="请输入数据"
                        allowClear={false}
                        fieldProps={{
                            suffix: <Popover
                                trigger="click"
                                placement="bottom"
                                content={<SketchPicker width="400px" color={value2StyleTmpColor} onChangeComplete={(e) => {
                                    ind.value2Style = { ...ind.value2Style, color: e.hex };
                                    this.setState({
                                        value2StyleTmpColor: e.rgb
                                    }, () => {
                                        this.form.setFieldsValue({
                                            value2StyleColor: rgbaToHex(e.rgb),
                                        })
                                    })
                                }}/>}
                            >
                                <div className="color-pick-item">
                                    <div className="color-pick-item-block" style={{
                                        background: rgbaToHex(value2StyleTmpColor) || '#fff',
                                    }}/>
                                    <CaretDownOutlined className="color-pick-item-icon"/>
                                </div>
                            </Popover>,
                        }}
                    />
                </ProCard>
                <ProCard colSpan="70%">
                    <ProFormDigit name="barWidth" label="柱状条宽度" min={0} placeholder="请输入数据" tooltip="0代表自适应"/>
                    <ProFormList name="items" label="数据项">
                        <ProForm.Group size={16}>
                            <ProFormText name="name" label="名称" />
                            <ProFormDigit name="value" label="值" min={0} />
                        </ProForm.Group>
                    </ProFormList>
                </ProCard>
            </ProCard>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let { number } = this.props;
        this.onRefresh({ action: "refresh", type: `card${number}` })
    }

    render() {
        let { editable, loading, ind } = this.state;
        let { number } = this.props;
        return <>
            {this.mkEdit()}
            <div className="decorate" />
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div className="decorate-card-number">{`0${number}`}</div>
                <div className={`card${number}`}>
                    <div id={`card${number}`} className={`card${number}-chart`} />
                    <div className={`card${number}-summary`}>
                        <div className="summary-style-1">
                            <div className="summary-style-1-field1">
                                <div className="label">{ind.label1}</div>
                                <div className="value">{ind.label2}</div>
                            </div>
                            <div className="summary-style-1-field2">
                                <div className="label" style={{opacity: 0}}>_</div>
                                <div className="value" style={ind.value2Style}>{ind.value2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Editable>
        </>
    }
}

export default CardBar;

