/* eslint-disable no-unused-vars */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Breadcrumb, Card, Result, Button } from 'antd';
import { menus } from '../laylout/menu';
import { IconFont } from '../util/hoComponent';
import { getMenus } from '../util/logic';

import './page.less'

class Page extends Component {
    
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        let magicNumber = (Math.random() * 100).toFixed(0);
        let { breadcrumbs, subMenus } = getMenus(menus, this.props.location)

        return <div className="ho-breadcrumb">
            <Breadcrumb>
                <Breadcrumb.Item key="main" >
                    <Button type="link" className="ho-breadcrumb-item-link nopadding" onClick={(e) => window.goToMenu("")}>主页</Button>
                </Breadcrumb.Item>
                {breadcrumbs.map((item, index) => {
                    if (item.breadcrumbName) {
                        let content = <Button type="link" className="ho-breadcrumb-item nopadding" style={{padding: 0}}>{item.breadcrumbName}</Button>
                        if (item.url) {
                            content = <Button type="link" className="ho-breadcrumb-item-link nopadding" onClick={(e) => window.goToMenu(item.path)}>{item.breadcrumbName}</Button>
                        }
                        return <Breadcrumb.Item key={index}>
                            {content}
                        </Breadcrumb.Item>
                    }
                    return undefined;
                })}
            </Breadcrumb>
            <ul className="menu-page-list">
                {
                    subMenus?.length > 0 ? subMenus.map((menu, index) => {
                        return <li key={index}>
                            <Card
                            className={"menu-card menu-background" + ((magicNumber + index) % 8 + 1)}
                                style={{ width: 300, height: 200 }}
                                onClick={() => {
                                    window.goToMenu(menu.key)
                                }}
                            >
                                <Card.Meta title={<Fragment>
                                        <IconFont className="menu-page-icon" type={menu.icon} />
                                        <span>{menu.title}</span>
                                    </Fragment>}/>
                            </Card>
                        </li>
                    }) : undefined
                }
            </ul>
            {
                subMenus?.length > 0 ? <div className="menu-page-title">选择一个你感兴趣的菜单</div> : <Result className="menu-page-empty" status="404" title="404" subTitle="没有发现你要找的页面"/>
            }
        </div>
    }
}


export default connect(
    null, 
    null
)(Page);
