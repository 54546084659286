
import { api } from './api';

export let apiRoleList = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/role/list", data, extra)
}

export let apiRoleAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/role/add", data, extra)
}

export let apiRoleMod = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/role/mod", data, extra)
}

export let apiRoleDel = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/role/del", data, extra)
}
