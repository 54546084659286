/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Spin, Popconfirm, Space, Button, Modal, Input, Dropdown } from 'antd';
import { PlusOutlined, EditOutlined, StarFilled, ReloadOutlined, SettingOutlined, FullscreenOutlined, DownOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import { StatisticCard } from '@ant-design/pro-card';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import { pagization } from '../../api/api';
import { apiStaffList, apiStaffAdd, apiStaffDel, apiStaffMod, apiStaffRoleStatistic, apiStaffDeviceAdd, apiStaffDeviceDel } from '../../api/staff';
import { apiRoleList } from '../../api/role';
import { apiOrganizationGet } from '../../api/organization';
import { apiTerminalMessage } from '../../api/terminal';
import { Page } from '../../component/antd/page';
import Number from '../../component/tweenOneIcon/number';
import { PaginationPositionIcon, PicPreview } from '../../component/antd/antd';
import { menus } from '../../laylout/menu';
import { getLoginUserInfo } from '../login/loginRD';
import { message } from '../../util/message';
import { getMenus, randomString, subSet } from '../../util/logic';
import { DEVICE } from '../../component/common/common';
import Terminal from '../../component/common/terminal';
import Conference from '../../component/common/conference';

import './staff.less';

class Staff extends Component{

    constructor(props){
        super(props);

        const columns = [
            {
                title: '头像',
                type: 'str',
                width: '15%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => {
                    return <div className="staff-avatar"><img alt="" src={text}/></div>
                }
            },
            {
                title: '姓名',
                type: 'str',
                width: '15%',
                dataIndex: 'NickName',
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '15%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '手机号',
                type: 'str',
                width: '15%',
                dataIndex: 'PhoneNum',
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let options = [];
                    options.push(<Button key="detail" type="link" className="nopadding" onClick={()=> {
                        window.goToMenu(`user/staff/${record.Id}`);
                    }}>详情</Button>)
                    options.push(this.mkEdit(record))
                    options.push(<Popconfirm
                        key={record.Id}
                        title={`是否删除人员${record?.NickName}？`}
                        onConfirm={async () => {
                            let rsp = await apiStaffDel(record);
                            if (rsp.Status === 0) {
                                message.success('删除人员成功');
                                this.tableAction.reload()
                                return true;
                            }
                        }}
                        okText="确定"
                        cancelText="我再想想"
                    >
                        <Button key="delete" type="link" className="nopadding">删除</Button>
                    </Popconfirm>)
                    return options;
                },
            },
        ];

        const terminalColumns = [
            {
                title: '状态',
                type: 'strEnumMultiple',
                width: 56,
                dataIndex: 'State',
                search: false,
                valueType: 'select',
                valueEnum: DEVICE.STATE.valueEnum(),
                render: (_, record) => DEVICE.STATE.columnRender(record)
            },
            {
                title: '类型',
                type: 'intEnumMultiple',
                width: '10%',
                dataIndex: 'TypeId',
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '名称',
                type: 'str',
                width: '15%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '15%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '号码',
                type: 'str',
                width: '30%',
                dataIndex: 'SipNum',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '设备类型',
                type: 'int',
                width: '10%',
                hideInSearch: true,
                hideInTable: true,
                dataIndex: 'TypeId',
                initialValue: DEVICE.TYPE.TERMINAL,
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                render: (_, record) => {
                    let options = [];
                    switch (record.TypeId) {
                        case DEVICE.TYPE.PHONE:
                            options.push(<Button disabled={record.State !== 'online'} key={`${record.TypeId}-diaodu`} type="link" className="nopadding" onClick={() => {
                                this.setState({
                                    conferenceVisible: true,
                                    conferenceMember: record,
                                }, () => {
                                    message.loading("正在调度", 30, 'diaodu')
                                })
                            }}>调度</Button>)
                            let component = <ModalForm
                                key={record.Id}
                                formRef={ref => record.editForm = ref}
                                title="推送消息"
                                labelwidth="auto"
                                trigger={<Button disabled={record.State !== 'online'} key={`${record.TypeId}-message`} type="link" className="nopadding">推送消息</Button>}
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        record.editForm.setFieldsValue({
                                            Title: "实时消息",
                                            Content: "",
                                        })
                                    }
                                }}
                                onFinish={async (values) => {
                                    let rsp = await apiTerminalMessage({
                                        DeviceId: record.Id,
                                        Title: values.Title,
                                        Content: values.Content,
                                    })
                                    if (rsp.Status === 0) {
                                        message.success('推送消息成功');
                                        return true;
                                    }
                                    return false;
                                }}
                            >
                                <ProFormText
                                    width="lg"
                                    name="Title"
                                    label="标题"
                                    placeholder="请输入标题"
                                    rules={[{ required: true, message: '请输入标题' }]}
                                />
                                <ProFormTextArea
                                    width="lg"
                                    name="Content"
                                    label="内容"
                                    placeholder="请输入内容"
                                    rules={[{ required: true, message: '请输入内容' }]}
                                />
                            </ModalForm>
                            options.push(component)
                            break;
                        case DEVICE.TYPE.TELEPHONE:
                            options.push(<Button key={`${record.TypeId}-call`} type="link" className="nopadding" onClick={() => {
                                message.info("暂不支持该操作")
                            }}>呼叫</Button>)
                            break;
                        default:
                            break;
                    }
                    return options;
                },
            },
        ];

        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            paginationConfig: {
                position: ['bottomRight'],
                showQuickJumper: true,
            },
            terminalSelectedRows: [],
            terminalColumns: terminalColumns,
        }
        this.staffTable = {}
    }

    initMqtt() {
        let { reqUserInfo } = this.props;
        const clientId = `${randomString()}_${moment().unix()}`;

        let mqtt = require('mqtt')
        let client = mqtt.connect('', {
            port: reqUserInfo.mqttInfo.ServerPort,
            hostname: reqUserInfo.mqttInfo.ServerHost,
            username: reqUserInfo.mqttInfo.Accout,
            password: reqUserInfo.mqttInfo.Password,
            clientId: clientId,
            protocol: 'wss',
        })
        // 订阅设备上下线消息
        let tagTopic1 = `cs/mntn/notify/device`;
        client.on('connect', () => {
            client.subscribe(`${tagTopic1}/#`, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe ${tagTopic1} success`);
                }
            })
        })

        client.on('message', (dstTopic, message) => {
            let content = {}
            let msg = message.toString();
            try {
                content = JSON.parse(msg);
                console.info(`mqtt message:`, dstTopic, content);
            } catch (error) {
                console.error(`mqtt message:`, dstTopic, msg);
            }

            if (dstTopic.startsWith(tagTopic1)) {
                switch (content?.State) {
                    default: {
                        let { data } = this.state;
                        if (data?.find(s => s.DeviceList?.find(r => r.SipNum === content?.SipNum))) {
                            this.onRefresh(500);
                        }
                        break;
                    }
                }
            }
        })

        this.setState({
            mqttClient: client,
        })
    }

    initColumn() {
        let { columns } = this.state;
        let fun = async () => {
            let nowColumns = [];
            // 获取角色信息
            let rsp2 = await apiRoleList({ PageNum: 1, PageSize: 1000, }, { ignoreError: true })
            let roleData = rsp2?.RecordList || []
            let valueEnum = {}
            if (roleData !== undefined) {
                roleData.forEach(r => {
                    valueEnum[r.Id] = {
                        text: r.Name
                    };
                })
                nowColumns = [
                    ...columns.slice(0, 4),
                    {
                        title: '角色',
                        type: 'intEnumMultiple',
                        width: '10%',
                        dataIndex: 'RoleId',
                        fieldProps: {
                            mode: 'multiple',
                        },
                        valueType: 'select',
                        valueEnum: valueEnum,
                    },
                    ...columns.slice(4),
                ]
            }

            // 获取部门信息
            let rsp3 = await apiOrganizationGet({}, { ignoreError: true })
            let orgData = [];
            try {
                orgData = JSON.parse(rsp3?.Data || "[{\"key\": \"_\", \"children\": [], \"title\": \"总部\"}]");
            } catch (error) {
                console.error("parse json error", error);
            }
            let dataList = [];
            const generateList = data => {
                for (let node of data) {
                    dataList.push(node);
                    if (node.children) {
                        generateList(node.children);
                    }
                }
            };
            generateList(orgData);
            let orgValueEnum = {}
            dataList.forEach(r => {
                orgValueEnum[r.key] = {
                    text: r.title
                };
            })
            nowColumns = [
                ...nowColumns.slice(0, 5),
                {
                    title: '部门',
                    type: 'strEnumMultiple',
                    width: '10%',
                    dataIndex: 'Department',
                    fieldProps: {
                        mode: 'multiple',
                    },
                    valueType: 'select',
                    valueEnum: orgValueEnum,
                    render: (text, record) => {
                        return <div>{dataList.find(d => d.key === record?.Department)?.title || "-"}</div>
                    }
                },
                ...nowColumns.slice(5),
            ]

            this.setState({
                breadcrumbs: getMenus(menus, this.props.location)?.breadcrumbs,
                roleData: roleData,
                columns: nowColumns,
                orgData: dataList,
            }, () => {
                this.onRefresh()
            })
        }
        fun()
    }

    init() {
        this.initMqtt()
        this.initColumn()
    }

    uninit() {
        let { mqttClient } = this.state;
        mqttClient && mqttClient.end()
        this.deviceConference && this.deviceConference.destroyRoom();
    }

    async modTerminal(staff) {

        let dstTerminals = this.state.terminalSelectedRows.map(r => r.Id) || [];
        let srcTerminals = staff?.DeviceList?.map(t => t.Id) || []
        let addTerminals = subSet(dstTerminals, srcTerminals)
        let delTerminals = subSet(srcTerminals, dstTerminals)

        console.group("staff mod terminal")
        console.log("staff", staff);
        console.group("terminal")
        console.log("src", srcTerminals);
        console.log("dst", dstTerminals);
        console.groupEnd();

        if (addTerminals?.length > 0) {
            for (let d of addTerminals) {
                let rsp = await apiStaffDeviceAdd({ StaffId: staff?.Id, DeviceId: d })
            }
        }

        if (delTerminals?.length > 0) {
            for (let d of delTerminals) {
                let rsp = await apiStaffDeviceDel({ StaffId: staff?.Id, DeviceId: d })
            }
        }

        this.setState({
            terminalSelectedRows: [],
        })

        return true;
    }

    onRefresh(timeout) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = setTimeout(async () => {
            let { roleData } = this.state;
            let rsp2 = await apiStaffRoleStatistic();
            let roleStatistic = [];
            let roleStatisticTotal = 0;
            // eslint-disable-next-line no-unused-expressions
            rsp2?.RecordList?.forEach(d => {
                let role = roleData?.find(r => r.Id === d.RoleId);
                roleStatistic.push({ name: role?.Name, count: d.Count, id: role?.Id })
                roleStatisticTotal += d.Count;
            })
            this.tableAction.reload();
            this.setState({
                roleStatistic,
                roleStatisticTotal,
            })
        }, timeout || 0);
        
    }

    mkConference() {
        let { conferenceVisible, conferenceMember } = this.state;
        return <Modal
            title="调度"
            visible={conferenceVisible}
            width={688}
            onCancel={() => {
                this.setState({
                    conferenceVisible: false,
                    conferenceMember: undefined,
                }, () => {
                    message.success("调度结束", 3, 'diaodu')
                })
            }}
            maskClosable={false}
            destroyOnClose={true}
            footer={null}
        >
            <Conference
                className="preview"
                ref={(element) => this.deviceConference = element}
                reqUserInfo={this.props.reqUserInfo}
                dispatch={this.props.dispatch}
                onInitOk={(profile) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onInitOk", profile);
                    this.setState({
                        sdkInit: true,
                        tips: <div>就绪</div>,
                        profile: profile,
                    }, () => {
                        if (this.deviceConference && conferenceMember) {
                            this.deviceConference.createRoom([conferenceMember])
                        }
                    })
                }}
                onInitError={(errCode, errDesc) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onInitError", errCode, errDesc);
                    this.setState({
                        tips: <div><Spin size="small" />正在准音视频组件...</div>,
                        sdkInit: false,
                    })
                }}
                onRoomOk={(room) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onRoomOk", room);
                    this.setState({
                        room: room,
                    })
                }}
                onRoomError={(errorCode, errorDesc) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onRoomError", errorCode, errorDesc);
                    this.setState({
                        room: null,
                    })
                }}
                onRoomDestroy={() => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onRoomDestroy");
                    this.setState({
                        room: null,
                    })
                }}
                onRoomChange={(room) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onRoomChange");
                    this.setState({
                        room: room,
                    })
                }}
                onMemberChange={(type, member, members) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    console.log("onMemberChange", type, member, members);
                    let { profile } = this.state;
                    switch (type) {
                        case window.vmeeting.ACTION.MEMBER.JOINED:
                            if (profile?.userId === member.userId) {
                                message.success("你已加入调度", 5, member.userId)
                            }else {
                                clearTimeout(this.joinNotifyMemberTimer)
                                if (!this.joinNotifyMembers?.length) {
                                    this.joinNotifyMembers = [];
                                }
                                let nickName = conferenceMember?.SipNum === member.userId ? conferenceMember?.Name : member.nickName;
                                this.joinNotifyMembers.push(nickName)
                                this.joinNotifyMemberTimer = setTimeout(() => {
                                    this.joinNotifyMemberTimer = 0;
                                    let msg = `${nickName}已加入`;
                                    if (this.joinNotifyMembers?.length > 1) {
                                        msg = `${this.joinNotifyMembers.slice(0, 3).join(',')}...已加入`
                                    }
                                    message.info(msg, 3, 'member_joined')
                                    message.success("调度成功", 3, 'diaodu')
                                    this.joinNotifyMembers = [];
                                }, 1000);
                                
                            }
                            break;
                        case window.vmeeting.ACTION.MEMBER.LEAVED:
                            if (profile?.userId === member.userId) {
                                message.info("你已退出调度", 5, member.userId)
                            }else {
                                clearTimeout(this.leaveNotifyMemberTimer)
                                if (!this.leaveNotifyMembers?.length) {
                                    this.leaveNotifyMembers = [];
                                }
                                let nickName = conferenceMember?.SipNum === member.userId ? conferenceMember?.Name : member.nickName;
                                this.leaveNotifyMembers.push(nickName)
                                this.leaveNotifyMemberTimer = setTimeout(() => {
                                    this.leaveNotifyMemberTimer = 0;
                                    let msg = `${nickName}已退出`;
                                    if (this.leaveNotifyMembers?.length > 1) {
                                        msg = `${this.leaveNotifyMembers.slice(0, 3).join(',')}...已退出`
                                    }
                                    message.info(msg, 3, 'member_leave')
                                    this.leaveNotifyMembers = [];
                                }, 1000);
                            }
                            break;
                        default:
                            break;
                    }
                    this.setState({
                        members: members,
                    })
                }}
                onViewChange={(view) => {
                    if (!conferenceVisible) {
                        return;
                    }
                    this.setState({
                        view: view,
                    })
                }}
            />
        </Modal>
    }

    mkPageHeaderExtra() {
        let { roleStatistic, roleStatisticTotal } = this.state;
        return (<Spin spinning={!roleStatistic}>
            <StatisticCard.Group>
                <StatisticCard statistic={{
                    title: '总计', valueRender: () => {
                        return <span className="hover" onClick={() => {
                            if (this.table) {
                                this.table.setFieldsValue({
                                    RoleId: undefined,
                                })
                                this.table.submit();
                            }
                        }}><Number value={roleStatisticTotal || 0} /></span>
                    }
                }}
                />
                <StatisticCard.Divider />
                {
                    roleStatistic?.map(r => {
                        return <StatisticCard key={`role-${r.id}`} statistic={{
                            title: r.name, valueRender: () => {
                                return <span className="hover" onClick={() => {
                                    if (this.table) {
                                        this.table.setFieldsValue({
                                            RoleId: [r.id],
                                        })
                                        this.table.submit();
                                    }
                                }}><Number value={r?.count || 0}/></span>
                            }
                        }}
                        />
                    })
                }
            </StatisticCard.Group>
        </Spin>)
    }

    mkForm(operation) {
        let { avatarFile, terminalSelectedRows } = this.state;
        return <>
            <ProForm.Group direction="vertical">
                {PicPreview.ProFormUploadButton.bind(this)(avatarFile)}
                <ProForm.Group>
                    <ProForm.Group direction="vertical">
                        <ProFormText
                            width="lg"
                            name="NickName"
                            label="姓名"
                            placeholder="请输入姓名"
                            rules={[{ required: true, message: '请输入姓名' }]}
                        />
                        <ProFormText
                            width="lg"
                            name="SerialNumber"
                            label="编号"
                            placeholder="请输入编号"
                            rules={[{ required: true, message: '请输入编号' }]}
                        />
                        <ProFormText
                            width="lg"
                            name="PhoneNum"
                            label="手机号"
                            placeholder="请输入手机号"
                        />
                    </ProForm.Group>
                    <ProForm.Group direction="vertical">
                        <ProFormSelect
                            name="RoleId"
                            label="角色"
                            width="lg"
                            request={async () => {
                                let { roleData } = this.state;
                                return roleData?.map(r => { return { label: r.Name, value: r.Id } });
                            }}
                            placeholder="请选择角色"
                            rules={[{ required: true, message: '请选择角色' }]}
                        />
                        <ProFormSelect
                            name="Department"
                            label="部门"
                            width="lg"
                            request={async () => {
                                let { orgData } = this.state;
                                return orgData?.map(r => { return { label: r.title, value: r.key } });
                            }}
                            placeholder="请选择部门"
                        />
                    </ProForm.Group>
                </ProForm.Group>
            </ProForm.Group>
            <div className="ant-row ant-form-item ant-form-item-has-success">
                <div className="ant-col ant-form-item-label">
                    <label>设备</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    <div className="form-content-operation">
                        <Button className="form-content-operation-btn" icon={<EditOutlined />} type="default" onClick={() => {
                            this.setState({
                                newTerminalVisible: true,
                            })
                        }}>编辑</Button>
                        <div className="form-content-operation-description">
                            <span>数量：<span>{terminalSelectedRows?.length || 0}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    mkEdit(record) {
        return <ModalForm
            key={record.Id}
            formRef={ref => record.editForm = ref}
            title="编辑人员"
            width="50%"
            labelwidth="auto"
            trigger={<Button key="edit" type="link" className="nopadding">编辑</Button>}
            onVisibleChange={(visible) => {
                if (visible) {
                    let file = PicPreview.newFile(record.AvatarUrl);
                    this.setState({
                        avatarFile: file,
                        terminalSelectedRows: record?.DeviceList || [],
                    })
                    record.editForm.setFieldsValue(Object.assign({}, record, { AvatarUrl: file ? [file] : undefined }))
                }
            }}
            onFinish={async (values) => {
                let rsp = await apiStaffMod(Object.assign(record, Object.assign(values, {
                    AvatarUrl: values.AvatarUrl?.[0]?.url || "",
                    editForm: undefined,
                })));
                if (rsp.Status === 0) {
                    message.success('编辑人员成功');
                    await this.modTerminal(record)
                    this.tableAction.reload()
                    return true;
                }
                return false;
            }}
        >
            {this.mkForm('edit')}
        </ModalForm>
    }

    mkNew() {
        return <ModalForm
            formRef={ref => this.newForm = ref}
            title="添加人员"
            width="50%"
            labelwidth="auto"
            trigger={<Button key="add" type="primary" icon={<PlusOutlined/>}>添加</Button>}
            onFinish={async (values) => {
                let rsp = await apiStaffAdd(Object.assign(values, { AvatarUrl: values.AvatarUrl?.[0]?.url || "" }));
                if (rsp.Status === 0) {
                    message.success('添加人员成功');
                    await this.modTerminal(rsp)
                    this.tableAction.reload()
                    return true;
                }
                return false;
            }}
            onVisibleChange={(visible) => {
                if (visible) {
                    this.newForm.setFieldsValue({
                        AvatarUrl: undefined,
                        SerialNumber: '',
                        NickName: '',
                        RoleId: undefined,
                        Department: undefined,
                    })
                    this.setState({
                        avatarFile: undefined,
                    })
                }
            }}
        >
            {this.mkForm('new')}
        </ModalForm>
    }

    mkNewDevice() {
        let { newTerminalVisible, terminalSelectedRows } = this.state;
        return <Modal
            width="80%"
            visible={newTerminalVisible}
            cancelButtonProps={{ className: 'none' }}
            onCancel={() => {
                this.setState({
                    newTerminalVisible: false,
                })
            }}
            okText="完成"
            onOk={() => {
                this.setState({
                    newTerminalVisible: false,
                })
            }}
        >
            <Terminal
                valid={{TypeId: [DEVICE.TYPE.PHONE]}}
                selectedRowKeys={terminalSelectedRows?.map(r => r.Id) || []}
                onAdd={(selectedRows) => {
                    selectedRows.forEach(k => {
                        if (!terminalSelectedRows.find(r => r.Id === k.Id)) {
                            terminalSelectedRows.push(k)
                        }
                    })
                    
                    this.setState({
                        terminalSelectedRows: terminalSelectedRows
                    }, () => {
                    })
                }}
                onDel={(selectedRows) => {
                    selectedRows.forEach((k, i) => {
                        let index = terminalSelectedRows.findIndex(r => r.Id === k.Id)
                        if (index !== -1) {
                            terminalSelectedRows = [
                                ...terminalSelectedRows.slice(0, index),
                                ...terminalSelectedRows.slice(index + 1),
                            ]
                        }
                    })
                    
                    this.setState({
                        terminalSelectedRows: terminalSelectedRows
                    }, () => {
                    })
                }}
            />
        </Modal>
    }


    mkPageBody() {
        let { columns, paginationData, paginationConfig, terminalColumns } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={(e) => this.onRefresh()}>刷新</Button>,
            this.mkNew(),
            // <Button key="export" type="primary" icon={<DownloadOutlined/>}>导出</Button>,
            <PaginationPositionIcon
                paginationConfig={paginationConfig}
                onChange={(key) => {
                paginationConfig.position = [key]
                    this.setState({
                        paginationConfig: paginationConfig,
                    })
                }}
            />,
        ]

        return (
            <div className="staff-page-body">
                <div className="staff-page-body-table-col">
                    <ProTable
                        formRef={(ref) => this.table = ref}
                        actionRef={(ref) => this.tableAction = ref}
                        columns={columns}
                        request={async (params, sorter, filter) => {
                            // 表单搜索项会从 pagination 传入，传递给后端接口。
                            console.group('request');
                            console.log("params:", params);
                            console.log("sorter:", sorter);
                            console.log("filter:", filter);
                            console.groupEnd();
                            
                            let rsp = await apiStaffList(pagization(params, sorter, filter, columns))
                            if (rsp.Status === 0) {
                                this.setState({
                                    data: rsp?.RecordList || [],
                                    paginationData: {
                                        current: params?.current,
                                        pageSize: params?.pageSize,
                                        total: rsp?.TotalNum,
                                    },
                                }, () => {
                                    for (let key in this.staffTable) {
                                        let ref = this.staffTable[key];
                                        ref && ref.reset();
                                    }
                                })
                            }
                            return {
                                data: rsp?.RecordList || [],
                                total: rsp?.TotalNum,
                                success: true,
                            };
                        }}
                        expandable={{
                            expandedRowRender: (record) => {
                                return <div className="staff-page-body-table-col-row-terminal">
                                    <ProTable
                                        actionRef={ref => this.staffTable[record.Id] = ref}
                                        headerTitle="设备列表"
                                        size="small"
                                        columns={terminalColumns}
                                        rowKey="Id"
                                        search={false}
                                        bordered={true}
                                        dateFormatter="string"
                                        options={false}
                                        pagination={false}
                                        request={async (params, sorter, filter) => {
                                            console.group('request device', record.Id);
                                            console.log("params:", params);
                                            console.log("sorter:", sorter);
                                            console.log("filter:", filter);
                                            console.log("data:", record.DeviceList);
                                            console.groupEnd();
                                            let list = Object.assign([], record.DeviceList || []);
                                            if (record.PhoneNum) {
                                                list.unshift({Id:record.PhoneNum, TypeId: DEVICE.TYPE.TELEPHONE, SipNum: record.PhoneNum })
                                            }
                                            return {
                                                data: list,
                                                success: true,
                                            };
                                        }}
                                    />
                                </div>
                            },
                        }}
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            },
                            getCheckboxProps: (record) => ({
                                Id: record.Id,
                            }),
                        }}
                        tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => {
                            return (
                                <Space size={16}>
                                    <Button className="nopadding" type="link">批量删除</Button>
                                    <Button className="nopadding" type="link">导出数据</Button>
                                    <Button className="nopadding" type="link" onClick={onCleanSelected}>取消选择</Button>
                                </Space>
                            );
                        }}
                        rowKey="Id"
                        pagination={{
                            ...paginationConfig,
                            ...paginationData,
                        }}
                        toolbar={{
                            // multipleLine: true,
                        }}
                        search={{ filterType: "light" }}
                        dateFormatter="string"
                        headerTitle="人员列表"
                        options={{
                            density: true,
                            setting: true,
                            reload: false,
                            fullScreen: false,
                            search: false,
                        }}
                        toolBarRender={() => operations}
                    />
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="staff-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        this.uninit();
    }

    componentDidMount() {
        window.staff = this;
        this.init()
    }

    render(){
        return (<div>
            {<PicPreview {...PicPreview.props.bind(this)(this.state)} />}
            {this.mkConference()}
            {this.mkNewDevice()}
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Staff);

