/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Menu, Spin, Modal, Collapse, Drawer, Tooltip, Button, Input, Descriptions } from 'antd';
import { CaretLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import { mapBackgroud, mapColor, mapStyle, menuBackgroud, menuColor } from '../../../component/common/theme';
import { IconFont } from '../../../util/hoComponent';
import Clock from '../../../component/common/clock';
import { message } from '../../../util/message';
import { getIconSrc } from '../../../resource/resource';
import { getLoginUserInfo } from '../../login/loginRD';
import { isFullscreen, toggleFullscreen, screenUnwatchFull, screenWatchFull, screenWatchResize, screenResize } from '../../../component/common/screenFull';
import { search } from '../../../api/api';
import { apiStaffList, apiStaffRoleStatistic, apiStaffSetLocation } from '../../../api/staff';
import { apiRoleList } from '../../../api/role';
import { apiTerminalList, apiTerminalMessage, apiTerminalSetLocation } from '../../../api/terminal';
import { apiDeviceTypeStatistic, apiScreenList } from '../../../api/device';
import { apiTaskDetail, apiTaskList, apiTaskStateStatistic } from '../../../api/task';
import { apiOrganizationGet } from '../../../api/organization';
import { getUrlParam, randomString } from '../../../util/logic';
import { version } from '../../../util/version';
import { DEVICE, TASK } from '../../../component/common/common';
import Member from './member';
import DrawRectangle from './drawRectangle';
import Conference from './conference';

import './situation.less';

class Situation extends Component{

    constructor(props){
        super(props);

        const staffColumns = [
            {
                title: '头像',
                type: 'str',
                width: '15%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => {
                    return <div className="staff-avatar"><img alt="" src={text}/></div>
                }
            },
            {
                title: '编号',
                type: 'str',
                width: '15%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '姓名',
                type: 'str',
                width: '15%',
                dataIndex: 'NickName',
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '手机号',
                type: 'str',
                width: '15%',
                dataIndex: 'PhoneNum',
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                width: '20%',
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let { room, task, linkedScreen, mode } = this.state;
                    let options = [];
                    options.push(<Button key="aim" type="link" className="nopadding" onClick={() => {
                        if (record.Lng && record.Lat) {
                            this.setState({
                                staffListVisible: false,
                            }, () => {
                                this.map.setCenter(new window.BMapGL.Point(record.Lng, record.Lat), {
                                    noAnimation: false,
                                })
                            })
                        } else {
                            message.error(`${record.NickName}暂无位置信息`)
                        }
                    }}>定位</Button>)
                    options.push(<Button key="aim" type="link" className="nopadding" onClick={() => {
                        if (record.Lng && record.Lat) {
                            this.setState({
                                staffListVisible: false,
                            }, () => {
                                let to = new window.BMapGL.Point(task?.MissionInfo?.CentreLng, task?.MissionInfo?.CentreLat);
                                this.routeOnClick(record, to)
                            })
                        } else {
                            message.error(`${record.NickName}暂无位置信息无法规划路径`)
                        }
                    }}>路径</Button>)
                    return options;
                },
            },
        ];

        const deviceColumns = [
            {
                title: '状态',
                type: 'strEnumMultiple',
                width: '10%',
                dataIndex: 'State',
                search: false,
                valueType: 'select',
                valueEnum: DEVICE.STATE.valueEnum(),
                render: (_, record) => DEVICE.STATE.columnRender(record)
            },
            {
                title: '照片',
                type: 'str',
                width: '10%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => {
                    return <div className="terminal-avatar"><img alt="" src={text}/></div>
                }
            },
            {
                title: '类型',
                type: 'int',
                width: '10%',
                hideInSearch: true,
                dataIndex: 'TypeId',
                initialValue: DEVICE.TYPE.TERMINAL,
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '名称',
                type: 'str',
                width: '20%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '20%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: 'SIP号',
                type: 'str',
                width: '20%',
                dataIndex: 'SipNum',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                width: '20%',
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let { room, task, linkedScreen, mode } = this.state;
                    let options = [];
                    options.push(<Button key="aim" type="link" className="nopadding" onClick={() => {
                        if (record.Lng && record.Lat) {
                            this.setState({
                                deviceListVisible: false,
                            }, () => {
                                this.map.setCenter(new window.BMapGL.Point(record.Lng, record.Lat), {
                                    noAnimation: false,
                                })
                            })
                        } else {
                            message.error(`设备${record.Name}暂无位置信息`)
                        }
                    }}>定位</Button>)
                    options.push(<Button key="diaodu" type="link" className="nopadding"
                        disabled={!(DEVICE.STATE.isIdle(record) && ((mode === 'controlled' && linkedScreen) || mode !== 'controlled'))}
                        onClick={() => {
                            this.setState({
                                deviceListVisible: false,
                            }, () => {
                                if (!room) {
                                    this.deviceConference && this.deviceConference.createRoom([record], task?.MissionInfo, linkedScreen);
                                } else {
                                    this.deviceConference && this.deviceConference.inviteMember([record]);
                                }
                            })
                        }
                        }>调度</Button>)
                    return options;
                },
            },
            
        ];

        const terminalColumns = [
            {
                title: '状态',
                type: 'strEnumMultiple',
                width: 56,
                dataIndex: 'State',
                search: false,
                valueType: 'select',
                valueEnum: DEVICE.STATE.valueEnum(),
                render: (_, record) => DEVICE.STATE.columnRender(record)
            },
            {
                title: '类型',
                type: 'intEnumMultiple',
                width: '10%',
                dataIndex: 'TypeId',
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '名称',
                type: 'str',
                width: '15%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '15%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '号码',
                type: 'str',
                width: '30%',
                dataIndex: 'SipNum',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '设备类型',
                type: 'int',
                width: '10%',
                hideInSearch: true,
                hideInTable: true,
                dataIndex: 'TypeId',
                initialValue: DEVICE.TYPE.TERMINAL,
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '操作',
                key: 'option',
                valueType: 'option',
                render: (_, record) => {
                    let options = [];
                    switch (record.TypeId) {
                        case DEVICE.TYPE.PHONE: {
                            let { room, mode, linkedScreen, task } = this.state;
                            options.push(<Button key={`${record.TypeId}-diaodu`} type="link" className="nopadding"
                                disabled={!(DEVICE.STATE.isIdle(record) && ((mode === 'controlled' && linkedScreen) || mode !== 'controlled'))}
                                onClick={() => {
                                    this.setState({
                                        staffListVisible: false,
                                    }, () => {
                                        if (!room) {
                                            this.deviceConference && this.deviceConference.createRoom([record], task?.MissionInfo, linkedScreen);
                                        } else {
                                            this.deviceConference && this.deviceConference.inviteMember([record]);
                                        }
                                    })
                                }
                                }>调度</Button>
                            )
                            let component = <ModalForm
                                key={record.Id}
                                formRef={ref => record.editForm = ref}
                                title="推送消息"
                                labelwidth="auto"
                                trigger={<Button disabled={record.State !== 'online'} key={`${record.TypeId}-message`} type="link" className="nopadding">推送消息</Button>}
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        record.editForm.setFieldsValue({
                                            Title: "实时消息",
                                            Content: "",
                                        })
                                    }
                                }}
                                onFinish={async (values) => {
                                    let rsp = await apiTerminalMessage({
                                        DeviceId: record.Id,
                                        Title: values.Title,
                                        Content: values.Content,
                                    })
                                    if (rsp.Status === 0) {
                                        message.success('推送消息成功');
                                        return true;
                                    }
                                    return false;
                                }}
                            >
                                <ProFormText
                                    width="lg"
                                    name="Title"
                                    label="标题"
                                    placeholder="请输入标题"
                                    rules={[{ required: true, message: '请输入标题' }]}
                                />
                                <ProFormTextArea
                                    width="lg"
                                    name="Content"
                                    label="内容"
                                    placeholder="请输入内容"
                                    rules={[{ required: true, message: '请输入内容' }]}
                                />
                            </ModalForm>
                            options.push(component)
                            break;
                        }
                        case DEVICE.TYPE.TELEPHONE:
                            options.push(<Button key={`${record.TypeId}-call`} type="link" className="nopadding" onClick={() => {
                                message.info("暂不支持该操作")
                            }}>呼叫</Button>)
                            break;
                        default:
                            break;
                    }
                    return options;
                },
            },
        ];

        this.taskId = parseInt(getUrlParam("id", window.location?.search)) || 0;
        this.staffTableExpandedAction = {}

        this.state = {
            theme: getUrlParam("theme", window.location?.search) || localStorage.getItem("theme") || 'light',
            mode: getUrlParam("mode", window.location?.search) || 'controlled',
            cameraOnlineIcon: new window.BMapGL.Icon(getIconSrc('camera-online'), new window.BMapGL.Size(25, 25)),
            cameraOfflineIcon: new window.BMapGL.Icon(getIconSrc('camera-offline'), new window.BMapGL.Size(25, 25)),
            cameraBusyIcon: new window.BMapGL.Icon(getIconSrc('camera-busy'), new window.BMapGL.Size(25, 25)),
            deviceOnlineIcon: new window.BMapGL.Icon(getIconSrc('device-online'), new window.BMapGL.Size(25, 25)),
            deviceOfflineIcon: new window.BMapGL.Icon(getIconSrc('device-offline'), new window.BMapGL.Size(25, 25)),
            deviceBusyIcon: new window.BMapGL.Icon(getIconSrc('device-busy'), new window.BMapGL.Size(25, 25)),
            staffOnlineIcon: new window.BMapGL.Icon(getIconSrc('staff-online'), new window.BMapGL.Size(25, 25)),
            staffOfflineIcon: new window.BMapGL.Icon(getIconSrc('staff-offline'), new window.BMapGL.Size(25, 25)),
            staffBusyIcon: new window.BMapGL.Icon(getIconSrc('staff-busy'), new window.BMapGL.Size(25, 25)),
            alarmOnlineIcon: new window.BMapGL.Icon(getIconSrc('alarm-online'), new window.BMapGL.Size(25, 25)),
            alarmOfflineIcon: new window.BMapGL.Icon(getIconSrc('alarm-offline'), new window.BMapGL.Size(25, 25)),
            alarmBusyIcon: new window.BMapGL.Icon(getIconSrc('alarm-busy'), new window.BMapGL.Size(25, 25)),
            labelOffset: new window.BMapGL.Size(-12, -35),
            allDevices: [],
            lng: 0,
            lat: 0,
            tilt: 50,
            heading: 0,
            zoomLevel: 19,
            tips: "",
            width: 1,
            height: 1,

            sdkInit: false,
            staffColumns: staffColumns,
            deviceColumns: deviceColumns,
            terminalColumns: terminalColumns,
        }
        this.drivingRoutes = []
    }

    initStaff() {
        setTimeout(async () => {
            let { staffColumns } = this.state;
            let nowColumns = [];
            // 获取角色信息
            let rsp2 = await apiRoleList({ PageNum: 1, PageSize: 1000, }, { ignoreError: true })
            let roleData = rsp2?.RecordList || []
            let valueEnum = {}
            if (roleData !== undefined) {
                roleData.forEach(r => {
                    valueEnum[r.Id] = {
                        text: r.Name
                    };
                })
                nowColumns = [
                    ...staffColumns.slice(0, 4),
                    {
                        title: '角色',
                        type: 'intEnumMultiple',
                        width: '10%',
                        dataIndex: 'RoleId',
                        fieldProps: {
                            mode: 'multiple',
                        },
                        valueType: 'select',
                        valueEnum: valueEnum,
                    },
                    ...staffColumns.slice(4),
                ]
            }

            // 获取部门信息
            let rsp3 = await apiOrganizationGet({}, { ignoreError: true })
            let orgData = [];
            try {
                orgData = JSON.parse(rsp3?.Data || "[{\"key\": \"_\", \"children\": [], \"title\": \"总部\"}]");
            } catch (error) {
                console.error("parse json error", error);
            }
            let dataList = [];
            const generateList = data => {
                for (let node of data) {
                    dataList.push(node);
                    if (node.children) {
                        generateList(node.children);
                    }
                }
            };
            generateList(orgData);
            let orgValueEnum = {}
            dataList.forEach(r => {
                orgValueEnum[r.key] = {
                    text: r.title
                };
            })
            nowColumns = [
                ...nowColumns.slice(0, 5),
                {
                    title: '部门',
                    type: 'strEnumMultiple',
                    width: '10%',
                    dataIndex: 'Department',
                    fieldProps: {
                        mode: 'multiple',
                    },
                    valueType: 'select',
                    valueEnum: orgValueEnum,
                    render: (text, record) => {
                        return <div>{dataList.find(d => d.key === record.Department)?.title || "-"}</div>
                    }
                },
                ...nowColumns.slice(5),
            ]
            this.setState({
                roles: roleData,
                departments: dataList,
                staffColumns: nowColumns,
            })
        }, 0);
    }

    init() {
        if (this.taskId) {
            this.refreshTask((task) => {
                this.initMap(task);
                this.initMqtt(task);
            })
        } else {
            this.refreshTasks((tasks) => {
                this.initMap(null, tasks);
            })
        }
        this.initStaff();
    }

    uninit() {
        this.uninitMap()
    }

    switchTheme(theme) {
        this.setState({
            theme: theme
        }, () => {
            let { allDevices, allStaffs } = this.state;
            localStorage.setItem("theme", theme)
            this.map && this.map.setMapStyleV2({ styleJson: mapStyle[theme] });
            this.circle && this.circle.setStrokeColor(mapColor[theme])
            this.circle && this.circle.setFillColor(mapBackgroud[theme])
            this.circles && this.circles.forEach(circle => {
                circle.setStrokeColor(mapColor[theme])
                circle.setFillColor(mapBackgroud[theme])
            })
            this.map.clearOverlays();
            this.initMapDeviceAndStaff(allDevices, allStaffs)
            this.map.resize();
        })
    }

    showMenuStyle(e, domId) {
        let { height, width } = this.state;
        let topOffset = 0;
        let rightOffset = 16;
        let dom = document.getElementById(domId);
        if (dom) {
            if (dom.clientHeight + e.clientY > height) {
                topOffset -= dom.clientHeight
            }
            if (dom.clientWidth + e.clientX > width) {
                rightOffset -= rightOffset * 2 + dom.clientWidth;
            }
        }

        return {
            opacity: 1,
            zIndex: 9999,
            top: `${e.clientY + topOffset}px`,
            left: `${e.clientX + rightOffset}px`,
        }
    }

    hideMenuStyle() {
        return {
            opacity: 0,
            zIndex: 0,
        }
    }

    areaMembers(start, end, devices, staffs) {
        // 计算出矩形框中满足要求的设备
        let members = devices || [];
        let arrays = [];
        let startLng = start?.lng || 0
        let startLat = start?.lat || 0
        let endLng = end?.lng || 0
        let endLat = end?.lat || 0
        if (startLng > endLng) {
            [startLng, endLng] = [endLng, startLng];
        }
        if (startLat < endLat) {
            [startLat, endLat] = [endLat, startLat];
        }

        // eslint-disable-next-line no-unused-expressions
        staffs?.forEach(s => {
            members = members.concat(s?.DeviceList || [])
        })
        members.forEach(m => {
            if (
                m.Lng < startLng || m.Lng > endLng
                || m.Lat < endLat || m.Lat > startLat
                || m.State !== 'online'
                || m.SipState !== 'online'
                || m.CallState !== 'idle'
                ) {
                return;
            }
            arrays.push(m);
        })

        return arrays;
    }

    screenOnChange(e) {
        this.setState({
            isFullScreen: isFullscreen(),
        }, () => {
            message.destroy();
            if (this.map) {
                this.map.checkResize();
            }
        });
    }

    initMqtt(task) {
        let { reqUserInfo } = this.props;
        const clientId = `${randomString()}_${moment().unix()}`;

        let mqtt = require('mqtt')
        let client = mqtt.connect('', {
            port: reqUserInfo.mqttInfo.ServerPort,
            hostname: reqUserInfo.mqttInfo.ServerHost,
            username: reqUserInfo.mqttInfo.Accout,
            password: reqUserInfo.mqttInfo.Password,
            clientId: clientId,
            protocol: 'wss',
        })
        // 订阅设备上下线消息
        let tagTopic1 = `cs/mntn/notify/device`;
        let tagTopic2 = `ccs/task/${task.MissionInfo?.Id}`;
        client.on('connect', () => {
            client.subscribe(`${tagTopic1}/#`, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe ${tagTopic1} success`);
                }
            })

            if (this.state.mode === 'screen') {
                client.subscribe(`${tagTopic2}/#`, (err) => {
                    if (!err) {
                        console.info(`mqtt subscribe ${tagTopic2} success`);
                    }
                })
            }
        })

        client.on('message', (dstTopic, message) => {
            let content = {}
            let msg = message.toString();
            try {
                content = JSON.parse(msg);
                console.info(`mqtt message:`, dstTopic, content);
            } catch (error) {
                console.error(`mqtt message:`, dstTopic, msg);
            }

            if (dstTopic.startsWith(tagTopic1)) {
                switch (content?.State) {
                    default: {
                        let { allDevices, allStaffs } = this.state;
                        if (allDevices?.find(r => r.SipNum === content?.SipNum)) {
                            this.refreshTask();
                            this.refreshDevice();
                        }
                        if (allStaffs?.find(s => s.DeviceList?.find(d => d.SipNum === content?.SipNum))) {
                            this.refreshTask();
                            this.refreshStaff();
                        }

                        this.refreshScreenList();
                        break;
                    }
                }
            } else if (dstTopic.startsWith(tagTopic2)) {
                let c = content?.content;
                switch (content?.action) {
                    case "map": {
                        if (c && this.map) {
                            let curCenter = this.map.getCenter();
                            // console.group()
                            // console.log("lng", curCenter.lng);
                            // console.log("lat", curCenter.lat);
                            // console.log("tilt", this.map.getTilt());
                            // console.log("zoomLevel", this.map.getZoom());
                            // console.log("heading", this.map.getHeading());
                            // console.groupEnd()
                            if (curCenter.lng !== c.lng || curCenter.lat !== c.lat) {
                                this.map.setCenter(new window.BMapGL.Point(c.lng, c.lat), {
                                    noAnimation: true,
                                    callback: () => {
                                        this.map.getTilt() !== c.tilt && this.map.setTilt(c.tilt);
                                        this.map.getZoom() !== c.zoomLevel && this.map.setZoom(c.zoomLevel);
                                        this.map.getHeading() !== c.heading && this.map.setHeading(c.heading);
                                    }
                                })
                            } else {
                                this.map.getTilt() !== c.tilt && this.map.setTilt(c.tilt);
                                this.map.getZoom() !== c.zoomLevel && this.map.setZoom(c.zoomLevel);
                                this.map.getHeading() !== c.heading && this.map.setHeading(c.heading);
                            }
                        }
                        break;
                    }
                    case "theme": {
                        let { theme } = this.state;
                        if (theme !== c.theme) {
                            this.switchTheme(c.theme)
                        }
                        break;
                    }
                    case "drawer": {
                        this.setState({
                            staffListVisible: c.staff,
                            deviceListVisible: c.device,
                            targetActiveKey: c.target,
                        })
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            
        })

        this.setState({
            mqttClient: client,
        })
    }

    routeOnClick(staff, to) {
        let transit = new window.BMapGL.DrivingRoute(this.map, {
            renderOptions: {
                map: this.map
            },
            onSearchComplete: (results) => {
                let plan = results && results.getPlan(0);
                if (transit.getStatus() === window.BMAP_STATUS_SUCCESS && plan) {
                    let duration = plan.getDuration(true);
                    let distance = plan.getDistance(true);
                    console.group("route plan");
                    console.log("plan", plan);
                    console.log("duration", duration);
                    console.log("distance", distance);
                    console.groupEnd();

                    var opts = {
                        position: new window.BMapGL.Point(staff?.Lng, staff?.Lat), // 指定文本标注所在的地理位置
                        offset: new window.BMapGL.Size(0, 0), // 设置文本偏移量
                        enableMassClear: false,
                    };
                    // 创建文本标注对象
                    var label = new window.BMapGL.Label(`${staff?.NickName}距目标${distance}，大约${duration}车程`, opts);
                    // 自定义文本标注样式
                    label.setStyle({
                        color: blue[5],
                        borderRadius: '2px',
                        borderColor: grey[1],
                        padding: '0 12px',
                        fontSize: '16px',
                        // height: '30px',
                        // lineHeight: '30px',
                        // fontFamily: '微软雅黑'
                    });
                    this.map.addOverlay(label);
                    this.drivingRoutes.push(label)
                }
            },
            onMarkersSet: (pois) => {
                // eslint-disable-next-line no-unused-expressions
                pois?.forEach(poi => {
                    let marker = poi?.marker;
                    if (marker) {
                        marker.disableMassClear()
                        this.drivingRoutes.push(marker)
                    }
                })
            },
            onPolylinesSet: (routes) => {
                // eslint-disable-next-line no-unused-expressions
                routes?.forEach(info => {
                    let polyline = info.getPolyline();
                    if (polyline) {
                        polyline.disableMassClear()
                        this.drivingRoutes.push(polyline)
                    }
                })
            }
        });
        let from = new window.BMapGL.Point(staff?.Lng, staff?.Lat);
        transit.disableAutoViewport();
        transit.search(from, to);
    }

    refreshTask(onFinish) {
        if (this.taskId) {
            clearTimeout(this.refreshTaskTimer);
            this.refreshTaskTimer = setTimeout(async () => {
                let { task } = this.state;
                let rsp = await apiTaskDetail({ Id: this.taskId });
                this.setState({
                    task: rsp,
                }, () => {
                    if (task && (task?.MissionInfo?.CentreLng !== rsp?.MissionInfo?.CentreLng || task?.MissionInfo?.Radius !== rsp?.MissionInfo?.Radius)) {
                        this.initCircle(rsp)
                    }
                    this.deviceTableAction && this.deviceTableAction.reload();
                    this.staffTableAction && this.staffTableAction.reload();
                    onFinish && onFinish(rsp)
                })
            }, 200);
        }
    }

    refreshTasks(onFinish) {
        let { tasks } = this.state;
        setTimeout(async () => {
            let pag1 = {
                PageNum: 1,
                PageSize: 1000,
                OrFirst: true,
                Filters: [[{Key: "State", Type: "str", Op: "=", Value: "inprogress"}, {Key: "State", Type: "str", Op: "=", Value: "tostart"}]],
                OrderKey: 'ScheduledTime',
                Desc: 1,
            }
            let rsp1 = await apiTaskList(pag1);
            
            let rsp2 = await apiTaskStateStatistic()
            let taskStateStatistic = rsp2?.RecordList || [];
            let stateTotal = 0;
            taskStateStatistic.forEach(r => stateTotal += r.Count);
           
            let rsp3 = await apiStaffRoleStatistic()
            let staffRoleStatistic = rsp3?.RecordList || [];
            let staffTotal = 0;
            staffRoleStatistic.forEach(r => staffTotal += r.Count);

            let rsp4 = await apiDeviceTypeStatistic();
            let deviceTypeStatistic = rsp4?.RecordList || [];
            let deviceTotal = 0;
            deviceTypeStatistic.forEach(r => deviceTotal += r.Count);

            this.setState({
                tasks: rsp1?.RecordList,
                taskStateStatistic: taskStateStatistic,
                taskStateStatisticTotal: stateTotal,
                staffRoleStatistic: staffRoleStatistic,
                staffRoleStatisticTotal: staffTotal,
                deviceTypeStatistic: deviceTypeStatistic,
                deviceTypeStatisticTotal: deviceTotal,
            }, () => {
                if (tasks) {
                    this.initCircles(rsp1?.RecordList)
                }
            })
            onFinish && onFinish(rsp1?.RecordList)
        }, 0);
    }

    refreshDevice(timeout) {
        clearTimeout(this.refreshDeviceTimer)
        this.refreshDeviceTimer = setTimeout(async () => {
            let pag = {
                PageNum: 1,
                PageSize: 1000,
                OrFirst: true,
                OrderKey: 'State',
                Desc: 1,
                Filters: [[{Key: "TypeId", Type: "int", Op: "!=", Value: DEVICE.TYPE.PHONE}]],
            }
            let rsp = await apiTerminalList(pag);
            this.setState({
                allDevices: rsp.RecordList,
                loading: false,
            }, () => {
                this.map.clearOverlays();
                this.initMapDeviceAndStaff(rsp.RecordList, this.state.allStaffs)
            })
        }, timeout || 500);
    }

    refreshStaff(timeout) {
        clearTimeout(this.refreshStaffTimer)
        this.refreshStaffTimer = setTimeout(async () => {
            let pag = {
                PageNum: 1,
                PageSize: 1000,
                OrFirst: true,
            }
            let rsp = await apiStaffList(pag);
            this.setState({
                allStaffs: rsp.RecordList,
                loading: false,
            }, () => {
                this.map.clearOverlays();
                this.initMapDeviceAndStaff(this.state.allDevices, rsp.RecordList)
            })
        }, timeout || 500);
    }

    refreshScreenList(timeout) {
        clearTimeout(this.refreshScreenListTimer)
        this.refreshScreenListTimer = setTimeout(async () => {
            let { linkedScreen } = this.state;
            let pag = {
                PageNum: 1,
                PageSize: 1000,
                OrFirst: true,
            }
            let rsp = await apiScreenList(pag);
            let tagScreen = undefined;
            for (let r of (rsp.RecordList || [])) {
                if (r.AppState === 'online' && r.SipState === 'online') {
                    tagScreen = r;
                    if (linkedScreen?.SipNum === r.SipNum) {
                        break;
                    }
                }
            }
            this.setState({
                screenList: rsp.RecordList,
                linkedScreen: tagScreen,
            })
        }, timeout || 500);
    }

    initMapDeviceAndStaff(devices, staffs) {
        let { labelOffset, members, theme, roles, departments } = this.state;

        let addOverlay = (records, type) => {
            // eslint-disable-next-line no-unused-expressions
            records?.forEach(r => {
                let member = r;
                
                if (type === 'staff') {
                    member = r.DeviceList?.[0]
                }
                if (!member?.Lng || !member?.Lat) {
                    return;
                }
                let point = new window.BMapGL.Point(member.Lng, member.Lat);
                let state = 'Online';
                let deviceType = 'staff';
                let color = theme === 'dark' ? '#fff' : '#000';
                let labelExtra = ''
                let titleText = '';
                let labelText = '';
                if (member?.State === 'online') {
                    state = 'Online';
                    if (member?.CallState === 'idle') {
                        state = 'Online';
                    } else {
                        state = 'Busy';
                        if (members?.find(m => m.userId === member?.SipNum && m.status === window.vmeeting.STATUS.MEMBER.JOINED)) {
                            labelExtra = '（通信中）';
                            color = gold[5];
                        } else {
                            labelExtra = '（忙碌）';
                            color = volcano[5];
                        }
                    }
                } else {
                    state = 'Offline';
                    color = red[5];
                    labelExtra = '（离线）';
                }
                switch (type) {
                    case 'device':
                        titleText = `${DEVICE.TYPE.description(member?.TypeId)} ${member?.NickName || member?.Name} ${member?.SerialNumber}`
                        labelText = `${member?.NickName || member?.Name}${labelExtra}`
                        switch (member?.TypeId) {
                            case DEVICE.TYPE.CAMERA:
                                deviceType = 'camera';
                                break;
                            case DEVICE.TYPE.TERMINAL:
                                deviceType = 'device';
                                break;
                            case DEVICE.TYPE.ALARM:
                                deviceType = 'alarm';
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'staff':
                        titleText = `${r?.NickName || r?.Name} ${member?.SerialNumber} ${roles?.find(i => i?.Id === r.RoleId)?.Name} ${departments.find(d => d.key === r.Department)?.title || "-"}`
                        labelText = `${r?.NickName || r?.Name}${labelExtra}`
                        break;
                
                    default:
                        break;
                }

                // console.group("add overlay to map");
                // console.log("type", type);
                // console.log("member", r);
                // console.log("device", member);
                // console.log("deviceType", deviceType);
                // console.log("state", state);
                // console.groupEnd();

                let marker = new window.BMapGL.Marker(point, {
                    icon: this.state[`${deviceType}${state}Icon`],
                    title: titleText,
                });
                let label = new window.BMapGL.Label(labelText, { offset: labelOffset });
                let style = { color: color, fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent'};
                label.setStyle(style)
                marker.setLabel(label)
                marker.dataset = Object.assign({ type: type, staff: r }, member);
                marker.addEventListener('click', (e) => {
                    let { members, view } = this.state;
                    this.meunMember = e?.srcElement?.dataset;
                    this.menuLatlng = e.latlng;
                    this.setState({
                        deviceIsIdle: DEVICE.STATE.isIdle(this.meunMember),
                        deviceInConference: !!members?.find(m => m.userId === this.meunMember.SipNum && m.status === window.vmeeting.STATUS.MEMBER.JOINED),
                        deviceStreamId: view?.ssrc?.[view?.media?.findIndex(userId => userId === this.meunMember.SipNum)],
                        deviceMenuStyle: this.showMenuStyle(e, "device-left-menu"),
                        mapMenuStyle: this.hideMenuStyle(),
                    })
                });
                marker.addEventListener('rightclick', (e) => {
                    this.meunMember = e?.srcElement?.dataset;
                    this.menuLatlng = e.latlng;
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle(),
                        deviceMenuStyle: this.hideMenuStyle(),
                    })
                });
                
                this.map.addOverlay(marker);
            })
        }

        addOverlay(devices, 'device')
        addOverlay(staffs, 'staff')
    }

    initAddress(local, callback) {
        new window.BMapGL.Geocoder().getLocation(local, (reslut) => {
            let addr = "";
            let poisTitle = "";
            let poisAddress = "";
            if (reslut?.surroundingPois?.length) {
                let pois = reslut?.surroundingPois?.[0]
                poisTitle = pois?.title;
                poisAddress = pois?.address;
                if (pois?.title) {
                    addr = `${pois?.title} | ${pois?.address}`
                } else {
                    addr = pois?.address;
                }
            } else {
                poisTitle = addr = reslut?.address + reslut?.business;
            }
            callback && callback(addr, poisTitle, poisAddress)
        }, {
            poiRadius: 50,
        })
    }

    initCircles(tasks) {
        let { theme } = this.state;
        let { reqUserInfo } = this.props;
        let taskOverlays = this.taskOverlays || [];
        let circles = this.circles || [];
        taskOverlays.forEach(overlay => {
            this.map.removeOverlay(overlay)
        })
        circles.forEach(overlay => {
            this.map.removeOverlay(overlay)
        })
        taskOverlays = [];
        circles = [];
        setTimeout(() => {
            
            // eslint-disable-next-line no-unused-expressions
            tasks?.forEach(task => {
                let center = new window.BMapGL.Point(task.CentreLng, task.CentreLat);
                let marker = new window.BMapGL.Marker(center, {
                    enableMassClear: false,
                })
                let ctxs = []
                if (task?.Target) {
                    ctxs.push(`<h3 style='margin:0 0 4px 0;'>${task?.Target}</h3>`)
                }
                if (task?.TargetPicUrl) {
                    ctxs.push(`<img style='margin:0' id='targetPic${task.Id}' src='${task?.TargetPicUrl}' width='139' height='100%'/>`)
                }
                if (task?.TargetDesc) {
                    ctxs.push(`<p style='margin:8px 0;line-height:1.5;font-size:13px;'>${task?.TargetDesc}</p>`)
                }
                if (ctxs.length) {
                    let content = "<div>" + ctxs.join("") + "</div>";
                    let infoWindow = new window.BMapGL.InfoWindow(content, {
                        offset: new window.BMapGL.Size(0, 25)
                    });
                    marker.addEventListener('mouseover', function (e) {
                        clearTimeout(this.infoWindowTimer)
                        this.openInfoWindow(infoWindow);
                        let dom = document.getElementById(`targetPic${task.Id}`)
                        if (dom) {
                            dom.onload = function () {
                                infoWindow.redraw();
                            };
                        }
                    });

                    // marker.addEventListener('mouseout', function (e) {
                    //     this.infoWindowTimer = setTimeout(() => {
                    //         this.closeInfoWindow(infoWindow);
                    //     }, 500);
                    // });
                }
                
                this.map.addOverlay(marker)
                taskOverlays.push(marker)

                let circle = new window.BMapGL.Circle(center, task.Radius || 0, {
                    strokeColor: mapColor[theme],
                    strokeWeight: 1,
                    strokeOpacity: 0.3,
                    strokeStyle: 'dashed',
                    fillOpacity: 0.3,
                    fillColor: mapBackgroud[theme],
                    enableMassClear: false,
                });
                this.map.addOverlay(circle);
                circle.addEventListener('mouseover', (e) => {
                    circle.setFillOpacity(0.5)
                })
                circle.addEventListener('mouseout', (e) => {
                    circle.setFillOpacity(0.3)
                })
                circle.addEventListener('click', (e) => {
                    let mode = 'controlled';
                    switch (reqUserInfo?.user?.role?.type){
                        case 'daping.task':
                            mode = 'screen'
                            break;
                        default:
                            break;
                    }
                    window.open(`/ccs/task/situation?env=${version.environment}&id=${task.Id}&mode=${mode}`, "_blank");
                })
                
                circles.push(circle)

                let taskName = new window.BMapGL.Label(task.Name, {
                    position: center,
                    enableMassClear: false,
                })
                taskName.setStyle({ color: '#fff', fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent', textAlign: 'center', transform: 'translate(-50%, 0)' });
                this.map.addOverlay(taskName);
                taskOverlays.push(taskName)
            })
            this.taskOverlays = taskOverlays;
            this.circles = circles;
        }, 1000);
            
    }

    initCircle(task, timeout) {
        let { theme } = this.state;
        let pt = new window.BMapGL.Point(task?.MissionInfo?.CentreLng, task?.MissionInfo?.CentreLat);
        setTimeout(() => {
            // 中心点
            this.map.removeOverlay(this.center)
            this.center = new window.BMapGL.Marker(pt, {
                enableMassClear: false,
            });

            // let ctxs = []
            // if (task?.MissionInfo?.Target) {
            //     ctxs.push(`<h3 style='margin:0 0 4px 0;'>${task?.MissionInfo?.Target}</h3>`)
            // }
            // if (task?.MissionInfo?.TargetPicUrl) {
            //     ctxs.push(`<img style='margin:0' id='targetPic${task?.MissionInfo?.Id}' src='${task?.MissionInfo?.TargetPicUrl}' width='139' height='100%'/>`)
            // }
            // if (task?.MissionInfo?.TargetDesc) {
            //     ctxs.push(`<p style='margin:8px 0;line-height:1.5;font-size:13px;'>${task?.MissionInfo?.TargetDesc}</p>`)
            // }
            // if (ctxs.length) {
            //     let content = "<div>" + ctxs.join("") + "</div>";
            //     let infoWindow = new window.BMapGL.InfoWindow(content, {
            //         offset: new window.BMapGL.Size(0, 25)
            //     });
            //     this.center.addEventListener('mouseover', function (e) {
            //         clearTimeout(this.infoWindowTimer)
            //         this.openInfoWindow(infoWindow);
            //         let dom = document.getElementById(`targetPic${task?.MissionInfo?.Id}`)
            //         if (dom) {
            //             dom.onload = function () {
            //                 infoWindow.redraw();
            //             };
            //         }
            //     });
            //     this.center.addEventListener('mouseout', function (e) {
            //         this.infoWindowTimer = setTimeout(() => {
            //             this.closeInfoWindow(infoWindow);
            //         }, 500);
            //     });
            // }
            
            this.map.addOverlay(this.center)

            // 任务圈
            this.map.removeOverlay(this.circle)
            this.circle = new window.BMapGL.Circle(pt, task?.MissionInfo?.Radius || 0, {
                strokeColor: mapColor[theme],
                strokeWeight: 1,
                strokeOpacity: 0.3,
                strokeStyle: 'dashed',
                fillOpacity: 0.3,
                fillColor: mapBackgroud[theme],
                enableMassClear: false,
            });
            this.map.addOverlay(this.circle);
        }, timeout || 1000);
    }

    uninitMap() {
        this.deviceConference && this.deviceConference.destroyRoom();
        this.map && this.map.destroy()
    }

    initMap(task, tasks) {
        let { theme, mode } = this.state;
        let map = new window.BMapGL.Map("map");
        this.map = map;
        let lng = task?.MissionInfo?.CentreLng || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLng")) || "113.97005752568613");
        let lat = task?.MissionInfo?.CentreLat || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLat")) || "22.589486651330265");
        let zoomLevel = (task?.MissionInfo?.ZoomLevel || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapZoomLevel")) || "14.00")).toFixed(2);
        let tilt = parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapTilt")) || 0).toFixed(2);
        let center = new window.BMapGL.Point(lng, lat)
        map.centerAndZoom(center);
        map.setMapStyleV2({ styleJson: mapStyle[theme]});
        let navigation = new window.BMapGL.NavigationControl3D({
            anchor: window.BMAP_ANCHOR_TOP_RIGHT,
            offset: new window.BMapGL.Size(18, 18)
        })
        map.addControl(navigation);
        if (mode !== 'screen') {
            let cityControl = new window.BMapGL.CityListControl({
                anchor: window.BMapGL.BMAP_ANCHOR_BOTTOM_LEFT,
            });
            map.addControl(cityControl);

            let ac = new window.BMapGL.Autocomplete({
                input: "suggestId",
                location: map,
                onSearchComplete: (results) => {
                    if (this.state.task) {
                        let dom = document.getElementById("tangram-suggestion--TANGRAM__1-main");
                        if (dom) {
                            dom.style.top = 'auto';
                            dom.style.bottom = '64px';
                        }
                    }
                }
            });
    
            ac.addEventListener("onhighlight", (e) => {
                let str = "";
                let _value = e.fromitem.value;
                let value = "";
                if (e.fromitem.index > -1) {
                    value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                }    
                str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
                
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                }    
                str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                document.getElementById("searchResultPanel").innerHTML = str;
            });
    
            ac.addEventListener("onconfirm", (e) => {
                let _value = e.item.value;
                let myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                document.getElementById("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                
                let myFun = () => {
                    let pp = local.getResults().getPoi(0).point;
                    map.centerAndZoom(pp, 18);
                    this.setState({
                        address: myValue,
                    })
                    this.searchMarker && map.removeOverlay(this.searchMarker)
                    this.searchMarker = new window.BMapGL.Marker(pp);
                    this.searchMarker.disableMassClear();
                    map.addOverlay(this.searchMarker);
                }
                let local = new window.BMapGL.LocalSearch(map, {
                    onSearchComplete: myFun
                });
                local.search(myValue);
            });
        }
        map.enableScrollWheelZoom();
        map.enableKeyboard();
        map.disableDoubleClickZoom();
        map.setDisplayOptions({
            poiIcon: false,
            poiText: true,
        })
        map.addEventListener('tilesloaded', (e) => {
            if (!this.tilesloadFirst) {
                this.tilesloadFirst = true;
                this.setState({
                    controlVisible: true,
                    tilt: tilt,
                    zoomLevel: zoomLevel,
                    lng: lng,
                    lat: lat,
                }, () => {
                    this.map.setTilt(tilt);
                    this.map.setZoom(zoomLevel, {
                        callback: (e) => {
                            if (task?.MissionInfo?.CentreLng && task?.MissionInfo?.CentreLng && task?.MissionInfo?.Radius) {
                                this.initCircle(task)
                            }
                            if (tasks) {
                                this.initCircles(tasks)
                            }
                            this.initAddress(center, (address, poisTitle, poisAddress) => {
                                this.setState({
                                    address: address,
                                    targetPoisTitle: poisTitle,
                                    targetPoisAddress: poisAddress,
                                })
                            })
                            this.refreshTask();
                            this.refreshDevice();
                            this.refreshStaff();
                            this.refreshScreenList();
                            this.setState({
                                zoomLevel: zoomLevel,
                            })
                        }
                    });
                })
            }

            // let button = document.getElementsByClassName('anchorBR')?.[0]?.children?.[1];
            // if (button) {
            //     if (button.style) {
            //         button.style.display = 'none';
            //     }
            // }
        })
        map.addEventListener('click', (e) => {
            if (e.target.className !== "BMap_Marker BMap_noprint") {
                let { mapMenuStyle, deviceMenuStyle } = this.state;
                if (mapMenuStyle?.opacity || deviceMenuStyle?.opacity) {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle(),
                        deviceMenuStyle: this.hideMenuStyle(),
                        lng: e.latlng.lng,
                        lat: e.latlng.lat,
                    })
                    return;
                }
            }

            this.setState({
                lng: e.latlng.lng,
                lat: e.latlng.lat
            }, () => {
                this.initAddress(new window.BMapGL.Point(e.latlng.lng, e.latlng.lat), (address) => {
                    this.setState({
                        address: address,
                    })
                })
            })
        });

        map.addEventListener('rightclick', (e) => {
            this.menuLatlng = e.latlng;
            if (e.target.className !== "BMap_Marker BMap_noprint") {
                this.setState({
                    mapMenuStyle: this.showMenuStyle(e.domEvent, "map-menu"),
                    deviceMenuStyle: this.hideMenuStyle(),
                    lng: e.latlng.lng,
                    lat: e.latlng.lat
                })
            } else {
                this.setState({
                    lng: e.latlng.lng,
                    lat: e.latlng.lat
                })
            }
        });
        map.addEventListener('zoomstart', (e) => {
            let { mapMenuStyle, deviceMenuStyle } = this.state;
            if (mapMenuStyle?.opacity || deviceMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('movestart', (e) => {
            let { mapMenuStyle, deviceMenuStyle } = this.state;
            if (mapMenuStyle?.opacity || deviceMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('zoomend', (e) => {
            let zoomLevel = parseFloat(e.target?.zoomLevel?.toFixed(2));
            this.setState({
                zoomLevel: zoomLevel,
            });
        });
        map.addEventListener('dragend', (e) => {
            let centerPoint = this.map.getCenter()
            this.setState({
                tilt: this.map.getTilt()?.toFixed(2),
                heading: ((this.map.getHeading() % 360 + 360) % 360).toFixed(2),
                lat: centerPoint?.lat || 0,
                lng: centerPoint?.lng || 0,
            });
        });

        map.addEventListener('mousemove', (e) => {
            this.curLatlng = e.latlng
        });
    }

    mkSelectDevice() {

        let { selectDeviceVisible, selectDeviceMode } = this.state;

        return <Modal
            title={`${selectDeviceMode === 'put' ? "放置" : "定位"}设备`}
            visible={selectDeviceVisible}
            width="80%"
            onCancel={(e) => {
                this.setState({
                    selectDeviceVisible: false
                }, () => {
                    message.warning(`你已取消${selectDeviceMode === 'put' ? "放置": "定位"}设备`)
                })
            }}
            footer={null}
            destroyOnClose={true}
            >
            <Member
                className=""
                valid={{TypeId: [DEVICE.TYPE.CAMERA, DEVICE.TYPE.TERMINAL, DEVICE.TYPE.ALARM]}}
                mode={selectDeviceMode}
                apiAddressBook={apiTerminalList.bind(this)}
                onSelect={async (m) => {
                    switch (selectDeviceMode) {
                        case "put": {
                            let data = {
                                DeviceId: m.Id,
                                Lng: this.menuLatlng.lng,
                                Lat: this.menuLatlng.lat,
                            }
                            let rsp = await apiTerminalSetLocation(data);
                            if (rsp?.Status === 0) {
                                this.setState({
                                    selectDeviceVisible: false
                                }, () => {
                                    message.success(`放置设备${m.Name}成功`)
                                    this.refreshDevice();
                                })
                            }
                            break;
                        }

                        case "aim": {
                            this.setState({
                                selectDeviceVisible: false
                            }, () => {
                                message.success(`定位设备${m.Name}成功`)
                                if (m?.Lng && m?.Lat) {
                                    this.map.setCenter(new window.BMapGL.Point(m.Lng, m.Lat), {
                                        noAnimation: false,
                                    })
                                }
                            })
                            
                            break;
                        }
                        
                        default:
                            break;
                    }
                    
                }}
            />
        </Modal>
    }

    mkMenuItem(icon, description) {
        return <div className="left">
            <IconFont className="icon-menu" type={icon} />
            <span className="description">{description}</span>
        </div>
    }

    mkMenuDevice() {
        let { loading, deviceIsIdle, deviceInConference, deviceStreamId, sdkInit, room, members, deviceMenuStyle, task, profile, theme, linkedScreen, roles, departments } = this.state;
        if (!task) {
            return undefined;
        }
        let infoComponent = undefined
        let intoTitle = ""
        switch (this.meunMember?.type) {
            case 'staff':
                intoTitle = "人员信息";
                infoComponent = <>
                    <Menu.Item key={`${this.meunMember?.Id}-1`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>姓名</div><div>{this.meunMember?.staff?.NickName}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={`${this.meunMember?.Id}-2`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>编号</div><div>{this.meunMember?.staff?.SerialNumber}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={`${this.meunMember?.Id}-3`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>角色</div><div>{roles?.find(i => i?.Id === this.meunMember.staff?.RoleId)?.Name}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={`${this.meunMember?.Id}-4`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>部门</div><div>{departments.find(d => d.key === this.meunMember.staff?.Department)?.title || "-"}</div>
                        </div>
                    </Menu.Item>
                </>
                break;
            case "device":
                intoTitle = "设备信息";
                infoComponent = <>
                    <Menu.Item key={`${this.meunMember?.Id}-1`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>类型</div><div>{DEVICE.TYPE.description(this.meunMember?.TypeId)}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={`${this.meunMember?.Id}-2`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>名称</div><div>{this.meunMember?.Name}</div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key={`${this.meunMember?.Id}-3`} className="marker-info-group-menu disable">
                        <div className="marker-info-group-menu-item">
                            <div>编号</div><div>{this.meunMember?.SerialNumber}</div>
                        </div>
                    </Menu.Item>
                </>
                break;
            default:
                break;
        }
        return <Menu
            id="device-left-menu"
            style={deviceMenuStyle}
            className={"situation-menu " + theme}
            selectedKeys={[]}
            defaultSelectedKeys={[]}
            onClick={() => {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceMenuStyle: this.hideMenuStyle(),
                })
            }}
        >
            {
                (deviceInConference && room) ? <Menu.SubMenu key="switch-video" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-switch", "选看")}>
                    <Menu.Item key="left1" className={deviceStreamId === 0 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 0)
                    }}>{this.mkMenuItem("icon-window-left-top", "左边窗口1")}</Menu.Item>
                    <Menu.Item key="left2" className={deviceStreamId === 1 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 1)
                    }}>{this.mkMenuItem("icon-window-left-top", "左边窗口2")}</Menu.Item>
                    <Menu.Item key="left3" className={deviceStreamId === 2 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 2)
                    }}>{this.mkMenuItem("icon-window-left-bottom", "左边窗口3")}</Menu.Item>
                    <Menu.Item key="left4" className={deviceStreamId === 3 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 3)
                    }}>{this.mkMenuItem("icon-window-left-bottom", "左边窗口4")}</Menu.Item>
                    <Menu.Item key="right1" className={deviceStreamId === 4 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 4)
                    }}>{this.mkMenuItem("icon-window-right-top", "右边窗口1")}</Menu.Item>
                    <Menu.Item key="right2" className={deviceStreamId === 5 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 5)
                    }}>{this.mkMenuItem("icon-window-right-top", "右边窗口2")}</Menu.Item>
                    <Menu.Item key="right3" className={deviceStreamId === 6 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 6)
                    }}>{this.mkMenuItem("icon-window-right-bottom", "右边窗口3")}</Menu.Item>
                    <Menu.Item key="right4" className={deviceStreamId === 7 ? "disable" : "enable"} onClick={(e) => {
                        this.deviceConference.viewMember(this.meunMember, 7)
                    }}>{this.mkMenuItem("icon-window-right-bottom", "右边窗口4")}</Menu.Item>
                </Menu.SubMenu> : undefined
            }
            {
                (!room) ? <Menu.Item key="create" className={`${(sdkInit && !loading && deviceIsIdle && ((this.state.mode === 'controlled' && linkedScreen) || this.state.mode !== 'controlled')) ? "enable" : "disable"}`} onClick={e => {
                    this.setState({
                        conferenceVisible: true,
                        loading: true,
                    }, () => {
                        if (this.meunMember) {
                            message.loading("正在调度", 30, linkedScreen?.SipNum || profile?.userId);
                            console.info("create room", this.meunMember);
                            this.deviceConference && this.deviceConference.createRoom([this.meunMember], task?.MissionInfo, linkedScreen);
                        }
                    })
                }}>{this.mkMenuItem("icon-create", "调度" + ((this.state.mode === 'controlled' && !linkedScreen) ? "（无在线大屏）" : ""))}</Menu.Item> : undefined
            }
            {
                (room && !deviceInConference) ? <Menu.Item key="invite" className={`${(sdkInit && !loading && deviceIsIdle && ((this.state.mode === 'controlled' && linkedScreen) || this.state.mode !== 'controlled')) ? "enable" : "disable"}`} onClick={e => {
                    if (this.meunMember) {
                        message.loading("正在调度", 30, this.meunMember?.SipNum);
                        console.info("invite member", this.meunMember);
                        this.deviceConference && this.deviceConference.inviteMember([this.meunMember]);
                    }
                }}>{this.mkMenuItem("icon-invite", "调度" + ((this.state.mode === 'controlled' && !linkedScreen) ? "（无在线大屏）" : ""))}</Menu.Item> : undefined
            }
            {
                deviceInConference ? <Menu.Item key="kick" className={members?.find(m => m.userId === this.meunMember?.SipNum) ? "online" : "offline"} onClick={e => {
                    this.meunMember && this.deviceConference.kickMember([this.meunMember]);
                }}>{this.mkMenuItem("icon-kick", "踢出")}</Menu.Item> : undefined
            }
            {
                this.meunMember?.type === 'staff' ? <Menu.Item key="route" onClick={() => {
                    let to = new window.BMapGL.Point(task?.MissionInfo?.CentreLng, task?.MissionInfo?.CentreLat);
                    this.routeOnClick(this.meunMember?.staff, to)
                }}>
                    {this.mkMenuItem("icon-route", "路径")}
                </Menu.Item> : undefined
            }
            {this.meunMember?.type !== 'staff' ? <Menu.Item key="del" onClick={e => {
                Modal.confirm({
                    title: `是否移除设备${this.meunMember.Name}？`,
                    cancelText: "我再想想",
                    onOk: async () => {
                        let data = {
                            DeviceId: this.meunMember.Id,
                            Lng: 0,
                            Lat: 0,
                        }
                        let rsp = await apiTerminalSetLocation(data);
                        if (rsp?.Status === 0) {
                            message.success(`移除设备${this.meunMember.Name}成功`)
                            this.refreshDevice();
                        }
                    }
                })
            }}>{this.mkMenuItem("icon-cuo", "移除")}</Menu.Item> : undefined}
            <Menu.Divider/>
            <Menu.ItemGroup title={intoTitle} className="marker-info-group">
                {infoComponent}
            </Menu.ItemGroup>
        </Menu>
    }

    mkMenuMap() {
        let { loading, sdkInit, room, mode, mapMenuStyle, areaSelectMode, tilt, isFullScreen, task, mqttClient, theme, screenList, linkedScreen } = this.state;
        let { reqUserInfo } = this.props;
        return <Menu
            id="map-menu"
            className={"situation-menu " + theme}
            // openKeys={['screen']}
            selectedKeys={[]}
            defaultSelectedKeys={[]}
            style={mapMenuStyle}
            onClick={(e) => {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle()
                })
            }}
        >
            {
                task && mode !== 'screen' ? <Menu.Item key="select_create" className={(sdkInit && !loading && ((this.state.mode === 'controlled' && linkedScreen) || this.state.mode !== 'controlled')) ? "enable" : "disable"} onClick={e => {
                    if (!areaSelectMode) {
                        this.deviceDrawArea.clear()
                    }
                    this.setState({
                        areaSelectMode: true,
                        orgTilt: tilt,
                        tilt: 0,
                    }, () => {
                        this.map.setTilt(0)
                    })
                }}>{this.mkMenuItem("icon-area-select", "框选调度" + ((this.state.mode === 'controlled' && !linkedScreen) ? "（无在线大屏）" : ""))}</Menu.Item> : undefined
            }
            {
                room && mode !== 'screen' ? <Menu.Item key="end" onClick={(e) => {
                    this.deviceConference.destroyRoom();
                    this.setState({
                        members: [],
                    })
                }}>{this.mkMenuItem("icon-end", "结束调度")}</Menu.Item> : undefined
            }
            {task && mode !== 'screen' ? <Menu.Divider /> : undefined}
            {mode !== 'screen' ? <Menu.SubMenu key="task" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-task1", "任务")}>
                <Menu.Item key="task_refresh" onClick={e => {
                    if (task) {
                        this.refreshTask();
                        this.refreshDevice();
                        this.refreshStaff();
                    } else {
                        this.refreshTasks();
                    }
                }}>{this.mkMenuItem("icon-refresh", "刷新")}</Menu.Item>
            </Menu.SubMenu> : undefined}
            {
                (task && this.state.mode === 'controlled') ? <Menu.SubMenu key="screen" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-screen", "大屏")}>
                    <Menu.Item key="push" className={linkedScreen ? "enable" : "disable"} onClick={e => {
                        let center = this.map.getCenter();
                        let path = `ccs/task/${task.MissionInfo?.Id}`
                        mqttClient.publish(path, JSON.stringify({
                            action: "map",
                            content: {
                                lng: center.lng,
                                lat: center.lat,
                                tilt: this.map.getTilt(),
                                zoomLevel: this.map.getZoom(),
                                heading: this.map.getHeading(),
                            },
                        }))

                        mqttClient.publish(path, JSON.stringify({
                            action: "theme",
                            content: {
                                theme: this.state.theme,
                            },
                        }))

                        mqttClient.publish(path, JSON.stringify({
                            action: "drawer",
                            content: {
                                staff: this.state.staffListVisible,
                                device: this.state.deviceListVisible,
                                target: this.state.targetActiveKey,
                            },
                        }))

                    }}>{this.mkMenuItem("icon-push", "同步" + ((this.state.mode === 'controlled' && !linkedScreen) ? "（无在线大屏）" : ""))}</Menu.Item>
                    <Menu.Divider />
                    {
                        screenList ? <Menu.ItemGroup title="关联大屏" className="linked-screen-group">
                            {
                                screenList.map(s => {
                                    let tag = undefined;
                                    let enable = "enable";
                                    if (s.AppState !== 'online' || s.SipState !== 'online') {
                                        enable = "disable";
                                        tag = <Button type="link">离线</Button>
                                    } else if (s.SipNum === linkedScreen?.SipNum) {
                                        tag = <IconFont type="icon-duihao" />
                                    }
                                    return <Menu.Item key={s.SipNum} className={"linked-screen-group-menu " + enable}>
                                        <div className="linked-screen-group-menu-item">
                                            <div>{s.NickName}</div>
                                            {tag}
                                        </div>
                                    </Menu.Item>
                                })
                            }
                        </Menu.ItemGroup> : undefined
                    }
                </Menu.SubMenu> : undefined
            }
            {mode !== 'screen' ? <Menu.SubMenu key="device" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-menu-device", "设备")}>
                <Menu.Item key="aim" onClick={e => {
                    this.setState({
                        selectDeviceVisible: true,
                        selectDeviceMode: "aim",
                        mapMenuStyle: this.hideMenuStyle(),
                    })

                }}>{this.mkMenuItem("icon-local", "定位")}</Menu.Item>
                <Menu.Item key="location" onClick={e => {
                    this.setState({
                        selectDeviceVisible: true,
                        selectDeviceMode: "put",
                        mapMenuStyle: this.hideMenuStyle(),
                    })
                }}>{this.mkMenuItem("icon-center", "放置")}</Menu.Item>
            </Menu.SubMenu> : undefined}
            <Menu.SubMenu key="setting" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-view-setting", "视图")}>
                <Menu.Item key="full-screen" onClick={() => {
                    toggleFullscreen();
                }}>
                    <div className="left">
                        <IconFont className="icon-menu" type={!isFullScreen ? "icon-full-screen" : "icon-full-screen-exit"} />
                        <span className="description">{!isFullScreen ? "全屏" : "退出全屏"}</span>
                    </div>
                </Menu.Item>
                {mode !== 'screen' ? <Menu.Item key="reload" onClick={e => {
                    let lng = task?.MissionInfo?.CentreLng || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLng")) || 113.97005752568613);
                    let lat = task?.MissionInfo?.CentreLat || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapCenterLat")) || 22.589486651330265);
                    
                    this.map.setCenter(new window.BMapGL.Point(lng, lat), {
                        noAnimation: false,
                        callback: () => {
                            let zoomLevel = (task?.MissionInfo?.ZoomLevel || parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapZoomLevel")) || "14.00")).toFixed(2);
                            let tilt = parseFloat((window.localStorage && window.localStorage.getItem("ccs::mapTilt")) || 0).toFixed(2);
                            this.setState({
                                lng: lng,
                                lat: lat,
                                tilt: tilt,
                                zoomLevel: zoomLevel,
                                heading: 0,
                            }, () => {
                                this.map.setZoom(zoomLevel)
                                this.map.setTilt(tilt);
                                this.map.setHeading(0);
                            })
                        }
                    })
                }}>{this.mkMenuItem("icon-default", "回到中心点")}</Menu.Item> : undefined}
                {
                    !task ? <Menu.Item key="save" onClick={e => {
                        if (window.localStorage) {
                            let centerPoint = this.map.getCenter();
                            window.localStorage.setItem("ccs::mapCenterLng", centerPoint?.lng);
                            window.localStorage.setItem("ccs::mapCenterLat", centerPoint?.lat);
                            window.localStorage.setItem("ccs::mapZoomLevel", this.state.zoomLevel);
                            window.localStorage.setItem("ccs::mapTilt", this.state.tilt);
                        }
                        message.success(`保存视图成功`)
                    }}>{this.mkMenuItem("icon-aim", "保存")}</Menu.Item> : undefined
                }
                {
                    mode !== 'screen' ? <Menu.Item onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        this.drivingRoutes?.forEach(marker => {
                            this.map.removeOverlay(marker)
                        })
                    }}>
                        {this.mkMenuItem("icon-cuo", "清除路径")}
                    </Menu.Item> : undefined
                }
            </Menu.SubMenu>
            {mode !== 'screen' ? <Menu.SubMenu key="theme" popupClassName={"situation-menu-submenu " + theme} title={this.mkMenuItem("icon-theme", "主题")}>
                <Menu.Item key="light" onClick={() => {
                    this.switchTheme("light")
                }}>{this.mkMenuItem("icon-theme-light", "浅色")}</Menu.Item>
                <Menu.Item key="dark" onClick={() => {
                    this.switchTheme("dark")
                }}>{this.mkMenuItem("icon-theme-dark", "深色")}</Menu.Item>
            </Menu.SubMenu> : undefined}
            {(mode !== 'screen' && reqUserInfo?.user?.role?.type !== "daping.task") ? <Menu.Divider /> : undefined}
            {
                reqUserInfo?.user?.role?.type !== "daping.task" ? (task ?
                    <Menu.Item key="exit" onClick={e => {
                        Modal.confirm({
                            title: `是否退出登录？`,
                            cancelText: "我再想想",
                            okText: "退出",
                            onOk: () => {
                                message.destroy();
                                this.props.history.push('/')
                            }
                        })
                    }}>{this.mkMenuItem("icon-exit", "退出登录")}</Menu.Item>
                    :
                    <Menu.Item key="back" onClick={e => {
                        this.props.history.push({
                            pathname: "/ccs/task/summary",
                        })
                    }}>{this.mkMenuItem("icon-back", "返回")}</Menu.Item>) : undefined
            }
        </Menu>
    }

    mkMenuDrawArea() {
        let { loading, orgTilt, allDevices, sdkInit, room, drawAreaMenuStyle, drawAreaStart, drawAreaEnd, theme, task, linkedScreen } = this.state;
        return <Menu
            id="draw-area-menu"
            style={drawAreaMenuStyle}
            className={"situation-menu " + theme}
            selectedKeys={[]}
            defaultSelectedKeys={[]}
            onClick={() => {
                this.setState({
                    drawAreaMenuStyle: this.hideMenuStyle(),
                })
            }}
        >
            {
                drawAreaEnd?.lng ? <Menu.Item key="diaodu" onClick={() => {
                    this.deviceDrawArea.clear()
                    let members = this.areaMembers(drawAreaStart, drawAreaEnd, allDevices, task?.StaffList);
                    if (members?.length > 0) {
                        this.map.setTilt(orgTilt)
                        this.setState({
                            drawAreaStart: { lng: 0, lat: 0 },
                            drawAreaEnd: { lng: 0, lat: 0 },
                            conferenceVisible: true,
                            areaSelectMode: false,
                            tilt: orgTilt
                        }, () => {
                            if (room) {
                                this.deviceConference.inviteMember(members);
                            } else {
                                this.deviceConference.createRoom(members, task?.MissionInfo, linkedScreen);
                            }
                        })
                    } else {
                        this.setState({
                            drawAreaStart: { lng: 0, lat: 0 },
                            drawAreaEnd: { lng: 0, lat: 0 },
                        })
                        message.error("没有发现可以调度的设备")
                    }
                    
                }}>{this.mkMenuItem("icon-invite", "调度" + ((this.state.mode === 'controlled' && !linkedScreen) ? "（无在线大屏）" : ""))}</Menu.Item> : undefined
            }
            <Menu.Item key="exit" onClick={() => {
                this.deviceDrawArea.clear()
                this.map.setTilt(orgTilt)
                this.setState({
                    areaSelectMode: false,
                    tilt: orgTilt,
                })
            }}>{this.mkMenuItem("icon-cuo", "退出框选")}</Menu.Item>
        </Menu>
    }

    mkConference() {
        if (!this.taskId) {
            return undefined;
        }
        let { conferenceVisible, mode } = this.state;
        return <Conference
            mode={mode}
            ref={(element) => this.deviceConference = element}
            reqUserInfo={this.props.reqUserInfo}
            dispatch={this.props.dispatch}
            visible={conferenceVisible}
            onInitOk={(profile) => {
                console.log("onInitOk", profile);
                this.setState({
                    sdkInit: true,
                    tips: <div>就绪</div>,
                    profile: profile,
                })
            }}
            onInitError={(errCode, errDesc) => {
                console.log("onInitError", errCode, errDesc);
                this.setState({
                    tips: <div><Spin size="small" />正在准音视频组件...</div>,
                    sdkInit: false,
                })
            }}
            onRoomOk={(room) => {
                console.log("onRoomOk", room);

                this.setState({
                    room: room,
                    loading: false,
                }, () => {
                    setTimeout(() => {
                        this.refreshTask();
                        this.refreshDevice();
                        this.refreshStaff();
                    }, 500);
                })
            }}
            onRoomError={(errorCode, errorDesc) => {
                console.log("onRoomError", errorCode, errorDesc);

                this.setState({
                    room: null,
                    conferenceVisible: false,
                })
            }}
            onRoomDestroy={() => {
                console.log("onRoomDestroy");

                this.setState({
                    conferenceVisible: false,
                    room: null,
                }, () => {
                    setTimeout(() => {
                        message.info("调度已结束")
                        this.refreshTask();
                        this.refreshDevice();
                        this.refreshStaff();
                    }, 500);
                })
            }}
            onRoomChange={(room) => {
                console.log("onRoomChange");

                this.setState({
                    room: room,
                })
            }}
            onMemberChange={(type, member, members) => {
                console.log("onMemberChange", type, member, members);
                let { profile, linkedScreen, allDevices, allStaffs } = this.state;
                switch (type) {
                    case window.vmeeting.ACTION.MEMBER.JOINED:
                        if (profile?.userId === member.userId) {
                            message.success("你已加入调度", 5, member.userId)
                        } else if (linkedScreen?.SipNum === member.userId) {
                            message.success(`${member.nickName}已加入调度`, 5, member.userId)
                        } else {
                            clearTimeout(this.joinNotifyMemberTimer)
                            if (!this.joinNotifyMembers?.length) {
                                this.joinNotifyMembers = [];
                            }
                            let nickName = allStaffs?.find(s => s.DeviceList?.find(d => d.SipNum === member.userId))?.NickName || allDevices?.find(d => d.SipNum === member.userId)?.Name || member.nickName;
                            this.joinNotifyMembers.push(nickName)

                            this.joinNotifyMemberTimer = setTimeout(() => {
                                this.joinNotifyMemberTimer = 0;
                                let msg = `${nickName}已加入`;
                                if (this.joinNotifyMembers?.length > 1) {
                                    msg = `${this.joinNotifyMembers.slice(0, 3).join(',')}...已加入`
                                }
                                message.info(msg, 3, 'member_joined')
                                this.joinNotifyMembers = [];
                            }, 1000);
                            
                        }
                        break;
                    case window.vmeeting.ACTION.MEMBER.LEAVED:
                        if (profile?.userId === member.userId) {
                            message.info("你已退出调度", 5, member.userId)
                        } else if (linkedScreen?.SipNum === member.userId) {
                            message.success(`${member.nickName}已退出调度`, 5, member.userId)
                        } else {
                            clearTimeout(this.leaveNotifyMemberTimer)
                            if (!this.leaveNotifyMembers?.length) {
                                this.leaveNotifyMembers = [];
                            }
                            let nickName = allStaffs?.find(s => s.DeviceList?.find(d => d.SipNum === member.userId))?.NickName || allDevices?.find(d => d.SipNum === member.userId)?.Name || member.nickName;
                            this.leaveNotifyMembers.push(nickName)
                            this.leaveNotifyMemberTimer = setTimeout(() => {
                                this.leaveNotifyMemberTimer = 0;
                                let msg = `${nickName}已退出`;
                                if (this.leaveNotifyMembers?.length > 1) {
                                    msg = `${this.leaveNotifyMembers.slice(0, 3).join(',')}...已退出`
                                }
                                message.info(msg, 3, 'member_leave')
                                this.leaveNotifyMembers = [];
                            }, 1000);
                        }
                        break;
                    default:
                        break;
                }
                this.setState({
                    members: members,
                }, () => {
                    this.refreshTask();
                    this.refreshDevice();
                    this.refreshStaff();
                })
            }}
            onViewChange={(view) => {
                this.setState({
                    view: view,
                })
            }}
        />
    }

    mkHeader() {
        let { task, taskStateStatistic, taskStateStatisticTotal,
            roles, staffRoleStatistic, staffRoleStatisticTotal,
            deviceTypeStatistic, deviceTypeStatisticTotal, deviceListVisible, staffListVisible,
            targetActiveKey, targetPoisTitle, targetPoisAddress,
        } = this.state;
        if (task) {
            return <div className="header">
                <div className="left">
                    <Tooltip title="点击查看设备列表">
                        <div className="device" onClick={() => {
                            this.setState({
                                deviceListVisible: !deviceListVisible,
                                staffListVisible: false,
                                mapMenuStyle: this.hideMenuStyle(),
                                deviceMenuStyle: this.hideMenuStyle(),
                            })
                        }}>
                            <div>设备</div>
                            <div>{task?.DeviceList?.length || 0}</div>
                        </div>
                    </Tooltip>
                    <Tooltip title="点击查看人员列表">
                        <div className="staff" onClick={() => {
                            this.setState({
                                staffListVisible: !staffListVisible,
                                deviceListVisible: false,
                                mapMenuStyle: this.hideMenuStyle(),
                                deviceMenuStyle: this.hideMenuStyle(),
                            })
                        }}>
                            <div>人员</div>
                            <div>{task?.StaffList?.length || 0}</div>
                        </div>
                    </Tooltip>
                </div>
                <div className="mid">
                    <div className="title">{task?.MissionInfo?.Name}</div>
                    <Clock className="clock"/>
                </div>
                <div className="right">
                    <div className="target">
                        <Collapse
                            activeKey={targetActiveKey}
                            expandIconPosition="right"
                            expandIcon={({ isActive }) => <CaretLeftOutlined rotate={isActive ? -90 : 0} />}
                            bordered={false}
                            onChange={(key) => {
                                this.setState({
                                    targetActiveKey: key
                                })
                            }}
                        >
                            <Collapse.Panel header="目标" key="1">
                                {task?.MissionInfo?.TargetPicUrl ? <div><img className="img" alt="" src={task?.MissionInfo?.TargetPicUrl} /></div> : undefined}
                                <Descriptions className="target-descriptions" column={1}>
                                    <Descriptions.Item label={<IconFont className="icon-menu" type="icon-local" />}>
                                        <div className="address">
                                            <div className="address-warpper">
                                                <span className="address-title">{targetPoisTitle}</span>
                                            </div>
                                            <div className="address-addr">{targetPoisAddress}</div>
                                        </div>
                                    </Descriptions.Item>
                                {
                                    task?.MissionInfo?.Target ? <Descriptions.Item label={<IconFont className="icon-menu" type="icon-user" />}>{task?.MissionInfo?.Target}</Descriptions.Item> : undefined
                                }
                                {
                                    task?.MissionInfo?.TargetDesc ? <Descriptions.Item label={<IconFont className="icon-menu" type="icon-description" />}>{task?.MissionInfo?.TargetDesc}</Descriptions.Item> : undefined
                                }
                                </Descriptions>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
        } else {
            return <div className="header2">
                <div className="left">
                    
                </div>
                <div className="right">
                    <div className="style1">
                        <div className="title">任务</div>
                        <div className="content">
                            <div className="detail-items">
                                {
                                    taskStateStatistic?.map(t => {
                                        return <div key={t?.State} className="common-item">
                                            <div>{TASK.STATE.description(t?.State)}</div>
                                            <div>{t?.Count || 0}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="total-item">
                                <div>总计</div>
                                <div className="total">{taskStateStatisticTotal}</div>
                            </div>
                        </div>
                    </div>
                    <div className="style1">
                        <div className="title">设备</div>
                        <div className="content">
                            <div className="detail-items">
                                {
                                    deviceTypeStatistic?.map(t => {
                                        return <div key={t?.TypeId} className="common-item">
                                            <div>{DEVICE.TYPE.description(t?.TypeId)}</div>
                                            <div>{t?.Count || 0}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="total-item">
                                <div>总计</div>
                                <div className="total">{deviceTypeStatisticTotal}</div>
                            </div>
                        </div>
                    </div>
                    <div className="style1">
                        <div className="title">人员</div>
                        <div className="content">
                            <div className="detail-items">
                                {
                                    staffRoleStatistic?.map(t => {
                                        return <div key={t?.RoleId} className="common-item">
                                            <div>{roles?.find(r => r?.Id === t.RoleId)?.Name}</div>
                                            <div>{t?.Count || 0}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="total-item">
                                <div>总计</div>
                                <div className="total">{staffRoleStatisticTotal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }
        
    }

    mkAddressSearch() {
        return <div className="address-search">
            <Input id="suggestId" placeholder="搜索地址" className="address-search-input"/>
            <div id="searchResultPanel" className="address-search-result"/>
        </div>
    }

    mkDrawArea() {
        let { width, height, orgTilt, areaSelectMode, theme } = this.state;
        return <DrawRectangle
            theme={theme}
            className={"draw-rectangle " + (areaSelectMode ? "show" : "hidden")}
            ref={(element) => this.deviceDrawArea = element}
            width={width}
            height={height}
            onStart={(e) => {
                this.setState({
                    drawAreaStart: this.curLatlng,
                    drawAreaEnd: {lng: 0, lat: 0},
                    drawAreaMenuStyle: this.hideMenuStyle(),
                    lng: this.curLatlng.lng,
                    lat: this.curLatlng.lat,
                })
            }}
            onEnd={(e) => {
                // drawAreaEnd控制了draw-area-menu菜单的项，先设置，让菜单的项增加
                this.setState({
                    drawAreaEnd: this.curLatlng,
                    lng: this.curLatlng.lng,
                    lat: this.curLatlng.lat,
                }, () => {
                    // 再设置draw-area-menu菜单显示，这样高度计算就正确了
                    this.setState({
                        drawAreaMenuStyle: this.showMenuStyle(e, "draw-area-menu"),
                    })
                })
            }}
            onRightClick={(e) => {
                this.setState({
                    drawAreaMenuStyle: this.showMenuStyle(e, "draw-area-menu"),
                })
            }}
            onCancel={() => {
                this.setState({
                    drawAreaMenuStyle: this.hideMenuStyle(),
                    areaSelectMode: false,
                    tilt: orgTilt,
                })
                this.map.setTilt(orgTilt)
            }}
        />
    }

    mkFooter() {
        let { lng, lat, zoomLevel, tilt, heading, address, room, task } = this.state;
        return <div className="footer">
            <div className="left">
                {
                    room ? <Tooltip title="正在音视频调度" className="tooltip">
                        <IconFont className="icon-doing" type="icon-circle" />
                    </Tooltip> : undefined
                }
                <Tooltip title="地址" className="tooltip">
                    <IconFont className="icon-menu" type="icon-local" /><div className="title address">{address}</div>
                </Tooltip>
                {
                    task ? <Tooltip title="半径" className="tooltip">
                        <IconFont className="icon-menu" type="icon-radius" /><div className="title radius">{task?.MissionInfo?.Radius}</div>
                    </Tooltip> : undefined
                }
            </div>
            <div className="right">
                <Tooltip title="经纬度" className="tooltip">
                    <IconFont className="icon-menu" type="icon-aim" /><div className="title lnglat">{lng}, {lat}</div>
                </Tooltip>
                <Tooltip title="缩放级别" className="tooltip">
                    <IconFont className="icon-menu" type="icon-zoom" /><div className="title zoom">{zoomLevel}</div>
                </Tooltip>
                <Tooltip title="视角倾斜度（3D）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-tilt" /><div className="title tilt">{tilt}°</div>
                </Tooltip>
                <Tooltip title="正北方偏移（顺时针）" className="tooltip">
                    <IconFont className="icon-menu" type="icon-heading" /><div className="title heading">{heading}°</div>
                </Tooltip>
            </div>
        </div>
    }

    mkStaffDrawer() {
        let { theme, staffListVisible, staffColumns, terminalColumns, task, height } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={() => this.staffTableAction.reload()}>刷新</Button>,
        ]
        return <Drawer
            key="staff"
            mask={false}
            title="人员"
            width="50%"
            className={"task-drawer-list staff " + theme}
            placement="left"
            onClose={() => {
                this.setState({
                    staffListVisible: false,
                });
            }}
            visible={staffListVisible}
        >
            <ProTable
                className="table"
                columns={staffColumns}
                actionRef={ref => this.staffTableAction = ref}
                sticky={true}
                scroll={{ y: height - 454 }}
                request={async (params, sorter, filter) => {
                    let { data, total } = search(task?.StaffList, params, sorter, filter, staffColumns);
                    console.group('request staff');
                    console.log("data", data)
                    console.log("total", total)
                    console.groupEnd()
                    for (let key in this.staffTableExpandedAction) {
                        let ref = this.staffTableExpandedAction[key];
                        ref && ref.reset();
                    }
                    return {
                        data: data,
                        total: total,
                        success: true,
                    };
                }}
                expandable={{
                    expandedRowRender: (record) => {
                        return <div className="task-drawer-list-row-terminal">
                            <ProTable
                                actionRef={ref => this.staffTableExpandedAction[record.Id] = ref}
                                headerTitle="设备列表"
                                size="small"
                                columns={terminalColumns}
                                rowKey="Id"
                                search={false}
                                bordered={true}
                                dateFormatter="string"
                                options={false}
                                pagination={false}
                                request={async (params, sorter, filter) => {
                                    console.group('request device');
                                    console.log("staff", record);
                                    console.groupEnd();
                                    let list = Object.assign([], record.DeviceList || []);
                                    if (record.PhoneNum) {
                                        list.unshift({Id:record.PhoneNum, TypeId: DEVICE.TYPE.TELEPHONE, SipNum: record.PhoneNum })
                                    }
                                    return {
                                        data: list,
                                        success: true,
                                    };
                                }}
                            />
                        </div>
                    },
                }}
                rowKey="Id"
                size="small"
                pagination={{
                    size: "small",
                    defaultPageSize: 10,
                    position: ['bottomRight'],
                    showQuickJumper: true,
                    showTotal: (total, range) => `第 ${range[0]}-${range[1]} 人/总共 ${total} 人`
                }}
                search={{ filterType: "light" }}
                dateFormatter="string"
                options={{
                    density: false,
                    setting: true,
                    reload: false,
                    fullScreen: false,
                    search: false,
                }}
                toolBarRender={() => operations}
            />
        </Drawer>
    }

    mkDeviceDrawer() {
        let { theme, deviceListVisible, deviceColumns, task, height } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={() => this.deviceTableAction.reload()}>刷新</Button>,
        ]
        return <Drawer
            key="device"
            mask={false}
            title="设备"
            width="50%"
            className={"task-drawer-list device " + theme}
            placement="left"
            onClose={() => {
                this.setState({
                    deviceListVisible: false,
                });
            }}
            visible={deviceListVisible}
        >
            <ProTable
                className="table"
                columns={deviceColumns}
                actionRef={ref => this.deviceTableAction = ref}
                sticky={true}
                scroll={{ y: height - 454 }}
                request={async (params, sorter, filter) => {
                    let { data, total } = search(task?.DeviceList, params, sorter, filter, deviceColumns);
                    return {
                        data: data,
                        total: total,
                        success: true,
                    };
                }}
                rowKey="Id"
                size="small"
                pagination={{
                    size: "small",
                    defaultPageSize: 10,
                    position: ['bottomRight'],
                    showQuickJumper: true,
                }}
                search={{ filterType: "light" }}
                dateFormatter="string"
                options={{
                    density: false,
                    setting: true,
                    reload: false,
                    fullScreen: false,
                    search: false,
                }}
                toolBarRender={() => operations}
            />
        </Drawer>
    }

    mkDrawer() {
        return <>
            {this.mkStaffDrawer()}
            {this.mkDeviceDrawer()}
        </>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        screenUnwatchFull((e) => this.screenOnChange(e));
        this.uninitMap();
    }

    componentDidMount() {
        window.situation = this
        document.oncontextmenu = function () {
            return false;
        }

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninit();
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninit();
        }.bind(this)

        screenWatchFull((e) => this.screenOnChange(e));
        screenWatchResize(screenResize.bind(this))
        screenResize.bind(this)();
        this.init();
    }

    render() {
        let { theme, mode } = this.state;
        return (
            <div className={`situation ${theme} ${mode} ${(this.taskId ? "task" : "summary")}`}>
                {
                    mode !== 'screen' ? <>
                        {this.mkAddressSearch()}
                        {this.mkSelectDevice()}
                        {this.mkMenuDevice()}
                        {this.mkMenuDrawArea()}
                        {this.mkDrawArea()}
                    </>: undefined
                }
                {this.mkMenuMap()}
                {this.mkConference()}
                {this.mkHeader()}
                {this.mkDrawer()}
                {this.mkFooter()}
                <div className="map" id="map"/>
            </div>
        )
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Situation);

