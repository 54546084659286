/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import moment from 'moment';
import { config } from '../../util/version';
import { IconFont } from '../../util/hoComponent';
import { randomString } from '../../util/logic';

class Conference extends Component {
    constructor(props) {
        super(props);

        // 上层的回调
        this.onInitOk = props?.onInitOk;
        this.onInitError = props?.onInitError;
        this.onRoomError = props?.onRoomError;
        this.onRoomOk = props?.onRoomOk;
        this.onRoomDestroy = props?.onRoomDestroy;
        this.onRoomChange = props?.onRoomChange;
        this.onMemberChange = props?.onMemberChange;
        this.onViewChange = props?.onViewChange;

        // 常量
        this.status = 0;
        this.maxViewNum = props?.maxViewNum || 8;

        // reactor state
        this.state = {

        }
        this.room = null;
        this.actionMembers = [];
    }
    uninitSdk() {
        this.destroyRoom();
        if (this.vmeetingsdk) {
            this.vmeetingsdk.uninit()
            this.vmeetingsdk = null;
        }
    }

    uninit() {
        this.uninitSdk();
    }

    initSdk() {
        this.uninitSdk();
        let { reqUserInfo } = this.props;
        let c = {
            // logLevel: 'debug',
            appid: reqUserInfo.appInfo.AppId,
            appCode: reqUserInfo.appInfo.AppCode,
            account: reqUserInfo.appInfo.Account,
            eventListener: this.sdkListener.bind(this),
            roomListener: this.roomListener.bind(this),
            host: {
                ws: config.host.ws,
                as: config.host.cs,
                cs: config.host.cs,
            },
            room: {
                autoViewDisable: true,
                ghost: true,
            }
        }
        this.vmeetingsdk = new window.vmeeting.Sdk(c)
    }

    init() {
        this.initSdk();
    }

    summary(members, key) {
        return members?.reduce((total, cur) => { 
            total[cur[key]] = total[cur[key]] ? total[cur[key]] + 1 : 1;
            return total
        }, {}) || {}
    }

    sdkListener(e) {
        console.log("sdkListener type: " + e.type);
        switch (e.type) {
            case window.vmeeting.EVENT.SDK.SUCCESS: {
                // SDK初始化成功
                // console.log("SDK启动成功！现在你可以输入主题创建房间或输入房间号加入房间")
                this.profile = e.profile;
                this.status = 1;
                this.reason = null;
                this.onInitOk && this.onInitOk(this.profile);
                break;
            }
            case window.vmeeting.EVENT.SDK.WARN: {
                this.status = 0;
                this.reason = e.description;
                this.onInitError && this.onInitError(e.code, e.description);
                break;
            }
                
            case window.vmeeting.EVENT.SDK.ERROR: {
                // SDK初始化失败
                // console.error("SDK启动失败！错误码：" + e.code + " 详细信息：" + e.description)
                this.status = 0;
                this.reason = e.description;
                this.onInitError && this.onInitError(e.code, e.description);
                this.initSdk()
                break;
            }
                
            default:
                break;
        }
    }

    roomListener(e) {
        // console.log("roomListener type: " + e.type);
        switch (e.type) {

            case window.vmeeting.EVENT.SESSION.ROOM: {
                switch (e.action) {
                    case window.vmeeting.ACTION.ROOM.PROCESS:
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            // 创建/加入房间失败
                            // console.error("创建/加入房间失败！错误码：" + e.code + " 详细信息：" + e.description)
                            // console.error("create/join room error. code: " + e.code)
                            this.status = 1;
                            this.onRoomError && this.onRoomError(e.code, e.description)
                        }
                        break;
                    
                    case window.vmeeting.ACTION.ROOM.CONNECTED: {
                        // 创建/加入房间成功
                        this.room = e.room;
                        // console.log(room);
                        // console.log("创建/加入房间成功。房间号：" + room.accessCode + " 主题：" + room.subject)
                        this.onRoomOk && this.onRoomOk(this.room)
                        for (let index in Array.from({ length: this.maxViewNum })) {
                            this.vmeetingsdk && this.vmeetingsdk.bindRemoteVideo(`html_video_remote${index}`, index);
                        }
                        this.vmeetingsdk && this.vmeetingsdk.bindRemoteAudio("html_audio_remote0", 0);
                        this.vmeetingsdk && this.vmeetingsdk.inviteMember({
                            userIds: (this.createMembers || []).map(m => m.SipNum),
                        })
                        
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.TERMINATED: {
                        // 退出房间
                        for (let index in Array.from({ length: this.maxViewNum })) {
                            this.vmeetingsdk && this.vmeetingsdk.unbindRemoteVideo(`html_video_remote${index}`, index);
                        }
                        this.vmeetingsdk && this.vmeetingsdk.unbindRemoteAudio("html_audio_remote0");
                        this.onRoomDestroy && this.onRoomDestroy()
                        this.status = 1;
                        this.room = null;
                        this.members = [];
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.ONCHANGE: {
                        // 房间信息变化（成员入会、离会、被静音等）
                        this.room = e.room;
                        console.log("ONROOMCHANGE", e.code, this.room);
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            return;
                        }

                        clearTimeout(this.roomChangeTimer);
                        this.roomChangeTimer = setTimeout(() => {
                            this.roomChangeTimer = 0;
                            this.onRoomChange && this.onRoomChange(this.room)
                        }, 500);
                        break;
                    }
                
                    default:
                        break;
                }
                break;
            }
                
 
            case window.vmeeting.EVENT.SESSION.MEMBER: {
                
                let member = e.member;
                let action = e.action;
                let curMembers = e.members || [];

                // console.log("ONMEMBERCHANGE", e.code, action, member);
                if (e.code !== window.vmeeting.CODE.SUCCESS) {
                    return;
                }

                let arrays = Array.from({ length: this.maxViewNum });
                let views = [];
                let media = Object.assign([], this.view?.media || []);
                let ssrc = Object.assign([], this.view?.ssrc || []);
                let flag = false;

                this.onMemberChange && this.onMemberChange(action, member, curMembers)

                let process = (actionMembers, curMembers) => {

                    actionMembers.forEach(am => {
                        let { action, member } = am;
                        switch (action) {
                            case window.vmeeting.ACTION.MEMBER.JOINED:
                                if (member.userId === this.profile?.userId) {
                                    return;
                                }
                                let ret = media.find(userId => userId === member.userId)
                                if (!ret && media.length < this.maxViewNum) {
                                    for (let streamId in arrays) {
                                        let index = ssrc.findIndex(s => `${s}` === `${streamId}`)
                                        if (index === -1) {
                                            flag = true;
                                            ssrc.push(parseInt(streamId))
                                            media.push(member.userId)
                                            break;
                                        }
                                    }
                                }
                                break;
                            case window.vmeeting.ACTION.MEMBER.LEAVED:
                                if (member.userId === this.profile?.userId) {
                                    return;
                                }
                                let index = media.findIndex(userId => userId === member.userId)
                                if (index !== -1) {
                                    // 离会成员影响选流了
                                    // 剩下在会成员是否足够填补这个空缺
                                    for (let m of curMembers) {
                                        if (!media.find(userId => userId === m.userId) && m.status === window.vmeeting.STATUS.MEMBER.JOINED && m.userId !== this.profile?.userId) {
                                            media[index] = m.userId;
                                            flag = true;
                                            break;
                                        }
                                    }
                                    // 没有人能填补，清除这个位置
                                    if (!flag) {
                                        delete media[index];
                                        delete ssrc[index];
                                        flag = true;
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    
                    if (flag) {
                        ssrc.forEach((ss, index) => {
                            views.push({
                                streamId: ss,
                                userId: media[index],
                                definition: 4,
                            })
                        })

                        this.vmeetingsdk && this.vmeetingsdk.viewMediaMember({
                            userId: this.profile.userId,
                            medias: views?.length > 0 ? views : [{
                                streamId: 0,
                                userId: this.profile.userId,
                                definition: 4,
                            }]
                        })
                        this.viewNotifyTimer = setTimeout(() => {
                            this.viewNotifyTimer = 0;
                        }, 5 * 1000);
                    }
                }
                
                if (action === window.vmeeting.ACTION.MEMBER.JOINED || action === window.vmeeting.ACTION.MEMBER.LEAVED) {
                    this.actionMembers.push({
                        action, member
                    })
                }
                clearTimeout(this.viewTimer);

                let loop = () => {
                    this.viewTimer = setTimeout(() => {
                        if (!this.viewNotifyTimer) {
                            process(this.actionMembers, curMembers)
                            this.actionMembers = [];
                        } else {
                            loop()
                        }
                    }, 500);
                }
                loop();
                this.members = curMembers;
                this.forceUpdate();
                break;
            }
                
            case window.vmeeting.EVENT.SESSION.MEDIA: {

                switch (e.action) {
                    case window.vmeeting.ACTION.MEDIA.ONCHANGE: {
                        // 视频选流变化
                        let curView = e.mediaOrg;
                        let view = JSON.parse(JSON.stringify(this.view || {}));
                        console.log("ONVIEWCHANGE", e.code, curView, view);
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            return;
                        }
                        let selfMediaIndex = curView?.media?.findIndex(userId => userId === this.profile?.userId);
                        if (selfMediaIndex !== -1) {
                            delete curView?.media[selfMediaIndex];
                            delete curView?.ssrc[selfMediaIndex];
                            delete curView?.definition[selfMediaIndex];
                        }
                        // 比较前后选流的差异。重新刷新下video的视频
                        Array.from({ length: this.maxViewNum }).forEach((_, streamId) => {

                            let orgSsrcIndex = view?.ssrc?.findIndex(ss => ss === streamId);
                            let orgSipNum = view?.media?.[orgSsrcIndex]

                            let curSsrcIndex = curView?.ssrc?.findIndex(ss => ss === streamId);
                            let curSipNum = curView?.media?.[curSsrcIndex]
                            // console.log("compare view", streamId, curSsrcIndex, curSipNum, orgSsrcIndex, orgSipNum);
                            if (orgSipNum !== curSipNum) {
                                if (orgSipNum && !curSipNum) {
                                    // 原来有，现在没有（移除）
                                    console.log("remove view", streamId);
                                    this.unbindView(streamId)
                                } else if (orgSipNum && curSipNum) {
                                    // 原来有，现在也有（发生变化）
                                    clearTimeout(this[`refreshViewTimer${streamId}`])
                                    this[`refreshViewTimer${streamId}`] = setTimeout(() => {
                                        this[`refreshViewTimer${streamId}`] = 0;
                                        console.log("refresh view", streamId);
                                        this.refreshView(streamId)
                                    }, 500);
                                } else if (!orgSipNum && curSipNum) {
                                    // 原来没有，现在有（添加）
                                    console.log("add view", streamId);
                                    this.bindView(streamId)
                                } else {

                                }
                            }
                        });
                        this.view = curView;
                        this.onViewChange && this.onViewChange(this.view)
                        if (this.viewNotifyTimer) {
                            clearTimeout(this.viewNotifyTimer);
                            this.viewNotifyTimer = 0;
                        }
                        this.forceUpdate();
                        break;
                    }
                        
                    case window.vmeeting.ACTION.MEDIA.PROCESS: {
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {

                        }
                        break;
                    }
                
                    default:
                        break;
                }

                break;
            }

            default:
                break;
        }
    }

    wsParse (msg) {
        let jsonStr = "{}";
        let content = {};
        let type = null;
        if (msg.startsWith("profile{")) {
            type = "profile";
            jsonStr = msg.substr("profile".length);
        } else if (msg.startsWith("Conference{")) {
            type = "conference";
            jsonStr = msg.substr("Conference".length);
        } else if (msg.startsWith("Screen{")) {
            type = "screen";
            jsonStr = msg.substr("Screen".length);
        } else if (msg.startsWith("CallEnd{")) {
            type = "callEnd";
            jsonStr = msg.substr("CallEnd".length);
        } else if (msg.startsWith("CallCreate{")) {
            type = "callCreate";
            jsonStr = msg.substr("CallCreate".length);
        } else if (msg.startsWith("CallJoin{")) {
            type = "callJoin";
            jsonStr = msg.substr("CallJoin".length);
        } else if (msg.startsWith("conferenceSendVideo{")) {
            type = "conferenceSendVideo";
            jsonStr = msg.substr("conferenceSendVideo".length);
        } else if (msg.startsWith("DesktopShare{")) {
            type = "screenShare";
            jsonStr = msg.substr("DesktopShare".length);
        } else if (msg.startsWith("DeviceConflict")) {
            type = "deviceConflict";
        } else if (msg.startsWith("ShowOrHideSmallWindows{")) {
            type = "showOrHideSmallWindows";
        } else if (msg.startsWith("WhiteBoard{")) {
            type = "whiteBoard";
            jsonStr = msg.substr("WhiteBoard".length);
        } else if (msg.startsWith("UploadLog{")) {
            type = "uploadLog";
        } else {
            
        }
    
        try {
            content = jsonStr.length > 0 ? JSON.parse(jsonStr) : {};
        } catch (error) {
    
        }
        return {
            type: type,
            content: content,
        };
    }

    subscribe(confSipNum, pushScreen) {
        let { reqUserInfo } = this.props;
        const clientId = `${randomString()}_${moment().unix()}`;
        let mqtt = require('mqtt')
        let client = mqtt.connect('', {
            port: reqUserInfo?.mqttInfo?.ServerPort,
            hostname: reqUserInfo?.mqttInfo?.ServerHost,
            username: reqUserInfo?.mqttInfo?.Accout,
            password: reqUserInfo?.mqttInfo?.Password,
            clientId: clientId,
            protocol: 'wss',
        })
        // 订阅会议相关消息
        let confTopic = `cs/mqtt/conference/${confSipNum}`
        let memberTopic = `cs/mqtt/conference/${confSipNum}/member/${pushScreen?.SipNum}`;
        client.on('connect', () => {
            client.subscribe(confTopic, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe ${confTopic} success`);
                }
            })
            client.subscribe(memberTopic, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe ${memberTopic} success`);
                }
            })
        })

        client.on('message', (dstTopic, message) => {
            if (dstTopic !== memberTopic && dstTopic !== confTopic) {
                return;
            }
            let { type, content } = this.wsParse(message.toString());
            console.log(type, content);
            switch (type) {
                case "conference": {
                    if (this.room && ((this.room.sipNum === content.conference.id) || !this.room)) {
                        let __dataMembers = (members) => {
                            let ms = Object.assign([], members || [])
                            ms.forEach(function (member) {
                                member.nickName = member?.user?.nickName || ""
                                member.avatarUrl = member?.user?.avatarUrl || ""
                                member.userId = member?.user?.id || ""
                                delete member['user']
                                delete member['device']
                            })
                            return ms;
                        }
                        let curConference = content;
                        let orgConference = this.room.conference;
                        console.group()
                        console.log("now:", curConference);
                        console.log("before:", orgConference);
                        console.groupEnd();
        
                        if (!curConference.conference.valid) {
                            this.onRoomDestroy && this.onRoomDestroy();
                            this.status = 1;
                            this.room = null;
                            this.members = [];
                        } else {
                            // eslint-disable-next-line no-unused-expressions
                            curConference?.participants?.forEach((curMember) => {
                                let orgMember = orgConference?.participants?.find(m => m.user.id === curMember.user.id) || undefined;
                                switch (curMember?.status) {
                                    case window.vmeeting.STATUS.MEMBER.JOINED:
                                        if (orgMember?.status !== window.vmeeting.STATUS.MEMBER.JOINED) {
                                            // 入会
                                            clearTimeout(this[curMember?.user?.id + "inviteTimer"])
                                            this.roomListener({
                                                type: window.vmeeting.EVENT.SESSION.MEMBER,
                                                action: window.vmeeting.ACTION.MEMBER.JOINED,
                                                code: window.vmeeting.CODE.SUCCESS,
                                                member: __dataMembers([JSON.parse(JSON.stringify(curMember || {}))])[0],
                                                members: __dataMembers(JSON.parse(JSON.stringify(curConference?.participants || []))),
                                            })
                                        }
                                        break;
                                    case window.vmeeting.STATUS.MEMBER.LEAVED:
                                        if (orgMember?.status !== window.vmeeting.STATUS.MEMBER.LEAVED) {
                                            // 离会
                                            clearTimeout(this[curMember?.user?.id + "kickTimer"])
                                            this.roomListener({
                                                type: window.vmeeting.EVENT.SESSION.MEMBER,
                                                action: window.vmeeting.ACTION.MEMBER.LEAVED,
                                                code: window.vmeeting.CODE.SUCCESS,
                                                member: __dataMembers([JSON.parse(JSON.stringify(curMember || {}))])[0],
                                                members: __dataMembers(JSON.parse(JSON.stringify(curConference?.participants || []))),
                                            })
                                        }
                                        break;
                                    case window.vmeeting.STATUS.MEMBER.INVITING:
                                        // 正在邀请
                                        if (orgMember?.status !== window.vmeeting.STATUS.MEMBER.INVITING) {
                                            this.roomListener({
                                                type: window.vmeeting.EVENT.SESSION.MEMBER,
                                                action: window.vmeeting.ACTION.MEMBER.INVITING,
                                                code: window.vmeeting.CODE.SUCCESS,
                                                member: __dataMembers([JSON.parse(JSON.stringify(curMember || {}))])[0],
                                                members: __dataMembers(JSON.parse(JSON.stringify(curConference?.participants || []))),
                                            })
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            })
                        }
                        this.room.conference = content;
                    }
                    break;
                }
                    
                case "screen": {
                    this.roomListener({
                        type: window.vmeeting.EVENT.SESSION.MEDIA,
                        action: window.vmeeting.ACTION.MEDIA.ONCHANGE,
                        code: window.vmeeting.CODE.SUCCESS,
                        mediaOrg: JSON.parse(JSON.stringify(content || {})),
                    })
                    this.room.media = content;
                    break;
                }
                default:
                    break;
            }
        })
    }

    createRoom(members) {
        this.createMembers = members;
        this.actionMembers = [];
        this.view = {}
        this.destroyRoom(false)
        let loop = () => {
            if (this.status === 1) {
                this.status = 2;
                this.vmeetingsdk && this.vmeetingsdk.createRoom({
                    subject: `音视频调度 ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
                    autoViewDisable: true,
                    ghost: true,
                });
            } else {
                this.createRoomTimer = setTimeout(() => {
                    loop()
                }, 1000)
            }
        }

        loop()
    }

    destroyRoom() {
        this.vmeetingsdk && this.vmeetingsdk.destroyRoom();
        clearTimeout(this.createRoomTimer);
    }

    joinRoom(accessCode) {
        this.actionMembers = [];
        this.vmeetingsdk && this.vmeetingsdk.joinRoom({
            accessCode: accessCode,
        });
    }

    addressBook(pag, onOk, onError) {
        this.vmeetingsdk && this.vmeetingsdk.addressBook({
            pag: pag,
            onOk2: onOk,
            onError: onError,
        });
    }

    inviteMember(members) {
        let memberSipNums = members?.map(m => m.SipNum) || [];
        this.vmeetingsdk && this.vmeetingsdk.inviteMember({
            userIds: memberSipNums
        })
    }

    kickMember(members, onOk, onError) {
        this.vmeetingsdk && this.vmeetingsdk.kickMember({
            userIds: members.map(m => m.SipNum)
        })
    }

    viewMember(member, streamId, onOk, onError) {
        let media = Object.assign([], this.view?.media || []);
        let ssrc = Object.assign([], this.view?.ssrc || []);
        let views = [];

        let mediaIndex = media.findIndex(userId => userId === member.SipNum)
        let ssrcIndex = ssrc.findIndex(ss => ss === streamId)
        if (mediaIndex !== -1) {
            // 这个成员在选看框中
            if (ssrcIndex !== -1) {
                if (mediaIndex === ssrcIndex) {
                    return;
                }
                media[mediaIndex] = media[ssrcIndex];
                media[ssrcIndex] = member.SipNum;
            } else {
                ssrc[mediaIndex] = streamId;
            }
        } else {
            // 这个成员不在选看框中
            if (ssrcIndex !== -1) {
                media[ssrcIndex] = member.SipNum;
            } else {
                ssrc.push(streamId);
                media.push(member.SipNum);
            }
        }

        ssrc.forEach((ss, index) => {
            views.push({
                streamId: ss,
                userId: media[index],
                definition: 4,
            })
        })
        
        this.vmeetingsdk && this.vmeetingsdk.viewMediaMember({
            userId: this.profile.userId,
            medias: views
        })
    }

    refreshView(index) {
        if (0 <= index && index <= this.maxViewNum) {
            this.vmeetingsdk && this.vmeetingsdk.rebindRemoteVideo(`html_video_remote${index}`, index);
        }
    }

    bindView(index) {
        this.vmeetingsdk && this.vmeetingsdk.bindRemoteVideo(`html_video_remote${index}`, index);
    }

    unbindView(index) {
        this.vmeetingsdk && this.vmeetingsdk.unbindRemoteVideo(`html_video_remote${index}`, index);
    }

    mkVideo() {
        let ms = {};
        Array.from({ length: this.maxViewNum }).forEach((_, streamId) => {
            let userId = this.view?.media?.[this.view?.ssrc?.findIndex(ss => ss === streamId)];
            ms[streamId] = this.members?.find(m => m.userId === userId && m.userId !== this.profile?.userId && userId !== undefined);
        })

        let showHidden = (index) => {
            let ret = ms[index]
            return ret ? "hidden" : "show" 
        }

        let noStreamTips = (index) => {
            return <div className={`no-stream-tips ` + showHidden(index)} >
                <div className="icons">
                    <IconFont type="icon-forbidden" className="icon-forbidden"/>
                    <IconFont type="icon-camera-off-1" className="icon-camera-off"/>
                </div>
                <span className="description">暂无视频预览</span>
            </div>
        }

        return <>
            <div className={`videos`}>
                <div className={`videos-item`}>
                    <div className={`nickname`}>{ms[0]?.nickName}</div>
                    {noStreamTips(0)}
                    <video id={`html_video_remote${0}`} className={`preview-video-${0} `} autoPlay muted></video>
                </div>
            </div>
        </>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = () => {
            return;
        };
        this.uninit()
    }

    componentDidMount() {
        this.init();
    }

    render() {
        let { reason } = this.state;
        let { className } = this.props;
        return <div className={className}>
            {this.mkVideo()}
            <audio id="html_audio_remote0" autoPlay></audio>
            <div className="error">{reason}</div>
        </div>
    }
}

export default Conference;