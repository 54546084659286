/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import AnimatedRouter from 'react-animated-router';
import { getLoginUserInfo } from '../pages/login/loginRD';
import { WaterMark } from '@ant-design/pro-layout';

import TestPage from '../pages/test/test';
import SituationPage from '../pages/task/situation/situation';
import SummaryPage from '../pages/task/summary/summary';
import StaffPage from '../pages/staff/staff';
import StaffDetailPage from '../pages/staff/detail';
import OrganizationPage from '../pages/organization/organization';
import RolePage from '../pages/role/role';

import DapingPage from '../pages/config/daping/daping';

import CameraPage from '../pages/camera/camera';
import TerminalPage from '../pages/terminal/terminal';
import PhonePage from '../pages/phone/phone';
import AlarmPage from '../pages/alarm/alarm';

import DefaultPage from '../pages/page';

class MainRoute extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let { reqUserInfo } = this.props;
        return (
            <WaterMark
                // fontColor='rgba(0,0,0,.03)'
                // content="应急指挥全流程业务平台"
                markClassName="water-mark"
                gapX={120}
                gapY={120}
                // height={61}
                // width={222}
                // image="/water_mark.png"
                height={80}
                width={80}
                image="/logo.png"
            >
                <Switch>
                    <Route path={`${this.props.match.path}/test`} component={TestPage} />
                    <Route path={`${this.props.match.path}/task/summary`} component={SummaryPage} />

                    
                    <Route path={`${this.props.match.path}/user/staff/:id`} component={StaffDetailPage} />
                    <Route path={`${this.props.match.path}/user/staff`} component={StaffPage} />
                    <Route path={`${this.props.match.path}/user/role`} component={RolePage} />
                    <Route path={`${this.props.match.path}/user/organization`} component={OrganizationPage} />
                    
                    <Route path={`${this.props.match.path}/config/daping`} component={DapingPage} />

                    <Route path={`${this.props.match.path}/asset/camera`} component={CameraPage} />
                    <Route path={`${this.props.match.path}/asset/terminal`} component={TerminalPage} />
                    <Route path={`${this.props.match.path}/asset/phone`} component={PhonePage} />
                    <Route path={`${this.props.match.path}/asset/alarm`} component={AlarmPage} />
                    
                    <Route path={`${this.props.match.path}`} component={DefaultPage} />
                </Switch>
            </WaterMark>
        )
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});

export default connect(
    mapState, 
    null
)(MainRoute);