/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts';
import { Button, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import ProForm, { ModalForm, ProFormText, ProFormList, ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import Editable from './editable';
import { getUrlParam, hexToRgba, rgbaToHex } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { CaretDownOutlined } from '@ant-design/icons';

import './component.less'

class CardGauge extends Component{

    constructor(props){
        super(props);

        let defaultOption = {
            title: {
                show: true,
                text: '卡片主标题',
                textStyle: {
                    fontSize: 18,
                    color: '#fff',
                    fontWeight: 'normal',
                },
                subtext: '卡片副标题',
                subtextStyle: {
                    fontSize: 14,
                    color: '#689ad3',
                },
                padding: [2, 20],
            },
            series: [{
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                radius: '60%',
                center: ['50%', '60%'],
                pointer: {
                    show: false
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: false,
                    clip: false,
                    itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                    }
                },
                axisLine: {
                    show: true,
                    roundCap: false,
                    lineStyle: {
                        width: 8
                    }
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    distance: 50
                },
                data: [{
                    value: 20,
                    title: {
                        offsetCenter: ['0%', '0%']
                    },
                    detail: {
                        offsetCenter: ['0%', '0%'],
                        fontSize: 22,
                        fontWeight: 'normal',
                    }
                }],
                title: {
                    fontSize: 14,
                },
                detail: {
                    formatter: '20%',
                },
            }],
            backgroundColor: 'transparent',
        }

        let defaultInd = {
            label1: "指标1描述",
            value1: "指标1值",
            value1Style: {
                color: '#c04245'
            },
            label2: "指标2描述",
            value2: "指标2值",
            value2Style: {
                color: '#43ba23'
            },
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
            defaultInd: defaultInd,
            ind: defaultInd,
        }
    }

    onRefresh(e) {
        let { number } = this.props;
        let key = `card${number}`
        if (e?.type === key) {
            let { chart, defaultOption, defaultInd } = this.state;
            let { template } = this.props;

            async function init() {
                if (!chart) {
                    let dom = document.getElementById(`card${number}`);
                    chart = echarts.init(dom, 'dark');
                }
                let rsp1 = await apiDapingDataGet(template, `card${number}.option`);
                let option = rsp1?.Data || defaultOption;
                let rsp2 = await apiDapingDataGet(template, `card${number}.ind`);
                let ind = rsp2?.Data || defaultInd;
                chart.setOption(option);
                window.addEventListener("resize",function (){
                    setTimeout(() => {
                        chart.resize();
                    })
                });
                this.setState({
                    option: option,
                    ind: ind,
                    value1StyleTmpColor: hexToRgba(ind.value1Style.color),
                    value2StyleTmpColor: hexToRgba(ind.value2Style.color),
                    loading: false,
                    chart: chart,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(ind, option) {
        this.setState({
            value1StyleTmpColor: hexToRgba(ind.value1Style.color),
            value2StyleTmpColor: hexToRgba(ind.value2Style.color),
        }, () => {
            this.form.setFieldsValue({
                ...ind,
                value1StyleColor: ind.value1Style.color,
                value2StyleColor: ind.value2Style.color,
                title: option?.title?.text,
                subTitle: option?.title?.subtext,
                value: option.series[0].data[0].value,
                displayValue: option.series[0].detail.formatter,
            })
        })
    }

    mkEdit() {
        let { chart, option, ind, visible, value1StyleTmpColor, value2StyleTmpColor } = this.state;
        let { template, onUpdate, number } = this.props;
        return <ModalForm
            width="80%"
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(ind, option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                }
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption, defaultInd } = this.state;
                            this.initForm(defaultInd, defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                option.title.text = values.title;
                option.title.subtext = values.subTitle;
                option.series[0].data[0].value = values.value;
                option.series[0].detail.formatter = values.displayValue;
                let rsp1 = await apiDapingDataSet(template, `card${number}.option`, option);
                if (rsp1.Status === 0) {
                    let newInd = {
                        ...values,
                        value1Style: {
                            color: values.value1StyleColor,
                        },
                        value2Style: {
                            color: values.value2StyleColor,
                        },
                    }
                    let rsp2 = await apiDapingDataSet(template, `card${number}.ind`, newInd);
                    if (rsp2.Status === 0) {
                        this.setState({
                            ind: newInd,
                            option: option,
                            visible: false,
                        }, () => {
                            chart?.setOption && chart.setOption(option);
                            onUpdate && onUpdate(`card${number}`);
                        })
                    }
                }
                return true;
            }}
        >
            <ProCard split="vertical">
                <ProCard colSpan="30%">
                    <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
                    <ProFormText name="subTitle" label="副标题" placeholder="请输入副标题" />
                    <ProFormDigit name="value" label="百分比值" placeholder="请输入百分比值"/>
                    <ProFormText name="displayValue" label="显示" placeholder="请输入显示数据" />
                </ProCard>
                <ProCard colSpan="70%">
                    <ProForm.Group>
                        <ProFormText name="label1" label="指标1描述" placeholder="请输入描述" />
                        <ProFormText name="value1" label="指标1值" placeholder="请输入数据" />
                        <ProForm.Group size="small">
                            <ProFormText
                                name="value1StyleColor"
                                label="指标1值颜色"
                                placeholder="请输入数据"
                                allowClear={false}
                                fieldProps={{
                                    suffix: <Popover
                                        trigger="click"
                                        placement="bottom"
                                        content={<SketchPicker width="400px" color={value1StyleTmpColor} onChangeComplete={(e) => {
                                            this.setState({
                                                value1StyleTmpColor: e.rgb
                                            }, () => {
                                                this.form.setFieldsValue({
                                                    value1StyleColor: rgbaToHex(e.rgb),
                                                })
                                            })
                                        }}
                                        />}
                                    >
                                        <div className="color-pick-item">
                                            <div className="color-pick-item-block" style={{
                                                background: rgbaToHex(value1StyleTmpColor) || '#fff',
                                            }}/>
                                            <CaretDownOutlined className="color-pick-item-icon"/>
                                        </div>
                                    </Popover>,
                                }}
                            />
                        </ProForm.Group>
                    </ProForm.Group>
                    <ProForm.Group>
                        <ProFormText name="label2" label="指标2描述" placeholder="请输入描述" />
                        <ProFormText name="value2" label="指标2值" placeholder="请输入数据" />
                        <ProForm.Group size="small">
                            <ProFormText
                                name="value2StyleColor"
                                label="指标2值颜色"
                                placeholder="请输入数据"
                                allowClear={false}
                                fieldProps={{
                                    suffix: <Popover
                                        trigger="click"
                                        placement="bottom"
                                        content={<SketchPicker width="400px" color={value2StyleTmpColor} onChangeComplete={(e) => {
                                            this.setState({
                                                value2StyleTmpColor: e.rgb,
                                            }, () => {
                                                this.form.setFieldsValue({
                                                    value2StyleColor: rgbaToHex(e.rgb),
                                                })
                                            })

                                        }}
                                        />}
                                    >
                                        <div className="color-pick-item">
                                            <div className="color-pick-item-block" style={{
                                                background: rgbaToHex(value2StyleTmpColor) || '#fff',
                                            }}/>
                                            <CaretDownOutlined className="color-pick-item-icon"/>
                                        </div>
                                    </Popover>,
                                }}
                            />
                        </ProForm.Group>
                    </ProForm.Group>
                </ProCard>
            </ProCard>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let { number } = this.props;
        this.onRefresh({ action: "refresh", type: `card${number}` })
    }

    render() {
        let { editable, loading, chart, ind, defaultOption, defaultInd } = this.state;
        let { template, number } = this.props;
        return <>
            {this.mkEdit()}
            <div className="decorate" />
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div className="decorate-card-number">{`0${number}`}</div>
                <div className={`card${number}`}>
                    <div id={`card${number}`} className={`card${number}-chart`} />
                    <div className={`card${number}-summary`}>
                        <div className="summary-style-1">
                            <div className="summary-style-1-field1">
                                <div className="label">{ind.label1}</div>
                                <div className="value" style={ind.value1Style}>{ind.value1}</div>
                            </div>
                            <div className="summary-style-1-field2">
                                <div className="label">{ind.label2}</div>
                                <div className="value" style={ind.value2Style}>{ind.value2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Editable>
        </>
    }
}

export default CardGauge;

