/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Breadcrumb as AntdBreadcrumb, Button } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import './antd.less'

export function Page(props) {
    let breadcrumbs = props.breadcrumbs.slice(0, -1) || [];
    let titleItem = props.breadcrumbs[(props.breadcrumbs?.length || 1) - 1];

    return (
        <div className={"page-header " + props.className}>
            <PageContainer
                header={{
                    title: titleItem?.breadcrumbName,
                    ghost: true,
                    breadcrumbRender: () => {
                        return <AntdBreadcrumb>
                            <AntdBreadcrumb.Item key="main" >
                                <Button type="link" className="page-header-breadcrumb-item-link" onClick={(e) => window.goToMenu("")}>主页</Button>
                            </AntdBreadcrumb.Item>
                            {breadcrumbs.map((item, index) => {
                                if (item.breadcrumbName) {
                                    let content = <Button type="link" className="page-header-breadcrumb-item" style={{padding: 0}}>{item.breadcrumbName}</Button>
                                    if (item.path) {
                                        content = <Button type="link" className="page-header-breadcrumb-item-link" onClick={(e) => window.goToMenu(item.path)}>{item.breadcrumbName}</Button>
                                    }
                                    return <AntdBreadcrumb.Item key={index} {...item.props}>
                                        {content}
                                    </AntdBreadcrumb.Item>
                                }
                                return undefined;
                            })}
                        </AntdBreadcrumb>
                    },
                    breadcrumb: {
                        routes: breadcrumbs,
                    },
                    extra: <div className="page-header-extra">{props.extra}</div>,
                }}
            >
                <div className="page-body">
                    {props.children}
                </div>
            </PageContainer>
        </div>
    );
}

Page.defaultProps = {
    separator: "/",
    breadcrumbs: [],
    extra: undefined,
};