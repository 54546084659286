/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Row, Popconfirm, Space, Button, Calendar, Tooltip, Dropdown } from 'antd';
import { red, volcano, gold, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { ReloadOutlined, CheckOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import { pagization } from '../../api/api';
import { apiTerminalList } from '../../api/terminal';
import { PaginationPositionIcon, PicPreview } from '../antd/antd';
import { DEVICE } from './common';

class Terminal extends Component{
    constructor(props){
        super(props);
        const columns = [
            {
                title: '确认',
                type: 'str',
                width: 48,
                dataIndex: 'Id',
                search: false,
                render: (text, record) => this.props.selectedRowKeys?.indexOf(record.Id) !== -1 ? <CheckOutlined style={{color: green[5]}} /> : undefined
            },
            {
                title: '类型',
                type: 'intEnumMultiple',
                width: '10%',
                dataIndex: 'TypeId',
                fieldProps: {
                    mode: 'multiple',
                },
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(this.props.valid?.['TypeId']),
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend', 'descend'],
                sorter: (a, b) => a.TypeId - b.TypeId,
            },
            {
                title: '照片',
                type: 'str',
                width: '20%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => {
                    return <div className="terminal-avatar"><img alt="" src={text}/></div>
                }
            },
            {
                title: '名称',
                type: 'str',
                width: '20%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '20%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                width: '20%',
                key: 'option',
                valueType: 'option',
                render: (_, record) => {
                    if (this.props.selectedRowKeys?.indexOf(record.Id) === -1) {
                        return [<Button key={record.Id} type="link" className="nopadding" onClick={() => {
                            let { onAdd } = this.props;
                            onAdd && onAdd([record])
                        }}>添加</Button>]
                    } else {
                        return [<Button key={record.Id} type="link" className="nopadding" onClick={() => {
                            let { onDel } = this.props;
                            onDel && onDel([record])
                        }}>删除</Button>]
                    }
                },
            },
        ];

        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            paginationConfig: {
                position: ['bottomRight'],
                showQuickJumper: true,
            },
        }
    }

    mkPageBody() {
        let { columns, paginationData, paginationConfig } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={() => this.table.submit()}>刷新</Button>,
            <PaginationPositionIcon
                paginationConfig={paginationConfig}
                onChange={(key) => {
                    paginationConfig.position = [key]
                    this.setState({
                        paginationConfig: paginationConfig,
                    })
                }}
            />,
        ]

        return (
            <ProTable
                className="table-bind-terminal page"
                formRef={(ref) => this.table = ref}
                columns={columns}
                request={async (params, sorter, filter) => {
                    // 表单搜索项会从 pagination 传入，传递给后端接口。
                    console.group('request');
                    console.log("params:", params);
                    console.log("sorter:", sorter);
                    console.log("filter:", filter);
                    console.groupEnd();
                    let rsp = await apiTerminalList(pagization(Object.assign(params, ), sorter, filter, columns, this.props.valid))
                    if (rsp.Status === 0) {
                        this.setState({
                            paginationData: {
                                current: params?.current,
                                pageSize: params?.pageSize,
                                total: rsp?.TotalNum,
                            },
                        })
                    }
                    return {
                        data: rsp?.RecordList || [],
                        total: rsp?.TotalNum,
                        success: true,
                    };
                }}
                rowSelection={{
                    getCheckboxProps: (record) => ({
                        Id: record.Id,
                    }),
                    alwaysShowAlert: true,
                }}
                tableAlertRender={({selectedRowKeys}) => {
                    return <Space size="small" style={{height: '32px'}}>
                        <span>已确认</span>
                        <span style={{ color: red[5] }}>{this.props.selectedRowKeys?.length || 0}</span>
                        <span >项，</span>
                        <span>待选择</span>
                        <span style={{ color: red[5] }}>{selectedRowKeys?.length || 0}</span>
                        <span>项</span>
                    </Space >
                }}
                tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => {
                    return ( selectedRowKeys?.length ?
                        <Space size={16}>
                            <Button className="nopadding" type="link" onClick={(e) => {
                                let { onAdd } = this.props;
                                onAdd && onAdd(selectedRows)
                                onCleanSelected(e)
                            }}>批量添加</Button>
                            <Button className="nopadding" type="link" onClick={(e) => {
                                let { onDel } = this.props;
                                onDel && onDel(selectedRows)
                                onCleanSelected(e)
                            }}>批量删除</Button>
                            {/* <Button className="nopadding" type="link" onClick={onCleanSelected}>取消选择</Button> */}
                        </Space> : undefined
                    );
                }}
                rowKey="Id"
                rowClassName={(record) => this.props.selectedRowKeys?.indexOf(record.Id) !== -1 ? "table-row-selected" : ""}
                pagination={{
                    ...paginationConfig,
                    ...paginationData,
                }}
                search={{ filterType: "light" }}
                dateFormatter="string"
                headerTitle={`设备列表`}
                options={{
                    density: true,
                    setting: true,
                    reload: false,
                    fullScreen: false,
                    search: false,
                }}
                toolBarRender={() => operations}
            />
        )
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
    }

    render(){
        return (<div>
            {this.mkPageBody()}
        </div>)
    }
}

export default Terminal;

