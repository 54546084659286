/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Popconfirm, Space, Button, Calendar, Tooltip, Dropdown } from 'antd';
import { PlusOutlined, PushpinOutlined, StarFilled, ReloadOutlined, SettingOutlined, FullscreenOutlined, DownOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import { StatisticCard } from '@ant-design/pro-card';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import { Page } from '../../component/antd/page';
import { ProTableHelper, PaginationPositionIcon } from '../../component/antd/antd';
import { menus } from '../../laylout/menu';
import { getMenus } from '../../util/logic';
import { getLoginUserInfo } from '../login/loginRD';
import { pagization } from '../../api/api';
import { apiRoleList, apiRoleAdd, apiRoleDel, apiRoleMod } from '../../api/role';
import { message } from '../../util/message';

import './role.less';

class Role extends Component{

    constructor(props){
        super(props);

        const columns = [
            {
                title: '名称',
                type: 'str',
                width: 180,
                dataIndex: 'Name',
                // search: true,
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '备注',
                type: 'str',
                width: 140,
                dataIndex: 'Remark',
                ellipsis: true,
                // search: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '操作',
                width: 180,
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let options = [];
                    options.push(this.mkEdit(record))
                    options.push(<Popconfirm
                        key={record.Id}
                        title={`是否删除角色${record?.Name}？`}
                        onConfirm={async () => {
                            let rsp = await apiRoleDel(record);
                            if (rsp.Status === 0) {
                                message.success('删除角色成功');
                                this.tableAction.reload()
                                return true;
                            }
                        }}
                        okText="确定"
                        cancelText="我再想想"
                    >
                        <Button key="delete" type="link" className="nopadding">删除</Button>
                    </Popconfirm>)
                    return options;
                },
            },
        ];
        let proTableHelper = new ProTableHelper({key: "role"})
        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            ...proTableHelper.pagination.init(),
            ...proTableHelper.columnsStateMap.init(),
        }
        this.proTableHelper = proTableHelper;
    }

    mkPageHeaderExtra() {
        let { paginationData } = this.state;
        return <StatisticCard.Group>
            <StatisticCard statistic={{ title: '总计', value: paginationData?.total }} />
        </StatisticCard.Group>
    }

    mkForm(operation) {
        return <>
            <ProFormText
                width="lg"
                name="Name"
                label="名称"
                placeholder="请输入名称"
                rules={[{ required: true, message: '请输入名称' }]}
            />
            <ProFormTextArea
                width="lg"
                name="Remark"
                label="备注"
                placeholder="请输入备注"
            />
        </>
    }

    mkEdit(record) {
        return <ModalForm
            key={record.Id}
            formRef={ref => record.editForm = ref}
            title="编辑角色"
            labelwidth="auto"
            trigger={<Button key="edit" type="link" className="nopadding">编辑</Button>}
            onVisibleChange={(visible) => {
                if (visible) {
                    record.editForm.setFieldsValue(record)
                }
            }}
            onFinish={async (values) => {
                let rsp = await apiRoleMod(Object.assign(record, values));
                if (rsp.Status === 0) {
                    message.success('编辑角色成功');
                    this.tableAction.reload()
                    return true;
                }
                return false;
            }}
        >
            {this.mkForm('edit')}
        </ModalForm>
    }

    mkNew() {
        return <ModalForm
            formRef={ref => this.newForm = ref}
            title="添加角色"
            labelwidth="auto"
            trigger={<Button key="add" type="primary" icon={<PlusOutlined />}>添加</Button>}
            onVisibleChange={(visible) => {
                if (visible) {
                    this.newForm.setFieldsValue({
                        Name: '',
                        Remark: '',
                    })
                }
            }}
            onFinish={async (values) => {
                let rsp = await apiRoleAdd(values);
                if (rsp.Status === 0) {
                    message.success('添加角色成功');
                    this.tableAction.reload()
                    return true;
                }
                return false;
            }}
        >
            {this.mkForm('new')}
        </ModalForm>
    }

    mkPageBody() {
        let { columns, data, paginationData } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={() => this.tableAction.reload()}>刷新</Button>,
            this.mkNew(),
            // <Button key="export" type="primary" icon={<DownloadOutlined/>}>导出</Button>,
            this.proTableHelper.pagination.button(this),
        ]
        return (
            <div className="role-page-body">
                <div className="role-page-body-table-col">
                    <ProTable
                        formRef={(ref) => this.table = ref}
                        actionRef={(ref) => this.tableAction = ref}
                        headerTitle="角色列表"
                        rowKey="Id"
                        columns={columns}
                        request={async (params, sorter, filter) => {
                            // 表单搜索项会从 pagination 传入，传递给后端接口。
                            console.group('request role list');
                            console.log("params:", params);
                            console.log("sorter:", sorter);
                            console.log("filter:", filter);
                            console.groupEnd();
                            
                            let rsp = await apiRoleList(pagization(params, sorter, filter, columns), { ignoreError: true })
                            if (rsp.Status === 0) {
                                this.setState({
                                    paginationData: {
                                        current: params?.current,
                                        pageSize: params?.pageSize,
                                        total: rsp?.TotalNum,
                                    },
                                })
                            }
                            return {
                                data: rsp?.RecordList || [],
                                total: rsp?.TotalNum,
                                success: true,
                            };
                        }}
                        {...this.proTableHelper.props(this)}
                        {...this.proTableHelper.rowSelection.props(this)}
                        {...this.proTableHelper.columnsStateMap.props(this)}
                        toolBarRender={() => operations}
                        pagination={{
                            ...this.proTableHelper.pagination.props(this),
                            ...paginationData,
                        }}
                    />
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="role-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
            // calendarSelectedDate: undefined,
        })
        window.role = this;
    }

    render(){
        return (<div>
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Role);

