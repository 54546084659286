/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import './test.less';

class Test extends Component{

    componentWillUnmount(){
    }

    componentDidMount(){
    }

    render(){
        return null;
    }
}

const mapState = (state) => ({

});


export default connect(
    mapState, 
    null
)(Test);

