/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { StatisticCard } from '@ant-design/pro-card';
import { Button, Space } from 'antd';
import { red } from '@ant-design/colors';
import { Page } from '../../../component/antd/page';
import { menus } from '../../../laylout/menu';
import { getMenus } from '../../../util/logic';
import { getLoginUserInfo } from '../../login/loginRD';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { message } from '../../../util/message';
import { version } from '../../../util/version';
import { template } from '../../../component/common/dapingTemplate';

import './daping.less';

class Daping extends Component{

    constructor(props){
        super(props);

        this.state = {
            
        }
    }

    refreshOnClick() {
        async function api() {
            let rsp = await apiDapingDataGet("daping.select", "0", )
            let data = rsp?.Data || {
                selected: 'default',
            }
            this.setState({
                data: data,
            })
        }

        api.bind(this)()
    }

    mkPageHeaderExtra() {
        let { data } = this.state;
        return <StatisticCard.Group>
            <StatisticCard statistic={{ title: '已选模板', value: template.list.find(t => t.template === data?.selected)?.name || template.list[0].name}} />
            <StatisticCard statistic={{ title: '模板总数', value: template.list.length }} />
        </StatisticCard.Group>
    }

    mkPageBody() {
        let { data } = this.state;
        return <div className="config-daping-body">
            <div className="config-daping-body-list">
                {
                    template.list?.map(temp => {
                        return <div key={temp.template} className={"config-daping-body-list-item " + (data?.selected === temp.template ? "select" : "")}>
                            <img className="config-daping-body-list-item-image" alt="" src={temp.image} />
                            <div className="config-daping-body-list-item-operation">
                                <div className="item title">{temp.name}</div>
                                <Space className="item">
                                    <Button
                                        type="primary"
                                        disabled={data?.selected === temp.template}
                                        onClick={async () => {
                                            let data = { selected: temp.template }
                                            let rsp = await apiDapingDataSet("daping.select", "0", data);
                                            if (rsp.Status === 0) {
                                                this.setState({
                                                    data: data,
                                                })
                                                message.success(<span>设置<span style={{color: red[5]}}>{temp.name}</span>为默认模板成功</span>)
                                            }
                                        }}
                                    >选为默认</Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            window.goToMenu(`/ccs/daping?editable=1&template=${temp.template}&env=${version.environment}`, "", undefined, false, true)
                                        }}
                                    >编辑</Button>
                                </Space>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>;
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="daping-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
        })
        this.refreshOnClick()
    }

    render(){
        return (<div>
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Daping);

