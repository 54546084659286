/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { Button, Space, Spin } from 'antd';
import { randomString } from '../../../util/logic';

import './component.less';

class Editable extends Component{

    constructor(props){
        super(props);

        this.state = {
            id: randomString()
        }
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let { id } = this.state;
        function resize() {
            let dom = document.getElementById(id);
            if (dom) {
                this.setState({
                    width: dom.offsetWidth,
                    height: dom.offsetHeight,
                })
            }
        }

        window.addEventListener("resize",function (){
            resize.bind(this)()
        }.bind(this));
        resize.bind(this)()
    }

    render() {
        let { id, width, height } = this.state;
        let { loading, editable, children, editOnClick, initOnClick } = this.props;
        if (!editable) {
            return children;
        }
        return <>
            {children}
            <div id={id} className={"editable"} >
                <div className="extra">
                    <div>{width} * {height}</div>
                </div>
                <Space>
                    <Button type="primary" onClick={editOnClick}>编辑</Button>
                </Space>
            </div>
        </>
    }
}

export default Editable;

