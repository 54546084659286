/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Popconfirm, Space, Button, Calendar, Tooltip, Dropdown } from 'antd';
import { PlusOutlined, PushpinOutlined, StarFilled, ReloadOutlined, SettingOutlined, FullscreenOutlined, DownOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import { StatisticCard } from '@ant-design/pro-card';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import { pagization } from '../../api/api';
import { apiStaffDel, apiStaffMod } from '../../api/staff';
import { apiRoleList } from '../../api/role';
import { Page } from '../../component/antd/page';
import { menus } from '../../laylout/menu';
import { getMenus } from '../../util/logic';
import { getLoginUserInfo } from '../login/loginRD';

import './staff.less';

class Detail extends Component{

    constructor(props){
        super(props);

        this.state = {
            data: undefined,
        }
    }

    mkPageHeaderExtra() {
        let { paginationData } = this.state;
        return <StatisticCard.Group>
            <StatisticCard statistic={{ title: '总计', value: paginationData?.total }} />
        </StatisticCard.Group>
    }

    mkPageBody() {
        return (
            <div className="staff-page-body">
                <div className="staff-page-body-table-col">
                    
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="staff-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
        })
    }

    render(){
        return (<div>
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Detail);

