/* eslint-disable no-unused-vars */
import React from 'react';
import { ModalForm } from '@ant-design/pro-form';
import { Button } from 'antd';
import Map from 'component/positionMap/map';
    
export function DevicePosition(props) {
    return <ModalForm
        title="设备位置"
        labelwidth="auto"
        width="80%"
        trigger={<Button key="edit" type="link" style={{ marginTop: '6px', padding: 0 }}>查看地图</Button>}
        onFinish={async (values) => {
            return true;
        }}
        modalProps={{
            destroyOnClose: true,
        }}
        submitter={false}
    >
        <Map {...props}/>
    </ModalForm>
}