/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Input, Space, Menu, Tooltip, Dropdown, Modal, Upload } from 'antd';
import { PushpinOutlined } from '@ant-design/icons';

class Pagination {
    constructor(props) {
        this.__key = props?.key
        this.stateKey = `${this.__key}paginationConfig`
        this.localStorageKey = `profile.${this.__key}.paginationConfig`
    }

    init() {
        let value = window.localStorage.getItem(this.localStorageKey)
        let r = value ? JSON.parse(value) : undefined;
        return {
            [this.stateKey]: {
                position: ['bottomRight'],
                showQuickJumper: true,
                ...r,
            },
        }
    }

    props(inst) {
        return inst.state?.[this.stateKey]
    }

    button(inst) {
        return <Tooltip title="分页器位置">
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu
                        selectedKeys={inst.state?.[this.stateKey]?.position}
                        onClick={({ key }) => {
                            let paginationConfig = inst.state?.[this.stateKey]
                            paginationConfig.position = [key]
                            inst.setState({
                                [this.stateKey]: paginationConfig,
                            }, () => {
                                window.localStorage.setItem(this.localStorageKey, JSON.stringify(paginationConfig))
                            })
                        }}
                    >
                        <Menu.Item key="topRight"><span>顶部</span></Menu.Item>
                        <Menu.Item key="bottomRight"><span>底部</span></Menu.Item>
                    </Menu>
                }
            >
                <PushpinOutlined className="table-header-operation-icon" />
            </Dropdown>
        </Tooltip>
    }
}


class ColumnsStateMap {
    constructor(props) {
        this.__key = props?.key
        this.stateKey = `${this.__key}columnsStateMap`
        this.localStorageKey = `profile.${this.__key}.columnsStateMap`
    }

    init() {
        let value = window.localStorage.getItem(this.localStorageKey)
        let r = value ? JSON.parse(value) : undefined
        return {
            [this.stateKey]: r,
        }
    }

    props(inst) {
        return {
            columnsStateMap: inst.state[this.stateKey],
            onColumnsStateChange: (m) => {
                inst.setState({
                    [this.stateKey]: m,
                }, () => {
                    window.localStorage.setItem(this.localStorageKey, JSON.stringify(m))
                })
            }
        }
    }
}

class RowSelection {
    constructor(props) {

    }

    props(inst) {
        return {
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    Id: record.Id,
                }),
            },
            tableAlertOptionRender: ({ selectedRowKeys, selectedRows, onCleanSelected }) => {
                return (
                    <Space size={16}>
                        <Button className="nopadding" type="link">批量删除</Button>
                        <Button className="nopadding" type="link">导出数据</Button>
                        <Button className="nopadding" type="link" onClick={onCleanSelected}>取消选择</Button>
                    </Space>
                );
            }
        }
    }
}

export class ProTableHelper {
    constructor(props) {
        this.__key = props?.key
        this.columnsStateMap = new ColumnsStateMap(props)
        this.pagination = new Pagination(props)
        this.rowSelection = new RowSelection(props)
    }

    props(inst) {
        return {
            search: { filterType: "light" },
            dateFormatter: "string",
            options: {
                density: true,
                setting: true,
                reload: false,
                fullScreen: false,
                search: false,
            }
        }
    }
}