/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import ProForm, { ModalForm, ProFormText } from '@ant-design/pro-form';
import Editable from './editable';
import { getUrlParam } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { Button } from 'antd';

const DATA_KEY = "statistic"

class Statistic extends Component{

    constructor(props){
        super(props);

        let defaultOption = {
            item1: {
                label: "综合指标1",
                value: "666",
                unit: "元",
            },
            item2: {
                label: "综合指标2",
                value: "888",
                unit: "元",
            }
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        let { number } = this.props;
        let key = `${DATA_KEY}${number}`;
        if (e?.type === key) {
            let { defaultOption } = this.state;
            let { template } = this.props;

            async function init() {
                let rsp = await apiDapingDataGet(template, key);
                let option = rsp?.Data || defaultOption;
                this.setState({
                    option: option,
                    loading: false,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(option) {
        this.form.setFieldsValue({
            item1Label: option.item1.label,
            item1Value: option.item1.value,
            item1Unit: option.item1.unit,
            item2Label: option.item2.label,
            item2Value: option.item2.value,
            item2Unit: option.item2.unit,
        })
    }

    mkEdit() {
        let { option, visible } = this.state;
        let { template, onUpdate, number } = this.props;
        return <ModalForm
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                let option = {
                    item1: {
                        label: values.item1Label,
                        value: values.item1Value,
                        unit: values.item1Unit,
                    },
                    item2: {
                        label: values.item2Label,
                        value: values.item2Value,
                        unit: values.item2Unit,
                    }
                }
                let key = `${DATA_KEY}${number}`;
                let rsp = await apiDapingDataSet(template, key, option);
                if (rsp.Status === 0) {
                    this.setState({
                        option: option,
                        visible: false,
                    }, () => {
                        onUpdate && onUpdate(key);
                    })
                }
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText name="item1Label" label="指标1描述" placeholder="请输入描述" />
                <ProFormText name="item1Value" label="指标1值" placeholder="请输入值" />
                <ProFormText name="item1Unit" label="指标1单位" placeholder="请输入单位" />
            </ProForm.Group>
            <ProForm.Group>
                <ProFormText name="item2Label" label="指标1描述" placeholder="请输入描述" />
                <ProFormText name="item2Value" label="指标1值" placeholder="请输入值" />
                <ProFormText name="item2Unit" label="指标1单位" placeholder="请输入单位" />
            </ProForm.Group>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let { number } = this.props;
        this.onRefresh({ action: "refresh", type: `${DATA_KEY}${number}` })
    }

    render() {
        let { option, editable, loading } = this.state;
        return <>
            {this.mkEdit()}
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div className="indicator-col-row">
                    <div className="label">{option?.item1?.label}</div>
                    <div className="value">{option?.item1?.value}<span className="unit">{option?.item1?.unit}</span></div>
                </div>
                <div className="indicator-col-row">
                    <div className="label">{option?.item2?.label}</div>
                    <div className="value">{option?.item2?.value}<span className="unit">{option?.item2?.unit}</span></div>
                </div>
            </Editable>
        </>
    }
}

export default Statistic;

