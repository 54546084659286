/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import ProForm, { ModalForm, ProFormText } from '@ant-design/pro-form';
import Editable from './editable';
import { getUrlParam, randomString } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { Upload, Button } from 'antd';
import { PicPreview } from '../../../component/antd/antd';
import { config } from '../../../util/version';
import { message } from '../../../util/message';

const DATA_KEY = "logo"

class Logo extends Component{

    constructor(props){
        super(props);

        let defaultOption = {
            url: "/logo.png" 
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        if (e?.type === DATA_KEY) {
            let { defaultOption } = this.state;
            let { template } = this.props;

            async function init() {
                let rsp = await apiDapingDataGet(template, DATA_KEY);
                let option = rsp?.Data || defaultOption;
                this.setState({
                    option: option,
                    loading: false,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(option) {
        let file = PicPreview.newFile(option?.url);
        this.setState({
            avatarFile: file,
        }, () => {
            this.form.setFieldsValue({
                url: [{ url: option?.url }]
            })
        })
    }

    mkEdit() {
        let { option, visible, avatarFile } = this.state;
        let { template, onUpdate } = this.props;
        return <ModalForm
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                console.log(values);
                let rsp = await apiDapingDataSet(template, DATA_KEY, Object.assign(values, { url: values.url?.[0]?.url }));
                if (rsp.Status === 0) {
                    this.setState({
                        option: values,
                        visible: false,
                    }, () => {
                        onUpdate && onUpdate(DATA_KEY);
                    })
                }
                return true;
            }}
        >
            {PicPreview.ProFormUploadButton.bind(this)(avatarFile, {label: "徽标", name: "url"})}
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.onRefresh({ action: "refresh", type: DATA_KEY })
    }

    render() {
        let { option, editable, loading } = this.state;
        return <>
            {<PicPreview {...PicPreview.props.bind(this)(this.state)}/>}
            {this.mkEdit()}
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <img className="daping-logo" alt="" src={option?.url} />
            </Editable>
        </>
    }
}

export default Logo;

