
import { api } from './api';

export let apiTerminalList = async (data, extra) => {
    return await api("/ccs/v1/operator/device/list", data, extra)
}

export let apiTerminalAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/device/add", data, extra)
}

export let apiTerminalMod = async (data, extra) => {
    return await api("/ccs/v1/operator/device/mod", data, extra)
}

export let apiTerminalDel = async (data, extra) => {
    return await api("/ccs/v1/operator/device/del", data, extra)
}

export let apiTerminalSetLocation = async (data, extra) => {
    return await api("/ccs/v1/operator/device/setLocation", data, extra)
}

export let apiTerminalMessage = async (data, extra) => {
    return await api("/ccs/v1/operator/device/sendNotify", data, extra)
}


