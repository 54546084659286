/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { ModalForm } from '@ant-design/pro-form';
import ProTable from '@ant-design/pro-table';
import { Button } from 'antd';
import { DEVICE } from './common';
import { apiDeviceList } from '../../api/device';
import { pagization } from '../../api/api';

export function DeviceList(props) {
    return <ModalForm
        formRef={ref => props.instance.bindForm = ref}
        title="绑定设备"
        labelwidth="auto"
        width="80%"
        trigger={<Button key="add" type="link" style={{marginTop: '6px', padding: 0}}>查看已有设备</Button>}
        onFinish={async (values) => {
            return true;
        }}
        submitter={false}
    >
        <ProTable
            className="table-bind-device"
            columns={props.deviceColumns}
            request={async (params, sorter, filter) => {
                // 表单搜索项会从 pagination 传入，传递给后端接口。
                console.group('device request');
                console.log("params:", params);
                console.log("sorter:", sorter);
                console.log("filter:", filter);
                console.groupEnd();
                let rsp = await apiDeviceList(pagization(params, sorter, filter, props.deviceColumns))
                if (rsp.Status === 0) {
                    props.instance.setState({
                        paginationDeviceData: {
                            current: params?.current,
                            pageSize: params?.pageSize,
                            total: rsp?.TotalNum,
                        },
                    })
                }
                return {
                    data: rsp?.RecordList || rsp?.UserList || [],
                    total: rsp?.TotalNum,
                    success: true,
                };
            }}
            rowKey="SipNum"
            pagination={{
                ...props.paginationDeviceData,
            }}
            search={{ filterType: "light" }}
            dateFormatter="string"
            headerTitle=""
            options={{
                density: true,
                setting: true,
                reload: false,
                fullScreen: false,
                search: false,
            }}
        />
    </ModalForm>
}

DeviceList.props = function (state) {
    return {
        paginationDeviceData: state?.paginationDeviceData,
        deviceColumns: state?.deviceColumns,
    }
}

DeviceList.columns = function () {
    return [
        {
            title: '状态',
            type: 'strEnumMultiple',
            width: 56,
            dataIndex: 'State',
            search: false,
            valueType: 'select',
            valueEnum: DEVICE.ORGSTATE.valueEnum(),
            render: (_, record) => DEVICE.ORGSTATE.columnRender(record)
        },
        {
            title: '类型',
            type: 'strEnumMultiple',
            width: '10%',
            dataIndex: 'DeviceType',
            fieldProps: {
                mode: 'multiple',
            },
            valueType: 'select',
            valueEnum: DEVICE.ORGTYPE.valueEnum(this.props.valid?.['DeviceType']),
        },
        {
            title: '名称',
            type: 'str',
            width: '20%',
            dataIndex: 'NickName',
            ellipsis: true,
            fieldProps: {
                placeholder: "搜索"
            },
        },
        {
            title: '最近在线时间',
            type: 'dateToTime',
            width: '20%',
            dataIndex: 'OnlineTime',
            valueType: 'date',
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => moment(a.OnlineTime) - moment(b.OnlineTime),
            render: (text, record) => record.OnlineTime,
        },
        {
            title: 'SIP号',
            type: 'str',
            width: '20%',
            dataIndex: 'SipNum',
            ellipsis: true,
            fieldProps: {
                placeholder: "搜索"
            },
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            // fixed: 'right',
            render: (_, record) => {
                return [<Button key={record.SipNum} type="link" className="nopadding" onClick={() => {
                    this.newForm && this.newForm.setFieldsValue({
                        SipNum: record.SipNum,
                    })
                    record.editForm && record.editForm.setFieldsValue({
                        SipNum: record.SipNum,
                    })
                    this.bindForm && this.bindForm.submit()
                }}>绑定</Button>]
            },
        },
    ]
}