
import { api } from './api';

export let apiStaffList = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/list", data, extra)
}

export let apiStaffAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/add", data, extra)
}

export let apiStaffMod = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/mod", data, extra)
}

export let apiStaffDel = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/del", data, extra)
}

export let apiStaffSetLocation = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/setLocation", data, extra)
}

export let apiStaffRoleStatistic = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/role/count", data, extra)
}

export let apiStaffDeviceAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/addDevice", data, extra)
}

export let apiStaffDeviceDel = async (data, extra) => {
    return await api("/ccs/v1/operator/staff/delDevice", data, extra)
}

