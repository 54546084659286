/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { extend } from "umi-request";
import { message } from "../util/message";
import { config } from '../util/version';

// 定义一个请求模板
const request = extend({
    prefix: config.host.cs,
    timeout: 1000,
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
    },
    errorHandler: function(error) {
        return error.data;
    }
});

// 响应拦截器
request.interceptors.response.use(async (response, options) => {
    let data = {}
    try {
        data = await response.clone().json();
    } catch (error) {
        
    }
    // console.group('rsp');
    // console.log("response:", response);
    // console.log("options:", options);
    // console.log("data:", data);
    // console.groupEnd();
    if (data?.Status === 0) {
        // 成功
    } else if (response?.status === 401 || data?.Status === 100008 || data?.Status === 100009) {
        
    } else if (!options.ignoreError){
        // 界面报错
        if (data?.StatusMsg !== undefined && data?.Status !== undefined){
            message.error(`操作失败! 错误码: ${data?.Status} 错误提示: ${data?.StatusMsg}`);
        } else if (response.message === "Failed to fetch") {
            /* body是一个TypeError类型 */
            message.error(`操作失败! 错误提示: 服务端无响应`);
        } else {
            message.error(`操作失败! 错误提示: 服务端错误`);
        }
    }
    return response;
})

// 请求消息
export let api = async (uri, data, extra) => {
    let rsp = await request
        .post(uri, {
            prefix: config.host.cs,
            headers: {
                Token: localStorage.getItem('token'),
            },
            data: data,
            ...extra
        })
        .then(function(response) {
            return Promise.resolve(response)
        })
        .catch(function(error) {
            return Promise.reject(error)
        });
    
    if (rsp?.Status === 100008 || rsp?.Status === 100009) {
        // 重新刷新token
        let refreshTokenUrl = '/ccs/v1/operator/account/refresh/token';
        if (uri !== refreshTokenUrl) {
            let rsp2 = await request
                .post(refreshTokenUrl, {
                    data: {
                        RefreshToken: localStorage.getItem('refreshToken'),
                    },
                })
                .then(function(response) {
                    return Promise.resolve(response)
                })
                .catch(function(error) {
                    return Promise.reject(error)
                });
            if (rsp2?.Status === 0) {
                localStorage.setItem('token', rsp2?.Token);
                localStorage.setItem('refreshToken', rsp2?.RefreshToken);

                rsp = await request
                    .post(uri, {
                        headers: {
                            Token: rsp2?.Token,
                        },
                        data: data,
                        ...extra
                    })
                    .then(function(response) {
                        return Promise.resolve(response)
                    })
                    .catch(function(error) {
                        return Promise.reject(error)
                    });
            }
        }
    }
    
    return rsp || {};
}

export let pagization = (params, sorter, filter, columns, valid) => {
    
    // 分页信息
    let newPag = {
        PageNum: params?.current || 1,
        PageSize: params?.pageSize || 10,
        OrFirst: true,
    };

    for (let key in valid || {}) {
        if (!params[key]) {
            params[key] = valid[key]
        }
    }

    // 过滤条件
    let filters = [];
    for (let key in params || {}) {
        if (key !== 'pageSize' && key !== 'current') {
            let ret = columns.find(col => col.dataIndex === key || col.key === key)
            switch (ret?.type) {
                // 字符串
                case 'str':
                    filters.push([{
                        Key: key,
                        Type: "str",
                        Op: "like",
                        Value: params[key]
                    }])
                    break;
                // 数字
                case 'int':
                    filters.push([{
                        Key: key,
                        Type: "int",
                        Op: "=",
                        Value: params[key]
                    }])
                    break;
                // 单选字符串枚举
                case "strEnum":
                    if (!valid?.[key] || (valid?.[key] && valid?.[key].includes(params[key]))) {
                        filters.push([{
                            Key: key,
                            Type: "str",
                            Op: "=",
                            Value: params[key]
                        }])
                    }
                    break;
                // 多选字符串枚举
                case "strEnumMultiple": {
                    let ors = [];
                    if (params[key] instanceof Array) {
                        params[key].forEach(e => {
                            if (!valid?.[key] || (valid?.[key] && valid?.[key].includes(e))) {
                                ors.push({
                                    Key: key,
                                    Type: "str",
                                    Op: "=",
                                    Value: e
                                })
                            }
                        });
                        filters.push(ors);
                    }
                    break;
                }
                // 数值枚举
                case "intEnum": {
                    if (!valid?.[key] || (valid?.[key] && valid?.[key].includes(params[key]))) {
                        filters.push([{
                            Key: key,
                            Type: "int",
                            Op: "=",
                            Value: parseInt(params[key])
                        }]);
                    }
                    break;
                }
                // 多选数值枚举
                case "intEnumMultiple": {
                    let ors = [];
                    if (params[key] instanceof Array) {
                        params[key].forEach(e => {
                            let v = parseInt(e)
                            if (!valid?.[key] || (valid?.[key] && valid?.[key].includes(v))) {
                                ors.push({
                                    Key: key,
                                    Type: "int",
                                    Op: "=",
                                    Value: v
                                })
                            }
                        });
                        filters.push(ors);
                    }
                    break;
                }
                // 选择是日期展示是时间
                case "dateToTime": {
                    let ands = []
                    ands.push({
                        Key: key,
                        Type: "str",
                        Op: "like",
                        Value: `${params[key]}`
                    });
                    filters.push(ands);
                    break;
                }
                default:
                    console.group('config error');
                    console.error(`the data '${key}' needs filter but no type defined, please check your config`);
                    console.log("config:", ret);
                    console.groupEnd();
                    break;
            }
        }
    }
    newPag['Filters'] = filters;

    // 排序
    for (let key in (sorter || {})) {
        let ret = columns.find(col => col.dataIndex === key || col.key === key)
        if (ret) {
            newPag['OrderKey'] = key;
            newPag['Desc'] = sorter[key] === "descend" ? 1 : 0;
        }
    }
    return newPag;
}

export let search = (data, params, sorter, filter, columns) => {
    console.group("search");
    console.log("data", data);
    console.log("params", params);
    console.log("sorter", sorter);
    console.log("filter", filter);
    console.groupEnd();
    window.searchData = data;
    let newData = data;
    for (let key in params) {
        if (key !== 'pageSize' && key !== 'current') {
            console.group("search key");
            console.log("key", key);
            console.log("value", params[key]);
            let ret = columns.find(col => col.dataIndex === key || col.key === key)
            console.log("type", ret?.type);
            console.groupEnd();
            switch (ret?.type) {
                // 字符串
                case 'str':
                    newData = newData.filter(d => d[key]?.includes(params[key]))
                    break;
                case 'int':
                case "strEnum":
                case "intEnum":
                    newData = newData.filter(d => d[key] === params[key])
                    break;
                // 多选字符串枚举
                case "strEnumMultiple": 
                case "intEnumMultiple": {
                    if (params?.[key]?.length) {
                        newData = newData.filter(d => params[key].indexOf(`${d[key]}`) !== -1)
                    }
                    break;
                }
                // 选择是日期展示是时间
                case "dateToTime": {
                    break;
                }
                default:
                    console.group('config error');
                    console.error(`the data '${key}' needs filter but no type defined, please check your config`);
                    console.log("config:", ret);
                    console.groupEnd();
                    break;
            }
        }
    }
    return {
        data: newData,
        total: newData?.length || 0,
    };
}