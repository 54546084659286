/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts';
import { Button } from 'antd';
import ProForm, { ModalForm, ProFormText, ProFormList, ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import Editable from './editable';
import { getUrlParam } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';

const DATA_KEY = "rank";

class Rank extends Component{

    constructor(props){
        super(props);

        let data = [];
        let category = ['项A', '项B', '项C', '项D', '项E', '项F', '项G', '项H', '项I', '项J', '项K', '项L']
        for (let i = 0; i < category.length; ++i) {
            data.push({ name:category[i], value: Math.round(Math.random() * 200 + 1) });
        }
        let defaultOption = {
            title: {
                show: true,
                text: '业绩排名主标题',
                textStyle: {
                    fontSize: 18,
                    color: '#fff',
                    fontWeight: 'normal',
                },
                subtext: '业绩排名副标题',
                subtextStyle: {
                    fontSize: 14,
                    color: '#689ad3',
                },
                padding: [2, 20],
            },
            grid: { // 图部分的边距距离
                top: 60,
                bottom: 0,
                left: 60,
                right: 20,
            },
            xAxis: {
                max: 'dataMax',
                show: false,
            },
            yAxis: {
                type: 'category',
                data: data.map(d => d.name),
                inverse: true,
                animationDuration: 300,
                animationDurationUpdate: 300,
                axisTick: {
                    alignWithLabel: true
                },
            },
            series: [{
                realtimeSort: true,
                name: 'X',
                type: 'bar',
                data: data.map(d => d.value),
                label: {
                    show: false, // 不显示柱状条的上的值
                },
                barWidth: 15, // 柱状条的宽度
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 1, 0,
                        [
                            { offset: 0, color: '#564aa3' },
                            { offset: 0.5, color: '#564aa3' },
                            { offset: 1, color: '#29abdf' },
                        ]
                    )
                },
            }],
            legend: {
                show: false
            },
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear',
            backgroundColor: 'transparent',
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        if (e?.type === DATA_KEY) {
            let { chart, defaultOption } = this.state;
            let { template } = this.props;

            async function init() {
                let rsp = await apiDapingDataGet(template, DATA_KEY);
                let dom = document.getElementById('rank');
                if (!chart) {
                    chart = echarts.init(dom, 'dark');
                }
                let option = rsp?.Data || defaultOption;
                chart.setOption(option);
                window.addEventListener("resize",function (){
                    setTimeout(() => {
                        chart.resize();
                    })
                });
                this.setState({
                    option: option,
                    loading: false,
                    chart: chart,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(option) {
        let items = [];
        let names = option?.yAxis?.data || [];
        let values = option?.series?.[0]?.data || [];
        for (let i in names) {
            items.push({ name: names[i], value: values[i] })
        }
        this.form.setFieldsValue({
            title: option?.title?.text,
            subTitle: option?.title?.subtext,
            items: items,
            barWidth: option?.series?.[0]?.barWidth,
        })
    }

    mkEdit() {
        let { chart, option, visible } = this.state;
        let { template, onUpdate } = this.props;
        return <ModalForm
            width="80%"
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                option.title.text = values.title;
                option.title.subtext = values.subTitle;
                option.yAxis.data = values.items?.map(i => i.name);
                option.series[0].data = values.items?.map(i => i.value);
                option.series[0].barWidth = values.barWidth;
                let rsp = await apiDapingDataSet(template, DATA_KEY, option);
                if (rsp.Status === 0) {
                    this.setState({
                        option: option,
                        visible: false,
                    }, () => {
                        chart?.setOption && chart.setOption(option);
                        onUpdate && onUpdate(DATA_KEY);
                    })
                }
                return true;
            }}
        >
            <ProCard split="vertical">
                <ProCard colSpan="30%">
                    <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
                    <ProFormText name="subTitle" label="副标题" placeholder="请输入副标题" />
                </ProCard>
                <ProCard colSpan="70%">
                    <ProFormDigit name="barWidth" label="柱状条宽度" min={0} placeholder="请输入数据" tooltip="0代表自适应"/>
                    <ProFormList name="items" label="数据项">
                        <ProForm.Group size={16}>
                            <ProFormText name="name" label="名称" />
                            <ProFormDigit name="value" label="值" min={0} />
                        </ProForm.Group>
                    </ProFormList>
                </ProCard>
            </ProCard>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.onRefresh({ action: "refresh", type: DATA_KEY })
    }

    render() {
        let { editable, loading } = this.state;
        return <>
            {this.mkEdit()}
            <div className="decorate" />
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div id="rank" className="rank-chart"/>
            </Editable>
        </>
    }
}

export default Rank;

