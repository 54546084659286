
import { api } from './api';

export let apiOrganizationGet = async (data, extra) => {
    return await api("/ccs/v1/operator/comm/organization/get", Object.assign(data, {KEY: "organization.1"}), extra)
}

export let apiOrganizationSet = async (data, extra) => {
    return await api("/ccs/v1/operator/comm/organization/set", Object.assign(data, {KEY: "organization.1"}), extra)
}

