
import { api } from './api';

export let apiTaskList = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/list", data, extra)
}

export let apiTaskAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/add", data, extra)
}

export let apiTaskMod = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/mod", data, extra)
}

export let apiTaskDel = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/del", data, extra)
}

export let apiTaskDetail = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/detial", data, extra)
}

export let apiTaskTypeList = async (data, extra) => {
    return await api("/ccs/v1/operator/missionType/list", data, extra)
}

export let apiTaskStateStatistic = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/state/count", data, extra)
}

export let apiTaskScheduledDateStatistic = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/scheduledDate/count", data, extra)
}

export let apiTaskTerminalAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/addDevice", data, extra)
}

export let apiTaskTerminalBatchAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/addDeviceBatch", data, extra)
}

export let apiTaskTerminalDel = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/delDevice", data, extra)
}

export let apiTaskTerminalBatchDel = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/delDeviceBatch", data, extra)
}

export let apiTaskStaffAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/addStaff", data, extra)
}

export let apiTaskStaffBatchAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/addStaffBatch", data, extra)
}

export let apiTaskStaffDel = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/delStaff", data, extra)
}

export let apiTaskStaffBatchDel = async (data, extra) => {
    return await api("/ccs/v1/operator/mission/delStaffBatch", data, extra)
}
