import React from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';

export class DraggableModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            disabled: true,
            bounds: { left: 0, top: 0, bottom: 0, right: 0 },
        };
    }

    onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = this.draggleRef?.current?.getBoundingClientRect();
        console.log(targetRect);
        this.setState({
            bounds: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y),
            },
        });
    };

    render() {
        const { bounds, disabled } = this.state;
        let { visible, children, onOk, onCancel, title } = this.props;

        return (<>
            <Modal
                title={<div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            this.setState({
                                disabled: false,
                            });
                        }
                    }}
                    onMouseOut={() => {
                        this.setState({
                            disabled: true,
                        });
                    }}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    >
                        {title}
                    </div>
                }
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
                width="70%"
                modalRender={modal => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => this.onStart(event, uiData)}
                    >
                        <div ref={(ref) => this.draggleRef = ref}>{modal}</div>
                    </Draggable>
                )}
            >
                {children}
            </Modal>
        </>);
    }
}
