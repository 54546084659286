/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import QueueAnim from 'rc-queue-anim';
import { Form, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, AutoComplete, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer, DatePicker, Tabs, PageHeader, Statistic, Checkbox, Menu, Dropdown, Popconfirm,
} from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import Jimp from 'jimp';
import moment from 'moment';

export function HoRow(props) {
    let {type, justify, align, extra, children} = props;
    return (<Row type={type} justify={justify} align={align} {...extra}>{children}</Row>); 
}
HoRow.defaultProps = {
    type: 'flex',
    justify: 'space-around',
    align: 'top',
};

export function HoCol(props) {
    let {span, offset, extra, children} = props;
    return (<Col offset={offset} span={span} {...extra}>{children}</Col>);
}
HoCol.defaultProps = {
    span: 12,
    offset: undefined,
};

// 跑马灯
export function HoCarousel(props){
    let {children, extra} = props;
    return (<Carousel autoplay {...extra}>
        {children}
    </Carousel>)
}

// 分割线
export function HoDivider(props) {
    return (<Divider {...props}>{props.children}</Divider>);
}
HoDivider.defaultProps = {
    type: 'horizontal',
    orientation: 'left',
};

// 标签
export function HoTag(props) {
    let {color, className, children, style, extra} = props;
    return (<Tag color={color} className={className} style={style} {...extra}>{children}</Tag>);
}
HoTag.defaultProps = {
    color: 'blue',
    className: 'ml-2',
    extra: undefined,
};

// 抽屉
export function HoDrawer(props){
    let {title, width, onClose, visible, children, extra} = props;
    return (<Drawer
          title={title}
          width={width}
          onClose={onClose}
          visible={visible}
          {...extra}
        >{children}</Drawer>)
}
HoDrawer.defaultProps = {
    title: "",
    width: 480,
    onClose: undefined,
    visible: false,
    extra: undefined,
};

// 卡片
export function HoCard(props) {
    let {title, buttons, extra, children} = props;
    
    return (<Card
        className="ho-card"
        title={<QueueAnim type={['left', 'right']}>{<div key="title">{title}</div>}</QueueAnim>} 
        extra={<QueueAnim type={['right', 'left']}>{<div key="buttons">{buttons}</div>}</QueueAnim>}
        bordered={false} 
        {...extra}>
            {children}
        </Card>);
}

HoCard.defaultProps = {
    title: "",
    buttons: undefined,
    extra: {bordered:false, style: {
        marginLeft: '24px',
        marginRight: '36px',
        marginTop: '12px',
    }},
};

// 卡片Meta
export function HoCardMeta(props) {
    let {title, style, description, avatar, extra} = props;
    return (<Card.Meta
        title={title}
        description={description}
        style={style}
        avatar={avatar}
        {...extra}
      />);
}

HoCardMeta.defaultProps = {
    title: "",
    description: "",
    avatar: undefined,
    style: undefined,
};

// 面包屑
export function HoBreadcrumb(props) {
    return (
        <Breadcrumb key="ho-breadcrumb" {...props}>
            {props.children}
            <Breadcrumb.Item key="main" >
                <HoLinkButton className="ho-breadcrumb-item-link" onClick={(e) => window.goToMenu("")}>主页</HoLinkButton>
            </Breadcrumb.Item>
            {props.items.map((item, index) => {
                if (item.breadcrumbName) {
                    let content = <HoLinkButton className="ho-breadcrumb-item" style={{padding: 0}}>{item.breadcrumbName}</HoLinkButton>
                    if (item.url) {
                        content = <HoLinkButton className="ho-breadcrumb-item-link" onClick={(e) => window.goToMenu(item.path)}>{item.breadcrumbName}</HoLinkButton>
                    }
                    return <Breadcrumb.Item key={index} {...item.props}>
                        {content}
                    </Breadcrumb.Item>
                }
                return undefined;
            })}
        </Breadcrumb>
    );
}

HoBreadcrumb.defaultProps = {
    separator: "/",
    style: {
        marginLeft: '24px',
        marginTop: '24px',
    },
    items: [],
};

// 带icon的提示
export function HoTooltipIcon(props){
    let {title, iconType, onClick} = props;
    return <Tooltip title={title}><Icon type={iconType} onClick={onClick}/></Tooltip>
}
HoTooltipIcon.defaultProps = {
    title: "",
    iconType: "info-circle-o",
    onClick: undefined,
};

export function HoTooltip(props){
    let {title, children} = props;
    return <Tooltip title={title}>{children}</Tooltip>
}
HoTooltip.defaultProps = {
    title: "",
};

// 按钮
export function HoButton(props) {
    return (<Button {...props}>{props.children}</Button>);
}
HoButton.defaultProps = {
    type: "primary",
    onClick: undefined,
    extra: undefined,
    style: {marginLeft: '1rem'},
    key: 'default',
    icon: undefined,
};

// 刷新按钮
export function HoRefreshButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="ghost" style={{marginLeft: '1rem'}} onClick={onClick} key="refresh" icon="sync" {...extra}>{children || "刷新"}</Button>);
}

// 编辑按钮
export function HoEditButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="primary" style={{marginLeft: '1rem'}} onClick={onClick} key="edit" icon="edit" {...extra}>{children || "编辑"}</Button>);
}

// 添加按钮
export function HoNewButton(props) {
    return (<Button type="primary" style={{marginLeft: '1rem'}} key="new" icon="plus" {...props}>{props.children || "添加"}</Button>);
}

// 保存按钮
export function HoSaveButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="danger" style={{marginLeft: '1rem'}} onClick={onClick} key="save" icon="save" {...extra}>{children || "保存"}</Button>);
}

// 确定按钮
export function HoOkButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="danger" style={{marginLeft: '1rem'}} onClick={onClick} key="ok" icon="check" {...extra}>{children || "确定"}</Button>);
}

// 取消按钮
export function HoCancelButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="primary" style={{marginLeft: '1rem'}} onClick={onClick} key="cancel" icon="close" {...extra}>{children || "取消"}</Button>);
}

// 预览按钮
export function HoPreviewButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="primary" style={{marginLeft: '1rem'}} onClick={onClick} key="preview" icon="eye" {...extra}>{children || "预览"}</Button>);
}

// 生成按钮
export function HoGenButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="primary" style={{marginLeft: '1rem'}} onClick={onClick} key="gen" icon="build" {...extra}>{children || "批量生成"}</Button>);
}

// 开始按钮
export function HoStartButton(props) {
    let {onClick, extra, children} = props;
    return (<Button type="primary" style={{marginLeft: '1rem'}} onClick={onClick} key="start" icon="play-circle" {...extra}>{children || "开始"}</Button>);
}

// 结束按钮
export function HoStopButton(props) {
    let { onConfirm, onCancel, okText, cancelText, popTitle, extra, children } = props;
    return (
        <Popconfirm title={popTitle || "是否确认结束？"}
            okText={okText || "是的"} 
            onConfirm={onConfirm} 
            cancelText={cancelText || "我再想想"} 
            onCancel={onCancel}>
            <Button type="danger" style={{marginLeft: '1rem'}} key="stop" icon="stop" {...extra}>{children || "结束"}</Button>
        </Popconfirm>
    );
}

// 查看历史按钮
export function HoHistoryButton(props) {
    let {onClick, extra, children} = props;
    return (<Button style={{marginLeft: '1rem'}} onClick={onClick} key="history" icon="history" {...extra}>{children || "查看历史"}</Button>)
}

// 返回按钮
export function HoBackButton(props) {
    return (<Button type="primary" style={{marginLeft: '1rem'}} key="back" icon="arrow-left" {...props}>{props.children || "返回"}</Button>)
}

// link按钮
export function HoLinkButton(props) {
    return (<Button type="link" style={{marginLeft: '0.5rem', padding: 0}} key="link" {...props}>{props.children}</Button>)
}


// 更多下拉按钮
export function HoMoreDropdownButton(props) {
    let {menu, extra, children} = props;
    return (<Dropdown overlay={menu}>
        <Button type="primary" style={{marginLeft: '1rem'}} key="more" icon="menu" {...extra}>{children || "更多"}</Button>
    </Dropdown>);
}



// 对话框
export function HoModal(props) {
    let {title, visible, onOk, onCancel, footer, children, extra} = props;
    return (<Modal visible={visible} title={title} onOk={onOk} onCancel={onCancel} footer={footer} {...extra}>
        {children}
    </Modal>);
}

// 图标
export function HoIcon(props) {
    let {type, extra} = props;
    return (<Icon type={type} {...extra}/>);
}
HoIcon.defaultProps = {
    type: 'question',
}

// 提示对话框
export function HoAlertDialog(props) {
    let {title, visible, 
        okText, onOkClick, okButtonProps, 
        cancelText, onCancelClick, cancelButtonProps, width, footer, destroyOnClose, 
        children, extra} = props;
    return (<Modal title={title} visible={visible} 
        width={width}
        okButtonProps={okButtonProps} onOk={onOkClick} okText={okText}
        cancelButtonProps={cancelButtonProps} onCancel={onCancelClick} cancelText={cancelText}
        footer={footer} 
        destroyOnClose={destroyOnClose}
        {...extra}>
        {children}
    </Modal>);
}
HoAlertDialog.defaultProps = {
    title: "提示",
    visible: false,
    okText: '确定',
    onOkClick: undefined,
    okButtonProps: {type:'primary'},
    cancelText: '我再想想',
    onCancelClick: undefined,
    cancelButtonProps: {type:'default'},
    width: '50%',
    footer: undefined,
    destroyOnClose: true,
    extra: undefined,
};

// 删除提示对话框
export function HoDeleteAlertDialog(props) {
    let {title, visible, onOkClick, onCancelClick, children, extra} = props;
    return (<Modal title={title} visible={visible} 
        okButtonProps={{type:'danger'}} onOk={onOkClick} okText="删除" 
        cancelButtonProps={{type:'primary'}} onCancel={onCancelClick} cancelText="我再想想" {...extra}>
        {children}
    </Modal>);
}
HoDeleteAlertDialog.defaultProps = {
    title: "删除提示",
    visible: false,
    onOkClick: undefined,
    onCancelClick: undefined,
    extra: undefined,
};


export class HoUploadPictureWall extends Component {
    constructor(props){
        super(props);

        this.getBase64 = this.getBase64.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleChange = this.handleChange.bind(this);

        // console.log("HoUploadPictureWall", fileList);

        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: props.fileList,
            updateFlag: true,
        };
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
  
    handleCancel() {
        this.setState({ previewVisible: false });
    }
  
    async handlePreview(file) {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange({ fileList }) {
        const {onChange} = this.props;

        for (let index in fileList){
            const file = fileList[index];
            const mime = file.type;
            if (file.status !== 'done' || mime === undefined){
                continue;
            }

            let reader = new FileReader();
            reader.onloadend = async () => {
                let buf = reader.result;
                let image = await Jimp.read(buf);
                const width = image.bitmap.width;
                const height = image.bitmap.height;
                let resizeImg = image;
                if (width > 960){
                    resizeImg = image.resize(960, Jimp.AUTO);
                }
                else if(height > 720){
                    resizeImg = image.resize(Jimp.AUTO, 720);
                }
                fileList[index] = {
                    name: file.name,
                    status: file.status,
                    uid: file.uid,
                    url: await resizeImg.getBase64Async(mime),
                }

                this.setState({
                    fileList: fileList,
                    updateFlag: !this.state.updateFlag,
                })

                if (onChange !== undefined){
                    onChange(fileList, !this.state.updateFlag);
                }

            }
            reader.readAsArrayBuffer(file.originFileObj);
        }

        if (onChange !== undefined){
            onChange(fileList, !this.state.updateFlag);
        }

        this.setState({
            fileList: fileList,
            updateFlag: !this.state.updateFlag,
        })
    };
  
    render() {
        const { previewVisible, previewImage, } = this.state;
        const { maxPicture, action, disabled, fileList} = this.props;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">点击上传图片</div>
            </div>
        );
        
      console.log("render", fileList); 

        return (
        <div className="clearfix">
            <Upload
                disabled={disabled}
                action={action}
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
            >
                {fileList.length >= maxPicture ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
        );
    }
}

HoUploadPictureWall.defaultProps = {
    maxPicture: 1,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: undefined,
    disabled: false,
    fileList: [],
}


export class HoUploadAvatar extends Component {
    constructor(props){
        super(props);

        this.getBase64 = this.getBase64.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBase64Done = this.handleBase64Done.bind(this);

        this.state = {
            loading: false,
        };
       
    }
    
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);

        // let reader = new FileReader();
        // const mime = file.type;
        // reader.onloadend = async () => {
        //     let buf = reader.result;
        //     let image = await Jimp.read(buf);
        //     const width = image.bitmap.width;
        //     const height = image.bitmap.height;
        //     let resizeImg = image;
        //     if (width > 960){
        //         resizeImg = image.resize(960, Jimp.AUTO);
        //     }
        //     else if(height > 720){
        //         resizeImg = image.resize(Jimp.AUTO, 720);
        //     }
        //     let  dataUri = await resizeImg.getBase64Async(mime);
        //     item.previewUrl = dataUri;
        //     item.fileName = file.name
        //     this.setState({
        //         ...this.state, 
        //         picList: picList
        //     });
        // }
        // reader.readAsArrayBuffer(file)
    }
      
    beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJPG) {
          message.error('只支持JPG、PNG格式文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('图片大小必须小于2MB!');
        }
        return isJPG && isLt2M;
    }
      
    handleBase64Done(imageUrl){
        const {onChange} = this.props;
        
        if (onChange !== undefined){
            onChange(imageUrl)
        }
        this.setState({
            loading: false,
        })
    }
  
    handleChange(info) {
        switch(info.file.status){
            case 'uploading':
                this.setState({ loading: true });
                break;
            
            case 'done':
                this.getBase64(info.file.originFileObj, this.handleBase64Done);
                break;
            
            default:
                break;
        }
    };
  
    render() {
        const {style, action, disabled, imageUrl, alt} = this.props;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">点击上传图片</div>
            </div>
        );
        return (<Upload
            disabled={disabled}
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            style={style}
            showUploadList={false}
            showRemoveIcon={true}
            action={action}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}>
                {imageUrl ? <img height={240} width={240} src={imageUrl} alt={alt} /> : uploadButton}
        </Upload>);
    }
}

HoUploadAvatar.defaultProps = {
    style: { width:240 },
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: undefined,
    disabled: false,
    alt: "",
}


// message
export function hoMessage(props){
    let { type, msg, duration, onClose } = props;
    let fun = undefined;
    switch(type){
        case "info": fun = message.info; break;
        case "error": fun = message.error; break;
        case "warning": fun = message.warning; break;
        case "loading": fun = message.loading; break;
        case "success":
        default: fun = message.success; break;
    }
    fun(msg, duration, onClose);
}
hoMessage.defaultProps = {
    type: 'success',
    msg: "",
    duration: 3,
    onClose: undefined,
}

export function hoMessageDestroy() {
    message.destroy();
}


export function HoDateRangePicker(props){
    let { value, onChange } = props;

    return (<DatePicker.RangePicker
        value={value}
        onChange={onChange}
        placeholder={['开始日期', '结束日期']}
        ranges={{
            '今天': [moment().startOf('day'), moment().endOf('day')],
            '昨天': [moment().add(-1, 'day').startOf('day'), moment().add(-1, 'day').endOf('day')],
            '本周': [moment().startOf('week'), moment().endOf('week')],
            '上周': [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')],
            '本月': [moment().startOf('month'), moment().endOf('month')],
            '上月': [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
            '今年': [moment().startOf('year'), moment().endOf('year')],
        }}
        format="YYYY-MM-DD"
        separator="至"
        dateRender={current => {
            const style = {};
            let date = current.date();
            if (current.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
                style.border = '1px solid #1890ff';
                style.borderRadius = '50%';
                date = <span>今</span>
            }
            return (
              <div className="ant-calendar-date" style={style}>
                {date}
              </div>
            );
        }}
        // style={{width:480}}
        // showTime
        // showToday
    />);
}

// 页头
export function HoPageHeader(props){

    let { title, subTitle, tags, onBack, description, extra, footer, children, breadcrumb, style } = props;

    return (<PageHeader
        title={title}
        subTitle={subTitle}
        style={style}
        tags={tags}
        onBack={onBack}
        content={description}
        breadcrumb={breadcrumb}
        extra={extra}
        footer={footer}
    >
        {children}
    </PageHeader>);
}
HoPageHeader.defaultProps = {
    style: {
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '12px',
    },
}

  

// 标签页
export function HoTabs(props){
    let { tabPanes, type, hideAdd, activeKey, defaultActiveKey, onChange, onEdit, tabPanesForceRender } = props;
    return (<Tabs 
        hideAdd={hideAdd}
        type={type}
        activeKey={activeKey}
        defaultActiveKey={defaultActiveKey} 
        onChange={onChange}
        onEdit={onEdit}
        >
        {tabPanes.map(item => <Tabs.TabPane tab={item.tab} key={item.key} forceRender={tabPanesForceRender}>{item.children}</Tabs.TabPane>)}
    </Tabs>);
}
HoTabs.defaultProps = {
    type: 'line',
    tabPanes: [],
    onChange: undefined,
    onEdit: undefined,
    hideAdd: true,
    tabPanesForceRender: false,
}

export function HoTabPane(props){
    let { tab, key, children } = props;

    return (<Tabs.TabPane tab={tab} key={key}>
        {children}
    </Tabs.TabPane>);
}

// Statistic
export function HoStatistic(props){
    let {title, prefix, children} = props;
    return (<Statistic 
        title={title} 
        prefix={prefix} 
        value={children}
        />)
}
HoStatistic.defaultProps = {
    title: "",
    prefix: "",
    children: undefined,
}

// 输入框
export function HoInput(props){
    let {placeholder, defaultValue, size, onChange, prefix, type, extra, children} = props;
    return (<Input 
        defaultValue={defaultValue}
        onChange={onChange}
        prefix={prefix}
        type={type}
        placeholder={placeholder}
        size={size}
        {...extra}
        >
        {children}
    </Input>);
}
HoInput.defaultProps = {
    size: 'default',
    placeholder: "",
    type: undefined,
    prefix: undefined,
    extra: {},
}

// 检查框
export function HoCheckbox(props){
    let {checked, onChange, children} = props;
    
    return (<Checkbox 
        checked={checked} 
        onChange={onChange}
        >
        {children}
    </Checkbox>);
}
HoCheckbox.defaultProps = {
    checked: true,
    onChange: undefined,
}

export function HoGroupMenu(props){
    const {mainMenu, subMenus, onClick} = props;
    const subMenuComponent = subMenus.map(item => <Menu.Item key={item.path} onClick={onClick}>
        <Icon type={item.icon}/><span>{item.title}</span>
    </Menu.Item>);
    return (<Menu.SubMenu
        key={mainMenu.path}
        title={<span><Icon type={mainMenu.icon} /><span>{mainMenu.title}</span></span>}
    >
        {subMenuComponent}
    </Menu.SubMenu>)
}

export function HoBackTop(props) {
    const { extra } = props;
    return (<BackTop 
        {...extra}
    />);
}

HoCheckbox.defaultProps = {
    extra: {},
}

// 折叠面板
export function HoCollapse(props) {
    
    const { defaultActiveKey, onChange, expandIconPosition, panels, extra } = props;

    return (<Collapse
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        expandIconPosition={expandIconPosition}
        {...extra}
    >
        {panels.map(item => <Collapse.Panel
            header={item.header} 
            key={item.key} 
            extra={item.buttons}
            {...item.extra}
        >
            {item.children}    
        </Collapse.Panel>)}    
    </Collapse>);
}

HoCollapse.defaultProps = {
    defaultActiveKey: ['0'],
    onChange: undefined,
    expandIconPosition: 'left',
    children: undefined,
    extra: {},
}

// 空状态
export function HoEmpty(props) {
    
    const { image, imageStyle, description, extra, children } = props;

    return <Empty 
        image={image} 
        imageStyle={imageStyle} 
        description={description} 
        {...extra}>
        {children}
    </Empty>;
}

HoEmpty.defaultProps = {
    image: Empty.PRESENTED_IMAGE_SIMPLE,
    children: undefined,
    imageStyle: undefined,
    description: undefined,
    extra: {},
}

export function HoAlert(props){
    let {message, type, closable, onClose, extra} = props;

    return (
        <Alert
            message={message}
            type={type}
            closable={closable}
            onClose={onClose}
            {...extra}
        />
    );
}
HoAlert.defaultProps = {
    type: "warning",
    closable: false,
}

export let IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1426787_s6r3krjzj7q.js', // 在 iconfont.cn 上生成
})