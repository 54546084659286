
import { api } from './api';

export let apiPhoneList = async (data, extra) => {
    return await api("/ccs/v1/operator/device/list", data, extra)
}

export let apiPhoneAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/device/add", data, extra)
}

export let apiPhoneMod = async (data, extra) => {
    return await api("/ccs/v1/operator/device/mod", data, extra)
}

export let apiPhoneDel = async (data, extra) => {
    return await api("/ccs/v1/operator/device/del", data, extra)
}
