/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import { Tag } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

export function sipStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.AppState){
        case 'online': 
            tags.push({color: green[3], tag:'在线'});
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'离线'});
            break;
        default: 
            break;
    }
    return tags;
}

export function confStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.ConfState){
        case 'joined': 
            tags.push({color: green[3], tag:'已入会'});
            break;
        case 'notjoin': 
            tags.push({color: grey[3], tag:'未入会'});
            if (record.AppState === 'online'){
                switch(record.CallState){
                    case 'idle': tags.push({color: green[3], tag:'空闲'});break;
                    case 'occupy': tags.push({color: red[3], tag:'被占用'});break;
                    default:break;
                }
                // tags.push({color: grey[3], tag:'未入会'});
            }
            break;
        case 'oncejoined': 
            tags.push({color: gold[3], tag:'曾入会'});
            break;
        default: 
            break;
    }
    return tags;
}

export function confStateTags2(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.ConfState){
        case 'joined': 
            tags.push({color: blue[3], tag:'已入会'});
            break;
        case 'notjoin': 
            tags.push({color: grey[3], tag:'未入会'});
            tags = tags.concat(sipStateTags(text, record));
            if (record.AppState === 'online'){
                switch(record.CallState){
                    case 'idle': tags.push({color: green[3], tag:'空闲'});break;
                    case 'occupy': tags.push({color: red[3], tag:'被占用'});break;
                    default:break;
                }
                // tags.push({color: grey[3], tag:'未入会'});
            }
            break;
        case 'oncejoined': 
            tags.push({color: gold[3], tag:'曾入会'});
            break;
        case 'inviting': 
            tags.push({color: gold[3], tag:'正在邀请'});
            break;
        default: 
            break;
    }
    return tags;
}


export function callStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.AppState){
        case 'online': 
            tags.push({color: green[3], tag:'在线'});
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'离线'});
            break;
        default: 
            break;
    }

    switch(record.SipState){
        case 'online': 
            tags.push({color: green[3], tag:'已注册'});
            switch(record.CallState){
                case 'idle': 
                    tags.push({color: green[3], tag:'空闲'});
                    break;
                case 'occupy': 
                    tags.push({color: red[3], tag:'通话中'});
                    break;
                default: 
                    break;
            }
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'未注册'});
            break;
        default: 
            break;
    }
    
    return tags;
}

export let DEVICE = {
    TYPE: {
        ALL: 0,
        CAMERA: 1,
        TERMINAL: 2,
        PHONE: 3,
        ALARM: 4,
        TELEPHONE: 99,
        LIST: [
            { value: 1, label: '摄像头' },
            { value: 2, label: '音视频终端' },
            { value: 3, label: '手持终端' },
            { value: 4, label: '告警器' },
            { value: 99, label: '手机' },
        ],
        description: (value) => {
            return DEVICE.TYPE.LIST.find(l => l.value === value)?.label
        },
        valueEnum: (valid) => {
            let map = {};
            DEVICE.TYPE.LIST.forEach(item => {
                if (!valid || (valid && valid.includes(item.value))) {
                    map[item.value] = { text: item.label }
                }
            });
            return map;
        },
    },
    STATE: {
        ALL: '',
        ONLINE: 'online',
        OFFLINE: 'offline',
        LIST: [
            { value: 'online', label: '在线' },
            { value: 'offline', label: '离线' },
        ],
        description: (value) => {
            return DEVICE.STATE.LIST.find(l => l.value === value)?.label
        },
        valueEnum: () => {
            let map = {};
            DEVICE.STATE.LIST.forEach(item => {
                map[item.value] = { text: item.label }
            });
            return map;
        },
        columnRender: (record) => {
            let tag = undefined;
            switch (record.State) {
                case "online":
                    tag = <Tag color={green[3]}>在线</Tag>
                    break;
                case "offline":
                    tag = <Tag color={grey[3]}>离线</Tag>
                    break;
                default:
                    break;
            }
            return tag
        },
        isIdle: (record) => {
            return (record.State === 'online' && record.SipState === 'online' && record.CallState === 'idle')
        },
        now: (record) => {
            return (record.State === 'online' && record.SipState === 'online') ? (record.CallState === 'idle' ? 'online' : 'busy') : 'offline'
        }
    },
    ORGTYPE: {
        ALL: '',
        CAMERA: 'CAMERA-28181',
        WEB: 'WEB',
        SDK: 'VMEETING-SDK',
        TELEPHONE: 'telephone',
        LIST: [
            { value: 'CAMERA-28181', label: '摄像头' },
            { value: 'WEB', label: '网页用户' },
            { value: 'VMEETING-SDK', label: 'SDK用户' },
            { value: 'telephone', label: '手机' },
        ],
        description: (value) => {
            return DEVICE.ORGTYPE.LIST.find(l => l.value === value)?.label
        },
        valueEnum: (valid) => {
            let map = {};
            DEVICE.ORGTYPE.LIST.forEach(item => {
                if (!valid || (valid && valid.includes(item.value))) {
                    map[item.value] = { text: item.label }
                }
            });
            return map;
        },
    },
    ORGSTATE: {
        ALL: '',
        ONLINE: 'online',
        OFFLINE: 'offline',
        LIST: [
            { value: 'online', label: '在线' },
            { value: 'offline', label: '离线' },
        ],
        description: (value) => {
            return DEVICE.STATE.LIST.find(l => l.value === value)?.label
        },
        valueEnum: () => {
            let map = {};
            DEVICE.STATE.LIST.forEach(item => {
                map[item.value] = { text: item.label }
            });
            return map;
        },
        columnRender: (record) => {
            let tag = undefined;
            switch (record.AppState) {
                case "online":
                    tag = <Tag color={green[3]}>在线</Tag>
                    break;
                case "offline":
                    tag = <Tag color={grey[3]}>离线</Tag>
                    break;
                default:
                    break;
            }
            return tag
        }
    },
}

export let TASK = {
    STATE: {
        TOSTART: "tostart",
        INPROGRESS: "inprogress",
        FINISHED: "finished",
        ALL: "all",
        LIST: [
            { value: "tostart", label: '未开始', color: gold[5] },
            { value: "inprogress", label: '进行中', status: 'Processing' },
            { value: "finished", label: '已完成', status: 'Default' },
        ],
        values: () => {
            return TASK.STATE.LIST.map(l => l.value);
        },
        description: (value) => {
            return TASK.STATE.LIST.find(l => l.value === value)?.label
        },
        valueEnum: () => {
            let map = {};
            TASK.STATE.LIST.forEach(item => {
                map[item.value] = { text: item.label, status: item.status, color: item.color };
            });
            return map;
        }
    }
}