/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import ProForm, { ModalForm, ProFormText } from '@ant-design/pro-form';
import Editable from './editable';
import { getUrlParam } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { Button } from 'antd';

const DATA_KEY = "header"

class HeaderTitle extends Component{

    constructor(props){
        super(props);

        let defaultOption = {
            title: "主标题" 
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        if (e?.type === DATA_KEY) {
            let { defaultOption } = this.state;
            let { template } = this.props;
            async function init() {
                let rsp = await apiDapingDataGet(template, DATA_KEY);
                let option = rsp?.Data || defaultOption;
                this.setState({
                    option: option,
                    loading: false,
                })
            }
            
            init.bind(this)()
        }
    }
    
    initForm(option) {
        this.form.setFieldsValue({
            title: option?.title
        })
    }

    mkEdit() {
        let { option, visible } = this.state;
        let { template, onUpdate } = this.props;
        return <ModalForm
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                let rsp = await apiDapingDataSet(template, DATA_KEY, values);
                if (rsp.Status === 0) {
                    this.setState({
                        visible: false,
                    }, () => {
                        onUpdate && onUpdate(DATA_KEY);
                    })
                }
                return true;
            }}
          >
            <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.onRefresh({ action: "refresh", type: DATA_KEY })
    }

    render() {
        let { option, editable, loading } = this.state;
        return <>
            {this.mkEdit()}
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                {option?.title}
            </Editable>
        </>
    }
}

export default HeaderTitle;

