
import { api } from './api';

export let apiAlarmList = async (data, extra) => {
    return await api("/ccs/v1/operator/device/list", data, extra)
}

export let apiAlarmAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/device/add", data, extra)
}

export let apiAlarmMod = async (data, extra) => {
    return await api("/ccs/v1/operator/device/mod", data, extra)
}

export let apiAlarmDel = async (data, extra) => {
    return await api("/ccs/v1/operator/device/del", data, extra)
}
