/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Button } from 'antd';
import ProTable from '@ant-design/pro-table';
import { ReloadOutlined } from '@ant-design/icons';
import { pagization } from '../../../api/api';
import { PaginationPositionIcon } from '../../../component/antd/antd';
import { DEVICE } from '../../../component/common/common';

class Member extends Component{
    constructor(props){
        super(props);
        
        let columns = [
            {
                title: '状态',
                type: 'strEnumMultiple',
                width: 56,
                dataIndex: 'State',
                search: false,
                valueType: 'select',
                valueEnum: DEVICE.STATE.valueEnum(),
                render: (_, record) => DEVICE.STATE.columnRender(record)
            },
            {
                title: '照片',
                type: 'str',
                width: '20%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => <div className="staff-avatar"><img alt="" src={text}/></div>
            },
            {
                title: '类型',
                type: 'intEnumMultiple',
                width: '10%',
                dataIndex: 'TypeId',
                fieldProps: {
                    mode: 'multiple',
                },
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(this.props.valid?.['TypeId']),
            },
            {
                title: '名称',
                type: 'str',
                width: '20%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '20%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '最近在线时间',
                type: 'dateToTime',
                width: '20%',
                dataIndex: 'OnlineTime',
                valueType: 'date',
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => moment(a.OnlineTime) - moment(b.OnlineTime),
                render: (text, record) => record.OnlineTime,
            },
            {
                title: '操作',
                width: '30%',
                key: 'option',
                valueType: 'option',
                render: (_, record) => {
                    let options = [
                        <Button key="selected" type="link" className="nopadding" onClick={(e) => this.props.onSelect && this.props.onSelect(record)}>
                            {this.props.mode === 'put' ? "选择" : "定位"}
                        </Button>
                    ];
                    return options;
                },
            },
        ]
        
        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            paginationConfig: {
                position: ['bottomRight'],
                showQuickJumper: true,
            },
        }
    }

    mkTable(){
        let { columns, paginationConfig, paginationData } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={(e) => this.table.submit()}>刷新</Button>,
            <PaginationPositionIcon
                paginationConfig={paginationConfig}
                onChange={(key) => {
                paginationConfig.position = [key]
                    this.setState({
                        paginationConfig: paginationConfig,
                    })
                }}
            />,
        ]

        return <ProTable
            formRef={(ref) => this.table = ref}
            columns={columns}
            request={async (params, sorter, filter) => {
                console.group('request');
                console.log("params:", params);
                console.log("sorter:", sorter);
                console.log("filter:", filter);
                console.groupEnd();
                let rsp = await this.props?.apiAddressBook(pagization(params, sorter, filter, columns, this.props.valid))
                if (rsp?.Status === 0) {
                    this.setState({
                        paginationData: {
                            current: params?.current,
                            pageSize: params?.pageSize,
                            total: rsp?.TotalNum,
                        },
                    })
                }
                return {
                    data: rsp?.RecordList || [],
                    total: rsp?.TotalNum,
                    success: true,
                };
            }}
            rowKey="Id"
            pagination={{
                ...paginationConfig,
                ...paginationData,
            }}
            toolbar={{
                // multipleLine: true,
            }}
            search={{filterType: "light"}}
            dateFormatter="string"
            options={{
                density: true,
                setting: true,
                reload: false,
                fullScreen: false,
                search: false,
            }}
            toolBarRender={() => operations}
        />
    }

    // clear handle
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (<div className="table-bind-terminal">
            {this.mkTable()}
        </div>);
    }
}

const mapState = (state) => ({
});

export default connect(
    mapState, 
    null
)(Member);

