/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts';
import ProForm, { ModalForm, ProFormText, ProFormList, ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import Editable from './editable';
import ChinaGeo from '../../../component/map/geoJson/china.json';
import { getUrlParam } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';
import { Button } from 'antd';

const DATA_KEY = "map";

class Map extends Component{

    constructor(props){
        super(props);

        let data = [];
        let category = ['江苏', '黑龙江', '北京', '上海', '广东', '海南', '西藏', '台湾', '湖南', '河北', '四川', '重庆', '湖北', '浙江', '福建']
        for (let i = 0; i < 15; ++i) {
            data.push({ name:category[i], value: Math.round(Math.random() * 200 + 1) });
        }
        let defaultOption = {
            title: {
                show: true,
                text: '{a|地图主标题}\n{b|数据指标}{x|+单位}',
                textStyle: {
                    rich: {
                        a: {
                            color: '#6392c8',
                            fontSize: 18,
                            lineHeight: 18,
                        },
                        b: {
                            color: '#fff',
                            fontSize: 32,
                            lineHeight: 56,
                        },
                        c: {
                            color: 'red',
                            fontSize: 32,
                        },
                        d: {
                            color: '#fff',
                            fontSize: 32,
                            lineHeight: 56,
                        },
                        x: {
                            color: '#6392c8',
                            fontSize: 32,
                            lineHeight: 56,
                        },
                    },
                },
                padding: [2, 20],
            },
            visualMap: {
                type: 'continuous',
                min: 0,
                max: 200,
                text: ['高', '低'],
                textStyle: {
                    color: '#fff',
                },
                left: 16,
                realtime: true,
                calculable: true,
                inRange: {
                    color: ['#24cfff', '#564aa3','#711c8e']
                }
            },
            series : [
                {
                    name: '',
                    type: 'map',
                    map: 'china',
                    label: {
                        show: true,
                        color: '#fff',
                    },
                    itemStyle: {
                        borderColor: 'rgba(255, 255, 255, 0.2)',
                        areaColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    data: data,
                }
            ],
            backgroundColor: 'transparent',
        }
        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        if (e?.type === DATA_KEY) {
            let { chart, defaultOption } = this.state;
            let { template } = this.props;

            async function init() {
                echarts.registerMap('china', {geoJSON: ChinaGeo});
                let rsp = await apiDapingDataGet(template, DATA_KEY);
                let dom = document.getElementById('map');
                if (!chart) {
                    chart = echarts.init(dom, 'dark');
                }
                let option = rsp?.Data || defaultOption;
                chart.setOption(option);
                window.addEventListener("resize",function (){
                    setTimeout(() => {
                        chart.resize();
                    })
                });
                this.setState({
                    option: option,
                    loading: false,
                    chart: chart,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(option) {
        let text = option?.title?.text || ""
        let reg = /{a\|(.*)}\n{b\|(.*)}{x\|(.*)}/.exec(text)
        this.form.setFieldsValue({
            title: reg?.[1],
            subTitle: reg?.[2],
            subTitleExtra: reg?.[3],
            itemValueMin: option?.visualMap?.min,
            itemValueMax: option?.visualMap?.max,
            items: option?.series?.[0]?.data
        })
    }

    mkEdit() {
        let { chart, option, visible } = this.state;
        let { template, onUpdate } = this.props;
        return <ModalForm
            width="80%"
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                option.title.text = `{a|${values.title}}\n{b|${values.subTitle}}{x|${values.subTitleExtra}}`;
                option.title.text = option.title.text.replace(/\n/g, '\\n')
                option.series[0].data = values.items;
                option.visualMap.min = values.itemValueMin;
                option.visualMap.max = values.itemValueMax;
                let rsp = await apiDapingDataSet(template, DATA_KEY, option);
                option.title.text = option.title.text.replace(/\\n/g, '\n');
                if (rsp.Status === 0) {
                    this.setState({
                        option: option,
                        visible: false,
                    }, () => {
                        chart?.setOption && chart.setOption(option);
                        onUpdate && onUpdate(DATA_KEY);
                    })
                }
                return true;
            }}
        >
            <ProCard split="vertical">
                <ProCard colSpan="30%">
                    <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
                    <ProFormText name="subTitle" label="数据指标" placeholder="请输入数据指标" />
                    <ProFormText name="subTitleExtra" label="数据指标单位" placeholder="请输入数据指标单位" />
                    <ProFormDigit name="itemValueMin" label="数据范围最小值" min={0} placeholder="请输入数据" tooltip="不需要严格和数据项中的最小值一致，可以小于它"/>
                    <ProFormDigit name="itemValueMax" label="数据范围最大值" min={0} placeholder="请输入数据" tooltip="不需要严格和数据项中的最大值一致，可以大于它" />
                </ProCard>
                <ProCard colSpan="70%">
                    <ProFormList name="items" label="数据项">
                        <ProForm.Group size={16}>
                            <ProFormText name="name" label="名称" />
                            <ProFormDigit min={0} name="value" label="值" />
                        </ProForm.Group>
                    </ProFormList>
                </ProCard>
            </ProCard>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.onRefresh({ action: "refresh", type: DATA_KEY })
    }

    render() {
        let { editable, loading } = this.state;
        return <>
            {this.mkEdit()}
            <div className="decorate" />
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div id="map" className="map-chart"/>
            </Editable>
        </>
    }
}

export default Map;

