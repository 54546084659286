
import { api } from './api';

export let apiDapingDataSet = async (key1, key2, data, extra) => {
    return await api("/ccs/v1/operator/comm/demoData/set", {
        DataDescA: key1,
        DataDescB: key2,
        Data: JSON.stringify(data)
    }, extra) || {}
}

export let apiDapingDataGet = async (key1, key2, extra) => {
    let rsp = await api("/ccs/v1/operator/comm/demoData/get", {
        DataDescA: key1,
        DataDescB: key2,
    }, { ignoreError: true, ...extra }) || {}
    try {
        rsp.Data = rsp.Data ? JSON.parse(rsp.Data) : null
    } catch (error) {
        console.error(error)
        rsp.Data = null;
    }
    return rsp;
}
