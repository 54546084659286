/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {Provider} from 'react-redux';
import AnimatedRouter from 'react-animated-router';

import {configStore, loadStoreState} from './redux/store';

import * as serviceWorker from './serviceWorker';

// import 'antd/dist/antd.less';
import './css/antd.css'; 
import './css/animate.css'; 
import './css/custom.less'; 

import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import MainLayout from './laylout/mainLayout';
import LoginCT from './pages/login/loginCT';
import Daping from './pages/daping/daping';
import Situation from './pages/task/situation/situation';

moment.locale('zh-cn');

const initState = loadStoreState();
const store = configStore(initState);

const app = (
    <ConfigProvider locale={zh_CN}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    {/* 登录页 */}
                    <Route exact path='/' component={LoginCT} />
                    {/* 这些页面是全屏，不需要导航 */}
                    <Route path='/ccs/daping/' component={Daping} />
                    <Route path='/ccs/task/situation' component={Situation} />
                    {/* 业务路由页面 */}
                    <Route path='/ccs' component={MainLayout} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
