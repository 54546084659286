/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Menu, Modal } from 'antd';
import { isFullscreen, toggleFullscreen, screenUnwatchFull, screenWatchFull, screenWatchResize, screenResize } from '../../component/common/screenFull';
import { apiDapingDataGet } from '../../api/daping';
import { template as TEMP } from '../../component/common/dapingTemplate';
import { getLoginUserInfo } from '../login/loginRD';
import { getUrlParam } from '../../util/logic';
import { message } from '../../util/message';
import { IconFont } from '../../util/hoComponent';

import Default from './template/default';
import Template1 from './template/template1';
import Template2 from './template/template2';
import Template3 from './template/template3';

import './daping.less';


class Daping extends Component{

    constructor(props){
        super(props);

        let template = getUrlParam("template", window.location?.search);
        this.state = {
            template: template,
        }
    }

    screenOnChange(e) {
        this.setState({
            isFullScreen: isFullscreen(),
        }, () => {
            screenResize.bind(this)();
        });
    }

    showMenuStyle(e, domId) {
        let { height, width } = this.state;
        let topOffset = 0;
        let rightOffset = 16;
        let dom = document.getElementById(domId);
        if (dom) {
            if (dom.clientHeight + e.clientY > height) {
                topOffset -= dom.clientHeight
            }
            if (dom.clientWidth + e.clientX > width) {
                rightOffset -= rightOffset * 2 + dom.clientWidth;
            }
        }

        return {
            opacity: 1,
            zIndex: 999,
            top: `${e.clientY + topOffset}px`,
            left: `${e.clientX + rightOffset}px`,
        }
    }

    hideMenuStyle() {
        return {
            opacity: 0,
            zIndex: 0,
        }
    }

    mkMenu() {
        let {  menuStyle, isFullScreen } = this.state;

        return <Menu
            id="daping-menu"
            className="daping-menu"
            selectedKeys={[]}
            defaultSelectedKeys={[]}
            style={menuStyle}
            onClick={(e) => {
                this.setState({
                    menuStyle: this.hideMenuStyle()
                })
            }}
        >
            <Menu.Item key="full-screen" onClick={() => {
                toggleFullscreen();
            }}>
                <div className="left">
                    <IconFont className="icon-menu" type={!isFullScreen ? "icon-full-screen" : "icon-full-screen-exit"} />
                    <span className="description">{!isFullScreen ? "全屏" : "退出全屏"}</span>
                </div>
            </Menu.Item>
            <Menu.Item onClick={e => {
                Modal.confirm({
                    title: `是否退出登录？`,
                    cancelText: "我再想想",
                    okText: "退出",
                    onOk: () => {
                        message.destroy();
                        this.props.history.push('/')
                    }
                })
            }}>
                <div className="left">
                    <IconFont className="icon-menu" type="icon-exit" />
                    <span className="description">退出登录</span>
                </div>
            </Menu.Item>
        </Menu>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        screenUnwatchFull((e) => this.screenOnChange(e));
    }

    componentDidMount() {
        let { template } = this.state;
        if (!template) {
            setTimeout(async () => {
                let rsp = await apiDapingDataGet("daping.select", "0")
                let ret = TEMP.list.find(t => t.template === rsp?.Data?.selected);
                this.setState({
                    template: ret?.template || "default" 
                })
            }, 0);
        }

        document.oncontextmenu = (e) => {
            this.setState({
                menuStyle: this.showMenuStyle(e, "daping-menu"),
            })
            return false;
        }

        screenWatchFull((e) => this.screenOnChange(e));
        screenWatchResize(screenResize.bind(this))

        screenResize.bind(this)();
    }

    render() {
        let { template, width, height, isFullScreen } = this.state;
        let temp = undefined
        switch (template) {
            case "template1":
                temp = <Template1 width={width} height={height} isFullScreen={isFullScreen} />
                break;
            case "template2":
                temp = <Template2 width={width} height={height} isFullScreen={isFullScreen} />
                break;
            case "template3":
                temp = <Template3 width={width} height={height} isFullScreen={isFullScreen} />
                break;
            case "default":
                temp = <Default width={width} height={height} isFullScreen={isFullScreen} />
                break;
            default:
                temp = <div />
                break;
        }

        return <div onClick={(e) => {
            this.setState({
                menuStyle: this.hideMenuStyle(),
            })
        }}>
            {this.mkMenu()}
            {temp}
        </div>
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Daping);

