/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from "moment";
import { Tree, Input, Space, Button, Popconfirm, Descriptions, Spin } from 'antd';
import { UserOutlined,  } from '@ant-design/icons';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import { StatisticCard } from '@ant-design/pro-card';
import { apiOrganizationGet, apiOrganizationSet } from '../../api/organization';
import { apiStaffList } from '../../api/staff';
import { apiRoleList } from '../../api/role';
import { Page } from '../../component/antd/page';
import Number from '../../component/tweenOneIcon/number';
import { menus } from '../../laylout/menu';
import { getLoginUserInfo } from '../login/loginRD';
import { message } from '../../util/message';
import { getMenus, randomString } from '../../util/logic';

import './organization.less';

class Organization extends Component{

    constructor(props){
        super(props);

        this.state = {
            data: [],
            dataList: [], // 拉平数据，便于搜索
            searchValue: "",
            expandedKeys: [],
            autoExpandParent: true,
            selectedNode: undefined,
        }
    }

    treeData(data) {
        let { searchValue } = this.state;
        return data?.map(item => {
            let index = item.title?.indexOf(searchValue);
            let beforeStr = item.title?.substr(0, index);
            let afterStr = item.title?.substr(index + searchValue.length);
            let title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span className="site-tree-search-value">{searchValue}</span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{item.title}</span>
                );
            if (item.children) {
                return {
                    title,
                    key: item.key,
                    children: this.treeData(item.children),
                    record: item,
                    isLeaf: item.isLeaf,
                    selectable: item.selectable,
                    icon: item.icon,
                };
            }

            return {
                title,
                key: item.key,
                children: [],
                record: item,
                isLeaf: item.isLeaf,
                selectable: item.selectable,
                icon: item.icon,
            };
        }) || [];
    }

    getParent(key, tree) {
        let parent;
        for (let i = 0; i < tree.length; i++) {
            let node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parent = node;
                    break;
                } else {
                    let p = this.getParent(key, node.children);
                    if (p) {
                        parent = p;
                        break;
                    }
                }
            }
        }
        return parent;
    };

    getNode(key, tree) {
        let ret = null;
        for (let node of tree) {
            if (node.key === key) {
                ret = node;
                break;
            } else if (node.children) {
                ret = this.getNode(key, node.children)
                if (ret) {
                    break;
                }
            }
        }
        return ret;
    };

    removeNode(key, tree) {
        for (let i = 0; i < tree.length; i++) {
            let node = tree[i]
            if (node.key === key) {
                tree.splice(i, 1)
                break;
            } else if (node.children) {
                this.removeNode(key, node.children)
            }
        }
    }

    generateList(list, data) {
        for (let node of data) {
            list.push(node);
            if (node.children) {
                this.generateList(list, node.children);
            }
        }
    };

    onRefresh(op) {
        this.setState({
            data: [],
            dataCopy: null,
            dataList: [],
            expandedKeys: [],
            selectedNode: undefined,
        }, async () => {
            // let { expandedKeys } = this.state;
            let rsp = await apiOrganizationGet({}, { ignoreError: true });
            let data = []
            let dataCopy = [];
            try {
                data = JSON.parse(rsp?.Data || "[{\"key\": \"_\", \"children\": [], \"title\": \"总部\"}]");
                dataCopy = JSON.parse(rsp?.Data || "[{\"key\": \"_\", \"children\": [], \"title\": \"总部\"}]");
            } catch (error) {
                console.error("parse json error", error);
            }
            // console.log("data", data);
            let dataList = []
            this.generateList(dataList, data);

            let rsp2 = await apiRoleList({ PageNum: 1, PageSize: 1000, }, { ignoreError: true })
            this.setState({
                data,
                dataCopy,
                dataList: dataList,
                roleData: rsp2?.RecordList || [],
            })
        })
    }

    mkForm(operation) {
        return <>
            <ProFormText
                width="lg"
                name="title"
                label="名称"
                placeholder="请输入名称"
                rules={[{ required: true, message: '请输入名称' }]}
            />
            <ProFormTextArea
                width="lg"
                name="remark"
                label="备注"
                placeholder="请输入备注"
            />
        </>
    }

    mkEdit(record) {
        return <ModalForm
            key={record.Id}
            formRef={ref => record.editForm = ref}
            title="编辑部门"
            labelwidth="auto"
            trigger={<Button key="edit" type="link">编辑</Button>}
            onVisibleChange={(visible) => {
                if (visible) {
                    record.editForm.setFieldsValue(Object.assign({}, record))
                }
            }}
            onFinish={async (values) => {
                let { data } = this.state;
                console.group("add")
                console.log("edit data", values);
                let node = this.getNode(record.key, data) || {}
                Object.assign(node, Object.assign(values, {
                    editForm: undefined,
                }))
                console.log("node", node);
                console.groupEnd();
                let rsp = await apiOrganizationSet({
                    Data: JSON.stringify(data)
                });
                if (rsp.Status === 0) {
                    message.success(`编辑部门${record?.title}成功`);
                    this.onRefresh();
                    return true;
                }
                return false;
            }}
        >
            {this.mkForm('edit')}
        </ModalForm>
    }

    mkNew(record) {
        return <ModalForm
            formRef={ref => record.newForm = ref}
            title={`在${record.title}下添加子部门`}
            labelwidth="auto"
            trigger={<Button key="add" type="link">添加</Button>}
            onFinish={async (values) => {
                console.group("add")
                console.log(values);
                let { data, expandedKeys } = this.state;
                let newData = Object.assign({ key: `${randomString(8)}${moment().format("YYYYMMDDHHmmss")}`, children: [] }, values);
                console.log("selected node", record);
                console.log("data", data)
                console.groupEnd();
                let ret = this.getNode(record.key, data)
                if (ret) {
                    ret.children.push(newData);
                    let rsp = await apiOrganizationSet({
                        Data: JSON.stringify(data),
                        // Data: "",
                    });
                    if (rsp.Status === 0) {
                        message.success(`添加部门${values?.title}成功`);
                        if (!expandedKeys.includes(record.key)) {
                            expandedKeys.push(record.key)
                            this.setState({
                                expandedKeys: expandedKeys
                            }, () => {
                                this.onRefresh('add');
                            })
                        } else {
                            this.onRefresh('add');
                        }
                        return true;
                    }
                }
                return false;
            }}
            onVisibleChange={(visible) => {
                if (visible) {
                    record.newForm.setFieldsValue({
                        title: '',
                        remark: '',
                    })
                }
            }}
        >
            {this.mkForm('new')}
        </ModalForm>
    }

    mkPageHeaderExtra() {
        let { dataList } = this.state;
        return (<Spin spinning={!dataList}>
            <StatisticCard.Group>
                <StatisticCard statistic={{
                    title: '总计',
                    valueRender: () => {
                        return <span className="hover"><Number value={dataList?.filter(d => !d.isLeaf)?.length || 0} /></span>
                    }
                }}/>
            </StatisticCard.Group>
        </Spin>)
    }

    mkPageBody() {
        let { expandedKeys, autoExpandParent, dataList, data, dataCopy, selectedNode, roleData } = this.state;
        return (
            <div className="organization-page-body">
                <div className="organization-page-body-header">
                    <div className="organization-page-body-header-left">
                        <div className="title">部门列表</div>
                    </div>
                    <Space className="organization-page-body-header-right">
                        <Button type="default" onClick={() => this.onRefresh()}>刷新</Button>
                    </Space>
                </div>
                <div className="organization-page-body-content">
                    <div className="organization-page-body-content-left">
                        <Input className="search" size="large" placeholder="搜索部门" onChange={(e) => {
                            let { value } = e.target;
                            if (value) {
                                clearTimeout(this.searchDebounceTimer)
                                this.searchDebounceTimer = setTimeout(() => {
                                    let expandedKeys = dataList
                                        .map(item => item.title?.indexOf(value) > -1 ? this.getParent(item.key, dataCopy)?.key : null)
                                        .filter((item, i, self) => item && self.indexOf(item) === i);
                                    if (expandedKeys?.length) {
                                        this.setState({
                                            expandedKeys,
                                            searchValue: value,
                                            autoExpandParent: true,
                                        });
                                    } else {
                                        this.setState({
                                            searchValue: value,
                                        });
                                    }
                                }, 500);
                            } else {
                                this.setState({
                                    searchValue: value,
                                });
                            }
                        }} />
                        {dataCopy ? <Tree
                            className="organization-page-body-content-left-tree"
                            blockNode={true}
                            selectable={false}
                            showIcon={true}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            treeData={this.treeData(dataCopy)}
                            loadData={async ({ key, children }) => {
                                console.group("load data")
                                console.log("key", key);
                                console.log("children", children);
                                let node = this.getNode(key, dataCopy);
                                let rsp = await apiStaffList({
                                    PageNum: 1,
                                    PageSize: 9999,
                                    OrFirst: true,
                                    Filters: [[{ Key: "Department", Type: "str", Op: "=", Value: key }]]
                                }, { ignoreError: true });
                                if (rsp?.RecordList?.length) {
                                    (rsp?.RecordList || []).forEach(m => {
                                        node.children.unshift({ key: m.Id, title: m.NickName, isLeaf: true, selectable: false, ...m })
                                    })
                                    let dataList = [];
                                    this.generateList(dataList, dataCopy);
                                    this.setState({
                                        dataCopy,
                                        dataList,
                                    })
                                }
                                console.groupEnd();
                            }}
                            titleRender={(item) => {
                                return <div className="tree-item" onClick={() => {
                                    this.setState({
                                        selectedNode: item,
                                    })
                                }}>
                                    <div className="title">{item.isLeaf ? <img src={item.record?.AvatarUrl} alt=""/> : undefined}{item.title}</div>
                                    {
                                        <div>
                                            <Button type="link" onClick={() => {
                                                this.setState({
                                                    selectedNode: item,
                                                })
                                            }}>详情</Button>
                                            {!item.isLeaf ? this.mkNew(item.record) : undefined}
                                            {!item.isLeaf ? this.mkEdit(item.record) : undefined}
                                            {!item.isLeaf && item.record.key !== '_' ? <Popconfirm title={`是否删除${item.record?.title}`} okText="确定" cancelText="我再想想" onConfirm={async () => {
                                                this.removeNode(item.key, data)
                                                let rsp = await apiOrganizationSet({
                                                    Data: JSON.stringify(data),
                                                });
                                                if (rsp.Status === 0) {
                                                    message.success(`删除部门${item.record?.title}成功`);
                                                    this.onRefresh('del');
                                                    return true;
                                                }
                                            }}>
                                                <Button type="link">删除</Button>
                                            </Popconfirm> : undefined}
                                        </div>
                                    }
                                    
                                </div>
                            }}
                            onExpand={(expandedKeys) => {
                                this.setState({
                                    expandedKeys,
                                    autoExpandParent: false,
                                });
                            }}
                            // draggable={(node) => !node.isLeaf && node.key !== '_'}
                            draggable={false}
                            onDragEnter={(info) => {
                                this.setState({
                                    expandedKeys: info.expandedKeys,
                                });
                            }}
                            onDrop={async (info) => {
                                console.group("drop")
                                console.log(info);
                                console.groupEnd();
                                const dropKey = info.node.key;
                                const dragKey = info.dragNode.key;
                                const dropPos = info.node.pos.split('-');
                                const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
                                if (dropKey === dragKey) {
                                    return;
                                }
                                const loop = (data, key, callback) => {
                                    for (let i = 0; i < data.length; i++) {
                                        if (data[i].key === key) {
                                            return callback(data[i], i, data);
                                        }
                                        if (data[i].children) {
                                            loop(data[i].children, key, callback);
                                        }
                                    }
                                };

                                // Find dragObject
                                let dragObj;
                                loop(data, dragKey, (item, index, arr) => {
                                    arr.splice(index, 1);
                                    dragObj = item;
                                });

                                if (!info.dropToGap) {
                                    // Drop on the content
                                    loop(data, dropKey, item => {
                                        item.children = item.children || [];
                                        // where to insert 示例添加到头部，可以是随意位置
                                        item.children.unshift(dragObj);
                                    });
                                } else if (
                                    (info.node.props.children || []).length > 0 && // Has children
                                    info.node.props.expanded && // Is expanded
                                    dropPosition === 1 // On the bottom gap
                                ) {
                                    loop(data, dropKey, item => {
                                        item.children = item.children || [];
                                        // where to insert 示例添加到头部，可以是随意位置
                                        item.children.unshift(dragObj);
                                        // in previous version, we use item.children.push(dragObj) to insert the
                                        // item to the tail of the children
                                    });
                                } else {
                                    let ar = [];
                                    let i;
                                    loop(data, dropKey, (item, index, arr) => {
                                        ar = arr;
                                        i = index;
                                    });
                                    if (dropPosition === -1) {
                                        ar.splice(i, 0, dragObj);
                                    } else {
                                        ar.splice(i + 1, 0, dragObj);
                                    }
                                }

                                let rsp = await apiOrganizationSet({
                                    Data: JSON.stringify(data)
                                });
                                if (rsp.Status === 0) {
                                    message.success('修改部门成功');
                                    this.onRefresh();
                                    return true;
                                }
                            }}
                        /> : undefined}
                    </div>
                    <div className="organization-page-body-content-right">
                        <div className="organization-page-body-content-right-detail">
                            {
                                selectedNode ? <div>
                                    {
                                        selectedNode.isLeaf ?
                                            <Descriptions title="人员详情" column={1}>
                                                <Descriptions.Item label="头像"><img src={selectedNode.record?.AvatarUrl} alt=""/></Descriptions.Item>
                                                <Descriptions.Item label="编号">{selectedNode.record?.SerialNumber}</Descriptions.Item>
                                                <Descriptions.Item label="姓名">{selectedNode.record?.NickName}</Descriptions.Item>
                                                <Descriptions.Item label="手机号">{selectedNode.record?.PhoneNum}</Descriptions.Item>
                                                <Descriptions.Item label="角色">{roleData?.find(r => r.Id === selectedNode.record?.RoleId)?.Name}</Descriptions.Item>
                                                <Descriptions.Item label="部门">{this.getNode(selectedNode.record?.Department, data)?.title}</Descriptions.Item>
                                            </Descriptions>
                                            :
                                            <Descriptions title="部门详情" column={1}>
                                                <Descriptions.Item label="名称">{selectedNode.record?.title}</Descriptions.Item>
                                                <Descriptions.Item label="备注">{selectedNode.record?.remark}</Descriptions.Item>
                                            </Descriptions>
                                    }
                                </div> : undefined
                                
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="staff-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        window.org = this;
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
            // calendarSelectedDate: undefined,
        })
        this.onRefresh('first');
    }

    render(){
        return (<div>
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Organization);

