
import { api } from './api';

export let apiCameraList = async (data, extra) => {
    return await api("/ccs/v1/operator/device/list", data, extra)
}

export let apiCameraAdd = async (data, extra) => {
    return await api("/ccs/v1/operator/device/add", data, extra)
}

export let apiCameraMod = async (data, extra) => {
    return await api("/ccs/v1/operator/device/mod", data, extra)
}

export let apiCameraDel = async (data, extra) => {
    return await api("/ccs/v1/operator/device/del", data, extra)
}
