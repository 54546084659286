/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Popconfirm, Space, Button, Tag, Tooltip, Dropdown } from 'antd';
import { PlusOutlined, PushpinOutlined, StarFilled, ReloadOutlined, SettingOutlined, FullscreenOutlined, DownOutlined } from '@ant-design/icons';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import { StatisticCard } from '@ant-design/pro-card';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormFieldSet } from '@ant-design/pro-form';
import { DEVICE } from '../../component/common/common';
import { DeviceList } from '../../component/common/deviceList';
import { pagization } from '../../api/api';
import { apiTerminalList, apiTerminalAdd, apiTerminalDel, apiTerminalMod } from '../../api/terminal';
import { Page } from '../../component/antd/page';
import { PaginationPositionIcon, PicPreview } from '../../component/antd/antd';
import { menus } from '../../laylout/menu';
import { getLoginUserInfo } from '../login/loginRD';
import { message } from '../../util/message';
import { getMenus } from '../../util/logic';
import { DevicePosition } from 'component/common/devicePosition';

import './terminal.less';

class Terminal extends Component{

    constructor(props){
        super(props);

        const columns = [
            {
                title: '状态',
                type: 'strEnumMultiple',
                width: '10%',
                dataIndex: 'State',
                search: false,
                valueType: 'select',
                valueEnum: DEVICE.STATE.valueEnum(),
                render: (_, record) => DEVICE.STATE.columnRender(record)
            },
            {
                title: '照片',
                type: 'str',
                width: '10%',
                dataIndex: 'AvatarUrl',
                search: false,
                render: (text) => {
                    return <div className="terminal-avatar"><img alt="" src={text}/></div>
                }
            },
            {
                title: '名称',
                type: 'str',
                width: '20%',
                dataIndex: 'Name',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '编号',
                type: 'str',
                width: '20%',
                dataIndex: 'SerialNumber',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: 'SIP号',
                type: 'str',
                width: '20%',
                dataIndex: 'SipNum',
                ellipsis: true,
                fieldProps: {
                    placeholder: "搜索"
                },
            },
            {
                title: '设备类型',
                type: 'int',
                width: '10%',
                hideInSearch: true,
                hideInTable: true,
                dataIndex: 'TypeId',
                initialValue: DEVICE.TYPE.TERMINAL,
                valueType: 'select',
                valueEnum: DEVICE.TYPE.valueEnum(),
            },
            {
                title: '操作',
                width: '20%',
                key: 'option',
                valueType: 'option',
                // fixed: 'right',
                render: (_, record) => {
                    let options = [];
                    options.push(this.mkEdit(record))
                    options.push(<Popconfirm
                        key={record.Id}
                        title={`是否删除视频终端${record?.Name}？`}
                        onConfirm={async () => {
                            let rsp = await apiTerminalDel(record);
                            if (rsp.Status === 0) {
                                message.success('删除视频终端成功');
                                this.tableAction.reload();
                                return true;
                            }
                        }}
                        okText="确定"
                        cancelText="我再想想"
                    >
                        <Button key="delete" type="link" className="nopadding">删除</Button>
                    </Popconfirm>)
                    return options;
                },
            },
        ];

        this.state = {
            data: [],
            columns: columns,
            paginationData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            paginationConfig: {
                position: ['bottomRight'],
                showQuickJumper: true,
            },

            deviceColumns: DeviceList.columns.bind(this)(),
            paginationDeviceData: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
        }
    }

    mkPageHeaderExtra() {
        let { paginationData } = this.state;
        return <StatisticCard.Group>
            <StatisticCard statistic={{ title: '总计', value: paginationData?.total }} />
        </StatisticCard.Group>
    }

    mkEdit(record) {
        let { avatarFile } = this.state;
        return <ModalForm
            key={record.Id}
            formRef={ref => record.editForm = ref}
            title="编辑视频终端"
            labelwidth="auto"
            trigger={<Button key="edit" type="link" className="nopadding">编辑</Button>}
            onVisibleChange={(visible) => {
                if (visible) {
                    let file = PicPreview.newFile(record.AvatarUrl);
                    this.setState({
                        avatarFile: file,
                        record: this.state.record,
                    }, () => {
                        record.editForm.setFieldsValue({
                            ...record,
                            AvatarUrl: file ? [file] : undefined,
                            location: [record.Lng, record.Lat],
                        })
                    })
                }
            }}
            onFinish={async (values) => {
                let rsp = await apiTerminalMod({
                    ...record,
                    ...values, 
                    AvatarUrl: values.AvatarUrl?.[0]?.url,
                    TypeId: DEVICE.TYPE.TERMINAL,
                    editForm: undefined,
                    Lng: values.location?.[0],
                    Lat: values.location?.[1],
                });
                if (rsp.Status === 0) {
                    message.success('编辑视频终端成功');
                    this.tableAction.reload();
                    return true;
                }
                return false;
            }}
        >
            {PicPreview.ProFormUploadButton.bind(this)(avatarFile)}
            <ProFormText
                width="lg"
                name="Name"
                label="名称"
                placeholder="请输入名称"
                rules={[{ required: true, message: '请输入名称' }]}
            />
            <ProFormText
                width="lg"
                name="SerialNumber"
                label="编号"
                placeholder="请输入编号"
                rules={[{ required: true, message: '请输入编号' }]}
            />
            <ProForm.Group>
                <ProFormText
                    width="lg"
                    name="SipNum"
                    label="SIP号"
                    disabled={true}
                    placeholder="请输入SIP号"
                    rules={[{ required: true, message: '请输入SIP号' }]}
                />
            </ProForm.Group>
            <ProForm.Group align="center">
                <ProFormFieldSet
                    name="location"
                    label="位置"
                    type="group"
                >
                    <ProFormText width="sm" placeholder="经度" disabled />
                    <ProFormText width="sm" placeholder="纬度" disabled />
                </ProFormFieldSet>
                <DevicePosition
                    name={this.state.record?.Name}
                    position={{ lng: this.state.record?.Lng, lat: this.state.record?.Lat }}
                    onSelect={(pos) => {
                        this.state.record?.editForm?.setFieldsValue({ location: [pos.lng, pos.lat] })
                        this.newForm?.setFieldsValue({ location: [pos.lng, pos.lat] })
                    }}
                />
            </ProForm.Group>
        </ModalForm>
    }

    mkNew() {
        let { avatarFile } = this.state;
        return <ModalForm
            key="add"
            formRef={ref => this.newForm = ref}
            title="添加视频终端"
            labelwidth="auto"
            trigger={<Button key="add" type="primary" icon={<PlusOutlined/>}>添加</Button>}
            onFinish={async (values) => {
                let rsp = await apiTerminalAdd({
                    ...values,
                    TypeId: DEVICE.TYPE.TERMINAL,
                    AvatarUrl: values.AvatarUrl?.[0]?.url || "",
                    Lng: values.location?.[0],
                    Lat: values.location?.[1],
                });
                if (rsp.Status === 0) {
                    message.success('添加视频终端成功');
                    this.tableAction.reload();
                    return true;
                }
                return false;
            }}
            onVisibleChange={(visible) => {
                if (visible) {
                    this.newForm.setFieldsValue({
                        AvatarUrl: undefined,
                        SerialNumber: '',
                        Name: '',
                        TypeId: DEVICE.TYPE.TERMINAL,
                        SipNum: '',
                    })
                    this.setState({
                        avatarFile: undefined,
                        record: undefined,
                    })
                }
            }}
        >
            {PicPreview.ProFormUploadButton.bind(this)(avatarFile)}
            <ProFormText
                width="lg"
                name="Name"
                label="名称"
                placeholder="请输入名称"
                rules={[{ required: true, message: '请输入名称' }]}
            />
            <ProFormText
                width="lg"
                name="SerialNumber"
                label="编号"
                placeholder="请输入编号"
                rules={[{ required: true, message: '请输入编号' }]}
            />
            <ProForm.Group align="center">
                <ProFormText
                    width="lg"
                    name="SipNum"
                    label="SIP号"
                    tooltip="若无法确认SIP号，你可以点击查看设备"
                    placeholder="请输入SIP号"
                    rules={[{ required: true, message: '请输入SIP号' }]}
                />
                <DeviceList {...DeviceList.props(this.state)} instance={this}/>
            </ProForm.Group>
            <ProForm.Group align="center">
                <ProFormFieldSet
                    name="location"
                    label="位置"
                    type="group"
                >
                    <ProFormText width="sm" placeholder="经度" disabled />
                    <ProFormText width="sm" placeholder="纬度" disabled />
                </ProFormFieldSet>
                <DevicePosition
                    name={this.state.record?.Name}
                    position={{ lng: this.state.record?.Lng, lat: this.state.record?.Lat }}
                    onSelect={(pos) => {
                        this.state.record?.editForm?.setFieldsValue({ location: [pos.lng, pos.lat] })
                        this.newForm?.setFieldsValue({ location: [pos.lng, pos.lat] })
                    }}
                />
            </ProForm.Group>
        </ModalForm>
    }

    mkPageBody() {
        let { columns, paginationData, paginationConfig } = this.state;
        let operations = [
            <Button key="refresh" type="default" icon={<ReloadOutlined />} onClick={() => this.tableAction.reload()}>刷新</Button>,
            this.mkNew(),
            // <Button key="export" type="primary" icon={<DownloadOutlined/>}>导出</Button>,
            <PaginationPositionIcon
                key="pagination"
                paginationConfig={paginationConfig}
                onChange={(key) => {
                paginationConfig.position = [key]
                    this.setState({
                        paginationConfig: paginationConfig,
                    })
                }}
            />,
        ]

        return (
            <div className="terminal-page-body">
                <div className="terminal-page-body-table-col">
                    <ProTable
                        formRef={(ref) => this.table = ref}
                        actionRef={(ref) => this.tableAction = ref}
                        columns={columns}
                        request={async (params, sorter, filter) => {
                            // 表单搜索项会从 pagination 传入，传递给后端接口。
                            console.group('request');
                            console.log("params:", params);
                            console.log("sorter:", sorter);
                            console.log("filter:", filter);
                            console.groupEnd();
                            params.TypeId = DEVICE.TYPE.TERMINAL;
                            let rsp = await apiTerminalList(pagization(params, sorter, filter, columns))
                            if (rsp.Status === 0) {
                                this.setState({
                                    paginationData: {
                                        current: params?.current,
                                        pageSize: params?.pageSize,
                                        total: rsp?.TotalNum,
                                    },
                                })
                            }
                            return {
                                data: rsp?.RecordList || [],
                                total: rsp?.TotalNum,
                                success: true,
                            };
                        }}
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            },
                            getCheckboxProps: (record) => ({
                                Id: record.Id,
                            }),
                        }}
                        tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => {
                            return (
                                <Space size={16}>
                                    <Button className="nopadding" type="link">批量删除</Button>
                                    <Button className="nopadding" type="link">导出数据</Button>
                                    <Button className="nopadding" type="link" onClick={onCleanSelected}>取消选择</Button>
                                </Space>
                            );
                        }}
                        rowKey="Id"
                        pagination={{
                            ...paginationConfig,
                            ...paginationData,
                        }}
                        toolbar={{
                            // multipleLine: true,
                        }}
                        search={{ filterType: "light" }}
                        dateFormatter="string"
                        headerTitle="视频终端列表"
                        options={{
                            density: true,
                            setting: true,
                            reload: false,
                            fullScreen: false,
                            search: false,
                        }}
                        toolBarRender={() => operations}
                    />
                </div>
            </div>
        )
    }

    mkPage() {
        let { breadcrumbs } = this.state;
        let extra = this.mkPageHeaderExtra();
        let body = this.mkPageBody();
        return <Page className="terminal-page-header" breadcrumbs={breadcrumbs} extra={extra}>
            {body}
        </Page>
    }
    
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
       
    }

    componentDidMount() {
        let { breadcrumbs } = getMenus(menus, this.props.location)
        this.setState({
            breadcrumbs: breadcrumbs,
        })
    }

    render(){
        return (<div>
            {<PicPreview {...PicPreview.props.bind(this)(this.state)}/>}
            {this.mkPage()}
        </div>)
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Terminal);

