

import styleJsonLight from '../map/style/custom_map_config5.json';
import styleJsonDark from '../map/style/custom_map_config6.json';

export let mapStyle = {
    'light': styleJsonLight,
    'dark': styleJsonDark,
}
export let mapColor = {
    'light': '#000',
    'dark': '#fff',
}
export let mapBackgroud = {
    'light': '#000',
    'dark': '#fff',
}
export let drawColor = {
    'light': '#000',
    'dark': '#fff',
}
export let drawBackgroud = {
    'light': '#0000004F',
    'dark': '#FFFFFF4F',
}

export let menuBackgroud = {
    'light': '#595959',
    'dark': '#FFFFFF4F',
}
export let menuColor = {
    'light': '#FFF',
    'dark': '#000',
}