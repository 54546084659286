/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { IconFont } from '../../../util/hoComponent';
import { message } from '../../../util/message';
import Clock from '../../../component/common/clock';
import { getLoginUserInfo } from '../../login/loginRD';
import { getUrlParam, randomString } from '../../../util/logic';
import HeaderTitle from '../component/headerTitle';

import Rank from '../component/rank';
import Map from '../component/map';
import CardGauge from '../component/cardGauge';
import CardBar from '../component/cardBar';
import CardBar2 from '../component/cardBar2';
import Logo from '../component/logo';
import Trend from '../component/trend';
import Statistic from '../component/statistic';

import './default.less';

class Default extends Component{

    constructor(props){
        super(props);

        this.onUpdateOnClick = this.onUpdateOnClick.bind(this);

        let editable = getUrlParam("editable", window.location?.search) === "1";
        let template = getUrlParam("template", window.location?.search) || "default";

        this.state = {
            template: `daping.template.${template}`,
            editable: editable,
            editableStyle: editable ? "editable" : "",
            topic: `daping/template/${template}`
        }
    }

    onUpdateOnClick(subTopic) {
        let { topic, mqttClient } = this.state;
        mqttClient.publish(`${topic}/${subTopic}`, JSON.stringify({ type: subTopic, action: "refresh" }))
    }

    init() {
        let { topic } = this.state;
        let { reqUserInfo } = this.props;
        const clientId = `${randomString()}_${moment().unix()}`;

        let mqtt = require('mqtt')
        let client = mqtt.connect('', {
            port: reqUserInfo.mqttInfo.ServerPort,
            hostname: reqUserInfo.mqttInfo.ServerHost,
            username: reqUserInfo.mqttInfo.Accout,
            password: reqUserInfo.mqttInfo.Password,
            clientId: clientId,
            protocol: 'wss',
        })
        let tagTopic = `${topic}/#`;
        console.log(`mqtt ${clientId} connected and will subscribe topic: ${tagTopic}`);

        client.on('connect', () => {
            client.subscribe(tagTopic, (err) => {
                if (!err) {
                    console.info(`mqtt subscribe success`);
                }
            })
        })

        client.on('message', (dstTopic, message) => {
            if (dstTopic.indexOf(topic) !== 0) {
                return;
            }

            let content = {}
            let msg = message.toString();
            try {
                content = JSON.parse(msg);
                console.info(`mqtt message:`, dstTopic, content);
            } catch (error) {
                console.error(`mqtt message:`, dstTopic, msg);
            }

            this.headerTitleRef?.onRefresh && this.headerTitleRef.onRefresh(content)
            this.rankRef?.onRefresh && this.rankRef.onRefresh(content)
            this.mapRef?.onRefresh && this.mapRef.onRefresh(content)
            this.cardGauge1Ref?.onRefresh && this.cardGauge1Ref.onRefresh(content)
            this.cardBar2Ref?.onRefresh && this.cardBar2Ref.onRefresh(content)
            this.cardBar3Ref?.onRefresh && this.cardBar3Ref.onRefresh(content)
            this.cardBar4Ref?.onRefresh && this.cardBar4Ref.onRefresh(content)
            this.cardGauge5Ref?.onRefresh && this.cardGauge5Ref.onRefresh(content)
            this.cardGauge6Ref?.onRefresh && this.cardGauge6Ref.onRefresh(content)
            this.logoRef?.onRefresh && this.logoRef.onRefresh(content)
            this.trendRef?.onRefresh && this.trendRef.onRefresh(content)
            this.statistic1Ref?.onRefresh && this.statistic1Ref.onRefresh(content)
            this.statistic2Ref?.onRefresh && this.statistic2Ref.onRefresh(content)
            this.statistic3Ref?.onRefresh && this.statistic3Ref.onRefresh(content)
        })

        this.setState({
            mqttClient: client,
        })
    }

    uninit() {

    }

    mkMain() {
        let { template } = this.state;
        return <>
            <div className="default-header">
                <div className="default-header-title"><HeaderTitle ref={e => this.headerTitleRef = e} template={template} onUpdate={this.onUpdateOnClick}/></div>
                <div className="default-header-clock"><IconFont type="icon-time"/><Clock className="clock"/></div>
            </div>
            <div className="default-body">
                <div className="default-body-rank">
                    <div className="default-body-rank-item">
                        <Rank ref={e => this.rankRef = e} template={template} onUpdate={this.onUpdateOnClick}/>
                    </div>
                </div>
                <div className="default-body-map">
                    <div className="default-body-map-item">
                        <Map ref={e => this.mapRef = e} template={template} onUpdate={this.onUpdateOnClick} />
                    </div>
                </div>
                <div className="default-body-items">
                    <div className="default-body-items-row">
                        <div className="default-body-items-row-item">
                            <CardGauge ref={e => this.cardGauge1Ref = e} number={1} template={template} onUpdate={this.onUpdateOnClick} />
                        </div>
                        <div className="default-body-items-row-item">
                            <CardBar ref={e => this.cardBar2Ref = e} number={2} template={template} onUpdate={this.onUpdateOnClick}/>
                        </div>
                        <div className="default-body-items-row-item">
                            <CardBar ref={e => this.cardBar3Ref = e} number={3} template={template} onUpdate={this.onUpdateOnClick}/>
                        </div>
                    </div>
                    <div className="default-body-items-row">
                        <div className="default-body-items-row-item">
                            <CardBar2 ref={e => this.cardBar4Ref = e} number={4} template={template} onUpdate={this.onUpdateOnClick}/>
                        </div>
                        <div className="default-body-items-row-item">
                            <CardGauge ref={e => this.cardGauge5Ref = e} number={5} template={template} onUpdate={this.onUpdateOnClick}/>
                        </div>
                        <div className="default-body-items-row-item">
                            <CardGauge ref={e => this.cardGauge6Ref = e} number={6} template={template} onUpdate={this.onUpdateOnClick}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="default-footer">
                <div className="default-footer-left">
                    <Logo ref={e => this.logoRef = e} template={template} onUpdate={this.onUpdateOnClick}/>
                </div>
                <div className="default-footer-mid">
                    <Trend ref={e => this.trendRef = e} template={template} onUpdate={this.onUpdateOnClick}/>
                </div>
                <div className="default-footer-right">
                    <div className="indicator-col">
                        <Statistic ref={e => this.statistic1Ref = e} template={template} number={1} onUpdate={this.onUpdateOnClick}/>
                    </div>
                    <div className="indicator-col">
                        <Statistic ref={e => this.statistic2Ref = e} template={template} number={2} onUpdate={this.onUpdateOnClick}/>
                    </div>
                    <div className="indicator-col">
                        <Statistic ref={e => this.statistic3Ref = e} template={template} number={3} onUpdate={this.onUpdateOnClick}/>
                    </div>
                </div>
            </div>
        </>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninit()
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninit()
        }.bind(this)

        this.init();
    }
    render() {
        return (<div className="default">
            {this.mkMain()}
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(Default);

