/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts';
import { Button, Popover } from 'antd';
import ProForm, { ModalForm, ProFormText, ProFormList, ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import Editable from './editable';
import { getUrlParam } from '../../../util/logic';
import { apiDapingDataGet, apiDapingDataSet } from '../../../api/daping';

const DATA_KEY = "grow";

class Trend extends Component{

    constructor(props){
        super(props);

        let defaultOption = {
            title: {
                show: true,
                text: '趋势图主标题',
                textStyle: {
                    fontSize: 18,
                    color: '#6392c8',
                    fontWeight: 'normal',
                },
                padding: [2, 20],
            },
            grid: { // 图部分的边距距离
                top: 30,
                bottom: 30,
                left: 60,
                right: 40,
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['2021-07-31', '2021-08-04', '2021-08-11', '2021-08-17', '2021-08-25', '2021-08-28', '2021-08-31']
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: true  //不显示坐标轴轴线
                },
                axisTick: {
                    show: true  //不显示坐标轴刻度
                },
                splitLine: {
                    show: false,
                }
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                areaStyle: {
                    color: '#146ea1',
                },
                emphasis: {
                    focus: 'series'
                },
            }],
            backgroundColor: 'transparent',
        }

        this.state = {
            editable: getUrlParam("editable", window.location?.search) === "1",
            visible: false,
            defaultOption: defaultOption,
            option: defaultOption,
            loading: true,
        }
    }

    onRefresh(e) {
        if (e?.type === DATA_KEY) {
            let { chart, defaultOption } = this.state;
            let { template } = this.props;

            async function init() {
                if (!chart) {
                    let dom = document.getElementById("grow");
                    chart = echarts.init(dom, 'dark');
                }
                let rsp1 = await apiDapingDataGet(template, DATA_KEY);
                let option = rsp1?.Data || defaultOption;
                chart.setOption(option);
                window.addEventListener("resize",function (){
                    setTimeout(() => {
                        chart.resize();
                    })
                });
                this.setState({
                    option: option,
                    loading: false,
                    chart: chart,
                })
            }
            
            init.bind(this)()
        }
    }

    initForm(option) {
        let items = [];
        let names = option?.xAxis?.data || [];
        let values = option?.series?.[0]?.data || [];
        for (let i in names) {
            items.push({ name: names[i], value: values[i] })
        }
        this.form.setFieldsValue({
            title: option?.title?.text,
            items: items,
        })
    }

    mkEdit() {
        let { chart, option, visible } = this.state;
        let { template, onUpdate } = this.props;
        return <ModalForm
            width="80%"
            formRef={(ref) => this.form = ref}
            title="编辑数据"
            visible={visible}
            onVisibleChange={(v) => {
                if (v) {
                    this.initForm(option)
                }
            }}
            modalProps={{
                okText: "保存",
                cancelText: "我再想想",
                onCancel: () => {
                    this.setState({
                        visible: false,
                    })
                },
            }}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                        <Button danger type="primary" key="reset" onClick={async () => {
                            let { defaultOption } = this.state;
                            this.initForm(defaultOption)
                        }}>重置</Button>,
                        ...defaultDoms,
                    ]
                },
            }}
            onFinish={async (values) => {
                option.title.text = values.title;
                option.xAxis.data = values.items?.map(i => i.name);
                option.series[0].data = values.items?.map(i => i.value);
                let rsp1 = await apiDapingDataSet(template, DATA_KEY, option);
                if (rsp1.Status === 0) {
                    this.setState({
                        option: option,
                        visible: false,
                    }, () => {
                        chart?.setOption && chart.setOption(option);
                        onUpdate && onUpdate(DATA_KEY);
                    })
                }
                return true;
            }}
        >
            <ProCard split="vertical">
                <ProCard colSpan="30%">
                    <ProFormText name="title" label="主标题" placeholder="请输入主标题" />
                </ProCard>
                <ProCard colSpan="70%">
                    <ProFormList name="items" label="数据项">
                        <ProForm.Group size={16}>
                            <ProFormText name="name" label="名称" />
                            <ProFormDigit name="value" label="值" min={0} />
                        </ProForm.Group>
                    </ProFormList>
                </ProCard>
            </ProCard>
        </ModalForm>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.onRefresh({ action: "refresh", type: DATA_KEY })
    }

    render() {
        let { editable, loading } = this.state;
        return <>
            {this.mkEdit()}
            <Editable
                loading={loading}
                editable={editable}
                editOnClick={() => {
                    this.setState({
                        visible: true,
                    })
                }}
            >
                <div id="grow" className="grow-chart" />
            </Editable>
        </>
    }
}

export default Trend;

