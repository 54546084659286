/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import { ProFormUploadButton } from '@ant-design/pro-form';
import { Button, Input, Space, Menu, Tooltip, Dropdown, Modal, Upload } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import { PushpinOutlined } from '@ant-design/icons';
import { randomString } from '../../util/logic';
import { config } from '../../util/version';
import { message } from '../../util/message';
import { api } from '../../api/api';
    
export function PaginationPositionIcon(props) {
    return <Tooltip title="分页器位置">
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu
                    selectedKeys={props?.paginationConfig?.position}
                    onClick={({ key }) => props?.onChange(key)}
                >
                    <Menu.Item key="topRight"><span>顶部</span></Menu.Item>
                    <Menu.Item key="bottomRight"><span>底部</span></Menu.Item>
                </Menu>
            }
        >
            <PushpinOutlined className="table-header-operation-icon" />
        </Dropdown>
    </Tooltip>
}

export function PicPreview(props) {
    return <Modal
        width='80%'
        visible={props.visible}
        title={props.title}
        footer={null}
        onCancel={props.onCancel}
    >
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 540,
        }}>
            <img alt="" style={{
                maxWidth: '100%',
                height: '100%',
            }} src={props.url} />
        </div>
    </Modal>
}
PicPreview.show = function (file) {
    this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
}
PicPreview.props = function (state) {
    return {
        visible: state?.previewVisible,
        title: state?.previewTitle,
        url: state?.previewImage,
        onCancel: () => this.setState({previewVisible: false}),
    }
}
PicPreview.newFile = function (url) {
    if (url) {
        let name = url.substring(url.lastIndexOf('/') + 1);
        return {
            uid: `rc-upload-${name}-${randomString(4)}`,
            name: name,
            status: 'done',
            url: url,
        }
    }
    return undefined;
}

PicPreview.ProFormUploadButton = function (avatarFile, rest) {
    return <ProFormUploadButton
        width="lg"
        name="AvatarUrl"
        label="照片"
        max={1}
        {...rest}
        fileList={avatarFile ? [avatarFile] : undefined}
        onChange={(info) => {
            this.setState({
                avatarFile: info.fileList?.[0]
            })
        }}
        fieldProps={{
            name: 'uploadFile',
            listType: 'picture-card',
            onPreview: (file) => {
                PicPreview.show.bind(this)(file)
            },
            onSuccess: (rsp) => {
                if (rsp.Status === 0) {
                    avatarFile.url = rsp.Url;
                    avatarFile.status = "done";
                    this.setState({
                        avatarFile,
                    })
                }
            },
            beforeUpload: (file) => {
                if (file.type.indexOf("image/") === -1) {
                    message.error(`${file.name}不是图片`);
                    return Upload.LIST_IGNORE;
                }
                return true;
            },
            data: {
                UploadType: 'Avatar',
            },
            action: `${config.host.cs}/ccs/v1/operator/comm/upload`,
            headers: {
                Token: localStorage.getItem('token')
            },
            method: 'POST',
        }}
    />
}

export { ProTableHelper } from './protable/protable';
export { DraggableModal } from './draggableModal/draggableModal';
